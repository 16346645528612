import { defineStore } from "pinia";
import type { Chat, Selectable } from "../types";
import { type Ref, ref } from "vue";

type BoxType = "inbox" | "outbox";

interface State {
  box: BoxType;
  page: number;
  chats: Selectable<Chat>[];
  areAllItemsSelected: Ref<boolean>;
  hasMoreMessages: Ref<boolean>;
}

export const useMailboxStore = defineStore("mailbox", {
  state: (): State => {
    return {
      box: "inbox",
      page: 0,
      chats: [],
      areAllItemsSelected: ref(false),
      hasMoreMessages: ref(true),
    };
  },
  getters: {
    hasCheckedChats: (state) => !!state.chats.find((c) => c.selected),
  },
  actions: {
    setChats(chats: Chat[]) {
      this.chats = chats;
    },
    addChats(chats: Chat[]) {
      this.chats.push(...chats);
    },
    switchBox(box?: BoxType) {
      this.box = box ?? this.box;
      this.page = 0;
      this.chats = [];
      this.areAllItemsSelected = false;
      this.hasMoreMessages = true;
    },
  },
});
