<template>
  <div>
    <Icon name="fa-regular:heart" size="24" class="animated2 swing relative right-1 top-1.5" />
    <Icon name="fa-regular:heart" size="24" class="animated bg-primary relative bottom-1.5 z-10" />
  </div>
</template>
<style lang="css">
.animated {
  background-position: left top;
  animation-delay: 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation: swing 3s infinite;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.animated2 {
  background-position: left top;
  animation-delay: 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation: swing 4s infinite;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

@-webkit-keyframes swing {
  25%,
  50%,
  75%,
  100% {
    -webkit-transform-origin: center;
  }
  25% {
    -webkit-transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(15deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
  }
}

@keyframes swing {
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.swing {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
</style>
