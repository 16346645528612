/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Check if a value is an object
 */
export function isObject(value: any) {
  return (
    value !== null && typeof value === "object" && value.constructor === Object
  );
}

/**
 * Flatten object keys into a single array
 */
export function flattenObjectKeys(
  obj: Record<string, any>,
  prefix = ""
): string[] {
  return Object.entries(obj).flatMap(([key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (isObject(value)) return flattenObjectKeys(value, newKey);
    return newKey;
  });
}

/**
 * Get nested object value
 */
export function getNestedObjectValue(
  obj: Record<string, any>,
  key: string
): any | undefined {
  return key.split(".").reduce((o, i) => o[i], obj);
}
