<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useSiteConfigStore } from '@horizon/library/stores';
import { overrideStyles } from '@horizon/library/utils';
import { Notivue, useNotivue } from 'notivue';
import { useWindowSize } from '@vueuse/core';
import 'notivue/animations.css';
import * as components from '~~/.nuxt/uiLibFacade'; // generated file containing all used components

type ComponentName = keyof typeof components;

const siteConfigStore = useSiteConfigStore();
const { notificationComponent } = storeToRefs(siteConfigStore);

const { width } = useWindowSize();
const isMobile = computed<boolean>(() => width.value < 768);

const notivueConfig = useNotivue();

watch(
  () => isMobile.value,
  () => {
    notivueConfig.position.value = isMobile.value
      ? notificationComponent.value?.data?.placementMobile
      : notificationComponent.value?.data?.placementDesktop;
  }
);
</script>

<template>
  <Notivue v-slot="item">
    <component
      :is="components[notificationComponent.name as ComponentName]"
      v-if="notificationComponent"
      :item="item"
      :style="overrideStyles(notificationComponent?.overrides)"
      :data="notificationComponent?.data"
    ></component>
  </Notivue>
</template>

