<script setup lang="ts">
import type { ZodFormattedError } from 'zod';
import { useModalStore, useAuthStore, useSiteConfigStore } from '../../../stores';
import {
  type LoginAccount,
  type Nullable,
  type HorizonComponentProps,
  LoginAccountSchema,
  EModal
} from '../../../types';
import { ref } from 'vue';
import { navigateTo, useRuntimeConfig } from '#app';
import FormErrorRenderer from '../../Forms/FormErrorRenderer.vue';

const props = defineProps<HorizonComponentProps>();
const googleSsoUrl = useRuntimeConfig().public.googleSsoUrl as string;
const modalStore = useModalStore();
const authStore = useAuthStore();
const siteConfigStore = useSiteConfigStore();
const logo = { src: siteConfigStore.siteSettings?.favicon, alt: 'logo' };
const account = ref<Nullable<LoginAccount>>({
  email: null,
  password: null
});

const isAuthenticating = ref<boolean>(false);
const accountErrors = ref<ZodFormattedError<LoginAccount>>({
  _errors: [],
  email: { _errors: [] },
  password: { _errors: [] }
});

async function login() {
  isAuthenticating.value = true;
  await _login();
  isAuthenticating.value = false;
}

async function _login() {
  accountErrors.value = {
    _errors: [],
    email: { _errors: [] },
    password: { _errors: [] }
  };
  const parsedForm = LoginAccountSchema.safeParse(account.value);

  if (!parsedForm.success) {
    accountErrors.value = parsedForm.error.format();
    return;
  }

  const { errorCode } = await authStore.loginWithEmailPassword(
    parsedForm.data.email,
    parsedForm.data.password
  );

  if (errorCode) {
    switch (errorCode) {
      case 'auth/emulator-config-failed': {
        accountErrors.value._errors.push('Firebase emulator config failed...');
        break;
      }
      case 'horizon/no-firebase-auth': {
        accountErrors.value._errors.push('Setup error: firebase auth not available...');
        break;
      }
      case 'horizon/idtoken-fetch-failed': {
        accountErrors.value._errors.push('Unable to fetch user id token...');
        break;
      }
      case 'auth/user-not-found': {
        accountErrors.value.email?._errors.push('Email not found...');
        break;
      }
      case 'auth/wrong-password': {
        accountErrors.value.password?._errors.push('Password incorrect...');
        break;
      }
      case 'auth/invalid-credential': {
        accountErrors.value._errors.push('Invalid credentials...');
        break;
      }
      case 'auth/user-unverified': {
        accountErrors.value._errors.push('User account has not been verified yet...');
        break;
      }
      case 'auth/user-disabled': {
        accountErrors.value._errors.push('User account has been disabled...');
        break;
      }
      default: {
        accountErrors.value._errors.push('Unknown error: ' + errorCode);
        break;
      }
    }
    return;
  }

  modalStore.clearModal();

  const authorizedRedirectRoute = siteConfigStore.siteSettings?.authorizedRedirectRoute ?? '/';

  await navigateTo(authorizedRedirectRoute);
}

async function openForgotPasswordModal() {
  modalStore.showModalComponent(EModal.forgotPasswordModalComponent);
}

function showRegisterModal() {
  modalStore.showModalComponent(EModal.registrationModalComponent);
}
</script>

<template>
  <div
    class="bg-header-bg top-1/10 md:!w-modal-lg absolute m-5 max-h-full w-full overflow-y-auto rounded-2xl p-6 md:h-fit"
  >
    <div
      class="border-header-text/20 absolute right-2 top-2 flex h-10 w-10 cursor-pointer place-content-center place-items-center rounded-md border"
    >
      <Icon
        name="ic:sharp-close"
        size="25"
        class="text-header-text hover:text-primary"
        :data-testid="props.testId + '/CloseButton'"
        @click="modalStore.clearModal()"
      />
    </div>
    <div class="flex flex-col">
      <img class="mx-auto mb-4 h-6 w-6" :src="logo.src" :alt="logo.alt" />
      <h2 class="text-header-text mx-auto pb-4 text-lg font-bold">Login</h2>
    </div>

    <FormErrorRenderer class="mb-3" :zod-errors="accountErrors" />

    <ButtonGoogleTransparent
      :data="{
        href: googleSsoUrl,
        type: 'login'
      }"
      class="mx-auto mb-3 w-52 text-sm"
      :test-id="props.testId"
    />

    <div class="my-8 border border-[#333741]"></div>

    <form @submit.prevent="login()">
      <div class="mb-3">
        <input
          id="email"
          v-model="account.email"
          type="text"
          name="email"
          placeholder="Your email"
          class="control"
          :class="{ '!border-danger': !accountErrors?.email }"
          :data-testid="props.testId + '/EmailInput'"
        />
        <FormErrorRenderer :zod-errors="accountErrors" property="email" />
      </div>

      <div class="mb-3">
        <input
          id="password"
          v-model="account.password"
          type="password"
          name="password"
          placeholder="Your password"
          class="control"
          :class="{ '!border-danger': !accountErrors?.password }"
          :data-testid="props.testId + '/PasswordInput'"
        />
        <FormErrorRenderer :zod-errors="accountErrors" property="password" />
      </div>

      <ButtonSimple
        class="w-full"
        type="submit"
        :data="{
          label: 'Login',
          isLarge: true,
          uppercase: true,
          isSaving: isAuthenticating
        }"
        :test-id="props.testId"
      />
    </form>

    <a
      class="text-footer-text hover:text-link-hover mt-3 block text-center text-sm"
      href="#"
      :data-testid="props.testId + '/ForgotPasswordLink'"
      @click="openForgotPasswordModal()"
    >
      Forgot password?
    </a>
    <a
      class="text-header-text hover:text-link-hover mt-1 block text-center text-sm"
      href="#"
      :data-testid="props.testId + '/RegisterLink'"
      @click="showRegisterModal()"
    >
      New user? Sign up here.
    </a>
  </div>
</template>

<style scoped lang="postcss">
.control {
  @apply text-modal-text bg-header-bg border-header-text/20 h-10 w-full rounded-md border px-2 py-1 text-sm;
}
</style>

