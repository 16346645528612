<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import type {
  ProfilePaginationEmits,
  ProfilePaginationProps,
} from "../../../types";
import { genPaginationPages } from "../../../utils";
import { watch } from "vue";

const { width } = useWindowSize();
const PAGINATION_WIDTH = [1, 3, 5, 9];
const emit = defineEmits<ProfilePaginationEmits>();
const props = defineProps<ProfilePaginationProps>();

const pages: number[][] = [];

watch(
  () => props.page,
  () => {
    resetPages();
  }
);
watch(
  () => props.totalPages,
  () => {
    resetPages();
  }
);
watch(
  () => width.value,
  () => {
    resetPages();
  }
);

function resetPages() {
  if (width.value > 1023)
    pages[3] = genPaginationPages(
      props.page,
      props.totalPages,
      PAGINATION_WIDTH[3]
    );
  else if (width.value > 767)
    pages[2] = genPaginationPages(
      props.page,
      props.totalPages,
      PAGINATION_WIDTH[2]
    );
  else if (width.value > 639)
    pages[1] = genPaginationPages(
      props.page,
      props.totalPages,
      PAGINATION_WIDTH[1]
    );
  else
    pages[0] = genPaginationPages(
      props.page,
      props.totalPages,
      PAGINATION_WIDTH[0]
    );
}

function setPages() {
  pages[0] = genPaginationPages(
    props.page,
    props.totalPages,
    PAGINATION_WIDTH[0]
  );
  pages[1] = genPaginationPages(
    props.page,
    props.totalPages,
    PAGINATION_WIDTH[1]
  );
  pages[2] = genPaginationPages(
    props.page,
    props.totalPages,
    PAGINATION_WIDTH[2]
  );
  pages[3] = genPaginationPages(
    props.page,
    props.totalPages,
    PAGINATION_WIDTH[3]
  );
}

setPages();

function pageNumberClass(value: number) {
  return value === props.page
    ? "bg-primary text-background border-primary"
    : "bg-transparent border-2 border-text text-text";
}
</script>

<template>
  <div class="flex justify-between align-middle">
    <button
      class="btn pagination-button"
      :class="{ invisible: props.page === 0 }"
      @click="emit('pageUpdate', props.page - 1)"
    >
      Vorige
    </button>

    <div class="pages block sm:hidden">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <a
        v-for="pageNumber of pages[0]"
        class="btn page-number"
        :class="pageNumberClass(pageNumber)"
        @click="emit('pageUpdate', pageNumber)"
      >
        {{ pageNumber + 1 }}
      </a>
    </div>

    <div class="pages hidden sm:block md:hidden">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <a
        v-for="pageNumber of pages[1]"
        class="btn page-number"
        :class="pageNumberClass(pageNumber)"
        @click="emit('pageUpdate', pageNumber)"
      >
        {{ pageNumber + 1 }}
      </a>
    </div>

    <div class="pages hidden md:block lg:hidden">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <a
        v-for="pageNumber of pages[2]"
        class="btn page-number"
        :class="pageNumberClass(pageNumber)"
        @click="emit('pageUpdate', pageNumber)"
      >
        {{ pageNumber + 1 }}
      </a>
    </div>

    <div class="pages hidden lg:block">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <a
        v-for="pageNumber of pages[3]"
        class="btn page-number"
        :class="pageNumberClass(pageNumber)"
        @click="emit('pageUpdate', pageNumber)"
      >
        {{ pageNumber + 1 }}
      </a>
    </div>

    <button
      class="btn pagination-button"
      :class="{ invisible: props.page === totalPages - 1 }"
      @click="emit('pageUpdate', props.page + 1)"
    >
      Volgende
    </button>
  </div>
</template>

<style scoped lang="postcss">
.pagination-button,
.page-number {
  @apply text-tertiary hover:text-primary hover:border-primary select-none border-2 bg-transparent;
}

.pagination-button,
.page-number {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.page-number {
  @apply mx-4 me-1 inline-grid aspect-square h-9 place-items-center rounded-full p-0;
  border-radius: 10px;
}
</style>
