<script setup lang="ts">
import type { ButtonHTMLAttributes } from 'vue';
import type { HorizonComponentProps } from '../../types';
import { useDebounceFn } from '@vueuse/core';

interface Props {
  label: string;
  delay?: number; // in ms
  maxDelay?: number; // in ms
  type?: ButtonHTMLAttributes['type'];
  isLarge?: boolean;
  isSmall?: boolean;
  isBold?: boolean;
  secondary?: boolean;
  wideSpacing?: boolean;
  isDisabled?: boolean;
  uppercase?: boolean;
  isSaving?: boolean;
}

const props = defineProps<HorizonComponentProps<Props>>();
const emit = defineEmits(['click']);

function textClass() {
  const size =
    props.data?.isLarge && !props.data?.isSmall
      ? 'text-xl'
      : !props.data?.isLarge && props.data?.isSmall
        ? 'text-sm'
        : 'text-base';
  const bold = props.data?.isBold ? ' font-bold ' : ' font-medium ';
  const spacing = props.data?.wideSpacing ? 'tracking-widest ' : ' ';
  const casing = props.data?.uppercase ? 'uppercase' : '';
  return size + bold + spacing + casing;
}

const debounce = useDebounceFn(
  () => {
    emit('click');
  },
  props.data?.delay ?? 0,
  { maxWait: props.data?.maxDelay ?? 60000 }
);
</script>

<template>
  <button
    v-if="props.data"
    class="btn text-center"
    :type="props.data.type ?? 'button'"
    :class="[
      textClass(),
      {
        'btn-primary': !props.data.secondary,
        'btn-secondary': props.data.secondary,
        'cursor-not-allowed': props.data?.isDisabled,
        'bg-btn-primary-bg/50': props.data?.isDisabled
      }
    ]"
    :disabled="props.data.isDisabled"
    :data-testid="testId + '/' + props.data.label + 'Button'"
    @click="debounce"
  >
    <slot>
      <Icon v-if="props.data.isSaving" name="svg-spinners:180-ring" />
      {{ props.data.label }}
    </slot>
  </button>
</template>

