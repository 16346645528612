import type { ChatDocument, MessageDocument } from '@horizon/chat-protocol-ts';
import dayjs from 'dayjs';
import type { Timestamp } from 'firebase-admin/firestore';
import { getApps } from 'firebase/app';
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { createChatFetchClient, createCreditPostClient } from '../composables/apiClients';
import {
  useAuthStore,
  useCloudFireStore,
  useMailStore,
  useNotificationStore,
  useProfileStore,
  useSiteConfigStore
} from '../stores';
import type { MessageCreateBody, Notification, chatProfileData } from '../types';
import type { ProfileCard } from '../types/profile/profile';
import { getSingleRouteParam } from '../utils';

export const useChatStore = defineStore('chat', () => {
  const isInited = ref<boolean>(false);
  const authApp = getApps()[0];
  const db = getFirestore(authApp);

  const siteConfigStore = useSiteConfigStore();
  const authStore = useAuthStore();
  const mailStore = useMailStore();
  const profileStore = useProfileStore();
  const notificationStore = useNotificationStore();

  const chatPostClient = createChatFetchClient();
  const creditPostClient = createCreditPostClient();

  const locale = siteConfigStore.siteSettings?.localeCode as string;
  const domainName = siteConfigStore.siteSettings?.domainName as string;
  const userID = computed(() => authStore.authUser?.uid);
  const route = useRoute();

  const profiles = ref<chatProfileData[]>([]);

  const ids = ref<string[]>([]);

  let chatIdempotencyId = crypto.randomUUID();

  const creditCount = ref<number>(0);
  const unreadChatsCount = ref<number>(0);
  const unreadMailCount = ref<number>(0);
  const unreadCount = computed(() => {
    return unreadChatsCount.value + unreadMailCount.value;
  });

  async function getCredits(save: boolean = false) {
    if (!authStore.isAuthorized) return;

    try {
      const { data: creditResponse } = await creditPostClient('/users', {
        method: 'GET'
      });
      if (creditResponse) {
        if (save) {
          creditCount.value = creditResponse === null ? 0 : creditResponse.current_credits;
        }
        return creditResponse === null ? 0 : creditResponse.current_credits;
      }
    } catch (e) {
      console.error(`unable to get user credits: ${e}`);
      console.error(`returning 0 credits`);
      return 0;
    }
  }

  async function getIfFlirted(profileID: string) {
    const profile = ref(getProfileByID(profileID));
    if (!profile.value) {
      profile.value = await newProfile(profileID);
    }
    return { flirted: profile.value?.flirted, name: profile.value?.card.name };
  }

  async function newProfile(profileID: string) {
    const createdChat = await createChat(profileID);
    if (createdChat) {
      const profile: chatProfileData = {
        card: createdChat.card,
        chatID: createdChat?.chat?.id ?? '',
        createdAt: createdChat?.chat?.created_at ?? '',
        conversation: false,
        flirted: false,
        messages: []
      };
      addProfile(profile);
      await getProfileImage(profile.card.profile_id);
      return getProfileByID(profile.card.profile_id);
    }
  }

  function getProfileByID(id: string) {
    for (const profile of profiles.value) {
      if (profile.card.profile_id === id) {
        return profile;
      }
    }
    return undefined;
  }

  function updateProfile(profile: chatProfileData, updatedData: chatProfileData) {
    profile.card = updatedData.card;
    profile.chatID = updatedData.chatID;
    profile.createdAt = updatedData.createdAt;
    profile.conversation = updatedData.conversation;
    profile.flirted = updatedData.flirted;
    profile.messages = updatedData.messages;
  }

  function addProfile(profileToAdd: chatProfileData) {
    const profile = getProfileByID(profileToAdd.card.profile_id);
    if (!profile) {
      profiles.value.push(profileToAdd);
      ids.value.push(profileToAdd.card.profile_id);
      orderProfiles();
    } else {
      updateProfile(profile, profileToAdd);
    }
  }

  const unreadMessages = ref<boolean>(false);

  function updateMessages(profileToUpdate: chatProfileData, messages: MessageDocument[]) {
    const profile = getProfileByID(profileToUpdate.card.profile_id);
    unreadMessages.value = false;
    if (profile) {
      for (const message of messages) {
        checkIfUnread(message, profile);
      }
      profile.messages = messages;
      if (unreadMessages.value) {
        unreadChatsCount.value++;
      }
    }
  }

  function checkIfUnread(message: MessageDocument, profile: chatProfileData) {
    if (
      !message.isRead &&
      message.type !== 'normal' &&
      message.type !== 'flirt' &&
      notificationStore.countAsUnread(message)
    ) {
      if (message.content) {
        const notification: Notification = {
          messageID: message.id,
          profile: {
            id: profile.card.profile_id,
            name: profile.card.name,
            images: [
              {
                src: profile.card.image.url,
                alt: profile.card.name
              }
            ]
          },
          text: message.content,
          duration: 5000
        };
        notificationStore.addNotification(notification, message, profile);
      }

      const id = getSingleRouteParam(route.params, 'id');
      if (
        id !== profile.card.profile_id ||
        (id === profile.card.profile_id && route.name != 'ProfileChat')
      ) {
        if (message.pokeMessageType === 'reply') {
          unreadMessages.value = true;
        } else {
          unreadMailCount.value++;
        }
      } else {
        readMessage(profile.chatID, message);
      }
    }
  }

  function updateProfileImage(profileToUpdate: chatProfileData, image: string) {
    const profile = getProfileByID(profileToUpdate.card.profile_id);
    if (profile) {
      profile.card.image = {
        ...profile.card.image,
        url: image
      };
    }
  }

  async function createChat(profileID: string) {
    if (!authStore.authUser?.uid || !authStore.authUser.displayName) return;

    const profile = await profileStore.getProfileById(profileID);
    const profileCard: ProfileCard = profileStore.extractCardFromProfile(profile);

    try {
      const { data } = await chatPostClient('/user/chats/{locale}', {
        method: 'POST',
        path: {
          locale: locale
        },
        body: {
          chat: {
            profile: {
              uuid: profileID,
              name: profileCard.name
            },
            is_conversation: false,
            last_sender_type: 'customer',
            domain: domainName
          },
          locale
        }
      });
      return {
        chat: data,
        card: profileCard
      };
    } catch (e) {
      console.error(`unable to create chat: ${e}, using dummy data instead`);
      return {
        chat: {
          id: 'no id available',
          created_at: 'N/A'
        },
        card: profileCard
      };
    }
  }

  function timeTypeToValue(time: string | Timestamp) {
    if (typeof time === 'string') {
      return dayjs(time).unix();
    } else {
      return time.seconds;
    }
  }

  function orderProfiles() {
    setTimeout(() => {
      profiles.value.sort(function (a, b) {
        const bTime =
          b.messages && b.messages.length > 0
            ? timeTypeToValue(b.messages.at(-1)?.createdAt ?? '0')
            : timeTypeToValue(b.createdAt ?? '0');
        const aTime =
          a.messages && a.messages.length > 0
            ? timeTypeToValue(a.messages.at(-1)?.createdAt ?? '0')
            : timeTypeToValue(a.createdAt ?? '0');
        return bTime - aTime;
      });
    });
  }

  function resetCounts() {
    unreadChatsCount.value = 0;
    unreadMailCount.value = 0;
  }

  type listenerType = () => void;
  const listeners: listenerType[] = [];
  async function createChatListener() {
    if (!userID.value) return;

    const cloudDB = await useCloudFireStore().getCloudFirestore();
    if (!cloudDB) return;

    const chatCollection = collection(doc(collection(cloudDB, 'geos'), locale), 'chats');
    const chatQuery = query(
      chatCollection,
      where('customer.uuid', '==', userID.value)
      // where('tenant', '==', tenantID), // TODO: CHB overwrites the tenant to the holding tenant ID, which we currently do not have
      // where('isDeleted', '==', false) // TODO: API does not set this property
    );

    // this subs to new chats, it is called unsub because this is what you need to call to un sub
    const unsubNewChats = onSnapshot(chatQuery, (chatQuerySnapshot) => {
      chatQuerySnapshot.forEach(async (chat) => {
        const chatData = chat.data() as ChatDocument;
        if (!chatData.profile) return;

        const profile = await profileStore.getProfileById(chatData.profile.uuid);

        const chatProfile: chatProfileData = {
          card: profileStore.extractCardFromProfile(profile),
          chatID: chatData.id,
          createdAt: chatData.createdAt,
          conversation: chatData.isConversation,
          flirted: false, // TODO: new field
          // flirted: chatData.FlirtedWith ?? false,
          messages: []
        };
        addProfile(chatProfile);
        createMessageListener(chatProfile);
      });
      getProfileImages();
    });
    listeners.push(unsubNewChats);
  }

  const notToAddToMailTypes = ['normal', 'flirt', 'reply'];

  async function createMessageListener(profile: chatProfileData) {
    const cloudFireStore = useCloudFireStore();
    const cloudDB = await cloudFireStore.getCloudFirestore();
    if (!cloudDB || !userID.value) return;

    const messageQ = query(
      collection(
        doc(collection(doc(collection(cloudDB, 'geos'), locale), 'chats'), profile.chatID),
        'messages'
      ),
      orderBy('createdAt', 'asc')
    );

    // this subs to new messages, it is called unsub because this is what you need to call to un sub
    const unsubNewMessages = onSnapshot(messageQ, (messageQuerySnapshot) => {
      const messages: MessageDocument[] = [];
      messageQuerySnapshot.forEach(async (qmessage) => {
        const data = qmessage.data() as MessageDocument;

        // TODO: HIL-1239 This check should be moved to firestore rules later, but creating a new index would take days and is being refactored at this moment anyways.
        if (cloudFireStore.isSnowflake) {
          if (data.senderType !== 'customer' || data.senderUuid !== userID.value) {
            return;
          }
        }

        if (!notToAddToMailTypes.includes(data.pokeMessageType)) {
          mailStore.addMail(data);
        }

        messages.push(data);
      });
      updateMessages(profile, messages);
      orderProfiles();
    });
    listeners.push(unsubNewMessages);
  }

  async function getProfileImages(profileIDs: string[] | undefined = undefined) {
    const IDs: string[] = profileIDs ?? [];
    if (IDs.length === 0) {
      for (const profile of profiles.value) {
        IDs.push(profile.card.profile_id);
      }
    }
    const images = await profileStore.getProfileImages(IDs, 250);
    if (images) {
      for (const image of images) {
        const profile = getProfileByID(image.id as string);
        if (profile) {
          updateProfileImage(profile, image.url as string);
        }
      }
    }
  }

  async function getProfileImage(id: string, useReturn: boolean = false) {
    const IDs: string[] = [id];
    const images = await profileStore.getProfileImages(IDs, 250);
    if (images) {
      const profile = getProfileByID(images[0].id as string);
      if (profile) {
        updateProfileImage(profile, images[0].url as string);
        if (useReturn) {
          return images[0];
        }
      }
    }
  }

  async function initStore() {
    if (isInited.value) {
      return;
    }
    mailStore.clearMail();
    await createChatListener();
    await getCredits(true);
    isInited.value = true;
  }

  function exitStore() {
    for (const unsub of listeners) {
      unsub();
    }
    isInited.value = false;
    resetCounts();
  }

  async function resetListeners() {
    exitStore();
    await initStore();
  }

  function clearAll() {
    profiles.value = [];
    ids.value = [];
    creditCount.value = 0;
    resetCounts();
  }

  /**
   * Create formdata with body properties, casting back to messagecreatebody to ensure all properties are set.
   */
  function createMessageBody(
    body: Omit<MessageCreateBody, 'domain' | 'file'> & { file?: File }
  ): MessageCreateBody | undefined {
    const formData = new FormData();

    const domain = siteConfigStore.siteSettings?.domainName;
    if (!domain) return undefined;
    formData.set('domain', domain);

    if (body.file) {
      formData.set('file', body.file);
    }

    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        formData.set(key, value);
      }
    });

    return formData as unknown as MessageCreateBody;
  }

  function getChatID(profileID: string) {
    return authStore?.authUser?.uid + ':' + profileID;
  }

  async function readChat(profileID: string) {
    const profile = getProfileByID(profileID);
    if (!authStore.authUser?.uid || !profile?.messages || !profile) return;

    const chatID = authStore.authUser.uid + ':' + profile.card.profile_id;
    for (const message of profile.messages) {
      if (message && message.pokeMessageType != 'normal' && !message.isRead) {
        await readMessage(chatID, message);
      }
    }
  }

  async function readMessage(chatID: string, message: MessageDocument) {
    if (!message.id || mailStore.getIsRead(message.id)) return;
    const messageRef = doc(
      collection(doc(collection(doc(collection(db, 'geos'), locale), 'chats'), chatID), 'messages'),
      message.id
    );
    setDoc(messageRef, { IsRead: true }, { merge: true });
    if (message.pokeMessageType != 'reply' && message.pokeMessageType != 'normal') {
      if (mailStore.readMail(message.id)) {
        unreadMailCount.value = Math.max(unreadMailCount.value - 1, 0);
        return;
      }
    }
    unreadChatsCount.value = Math.max(unreadChatsCount.value - 1, 0);
  }

  function setConversation(chatID: string) {
    if (getProfileByID(chatID.split(':')[1])?.conversation) return;
    const chatRef = doc(collection(doc(collection(db, 'geos'), locale), 'chats'), chatID);
    setDoc(chatRef, { IsConversation: true }, { merge: true });
    resetListeners();
  }

  async function sendMessage(
    chatID: string,
    recipientUUID: string,
    content: string,
    attachment: File | undefined = undefined
  ) {
    const { data, error: messageError } = await chatPostClient(
      '/user/chats/{locale}/{chatId}/messages',
      {
        method: 'POST',
        headers: {
          'X-Idempotency-Key': chatIdempotencyId
        },
        path: {
          locale: locale,
          chatId: chatID
        },
        body: createMessageBody({
          file: attachment,
          'message.content': content,
          'message.recipient_uuid': recipientUUID
        })
      }
    );

    if (!data?.success || messageError) {
      console.error(`unable to send message: ${messageError}`);
      return {
        data: undefined,
        error: messageError
      };
    }

    // TODO: Dynamic credit pricing once backend returns it
    creditCount.value = Math.max(creditCount.value - 1, 0);
    chatIdempotencyId = crypto.randomUUID();
    setConversation(chatID);

    return {
      data,
      error: undefined
    };
  }

  // TODO: update once media api has a get image endpoint for FE
  async function getMessageAttachment(_fileName: string) {
    // try {
    //   const { data, error: messageError } = await mediaGetClient(
    //     '/images/{name}',
    //     {
    //       method: 'GET',
    //       path: {
    //         name: fileName,
    //       }
    //     }
    //   );

    //   if (messageError === null || data === undefined || data === null) {
    //     return undefined
    //   }

    //   return data
    // } catch (e) {
    //   console.error(`unable to retrieve image: ${e}`);
    // }
    return 'TODO: get image';
  }

  async function sendFlirt(ID: string, content: string) {
    const profile = getProfileByID(ID) ?? (await newProfile(ID));
    if (!profile) {
      return {
        chatID: undefined,
        error: 'something went wrong'
      };
    }

    const chatID = profile.chatID;

    const { data, error: messageError } = await chatPostClient(
      '/user/chats/{locale}/{chatId}/flirts',
      {
        method: 'POST',
        headers: {
          'X-Idempotency-Key': chatIdempotencyId
        },
        path: {
          locale: locale,
          chatId: chatID
        },
        body: {
          chat_document_id: chatID,
          domain: siteConfigStore.siteSettings?.domainName,
          message: {
            recipient_uuid: ID,
            content
          }
        }
      }
    );

    if (!data?.success || messageError) {
      console.error(`unable to send reply: ${messageError}`);

      return {
        chatID: undefined,
        error: messageError
      };
    }

    chatIdempotencyId = crypto.randomUUID();

    return {
      chatID: chatID,
      error: undefined
    };
  }

  // temporary function to simulate and test replies and other received messages.
  async function sendOther(chatId: string, content: string) {
    const { data, error: messageError } = await chatPostClient(
      '/user/chats/{locale}/{chatId}/messages',
      {
        method: 'POST',
        headers: {
          'X-Idempotency-Key': chatIdempotencyId
        },
        path: {
          locale: locale,
          chatId
        },
        body: createMessageBody({
          'message.content': content,
          'message.recipient_uuid': chatId
        })
      }
    );

    if (!data?.success || messageError) {
      console.error(`unable to send reply: ${messageError}`);
      return {
        data: undefined,
        error: messageError
      };
    }

    chatIdempotencyId = crypto.randomUUID();

    return {
      data,
      error: undefined
    };
  }

  return {
    profiles,
    ids,
    creditCount,
    unreadCount,

    initStore,
    exitStore,
    resetListeners,
    getProfileByID,
    newProfile,
    sendMessage,
    sendOther,
    sendFlirt,
    clearAll,
    getCredits,
    getProfileImage,
    getChatID,
    readChat,
    readMessage,
    getIfFlirted,
    getMessageAttachment
  };
});

