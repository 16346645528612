import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useFiltersStore, useProfileStore, useModalStore } from '../stores';
import { type ProfileCard, EModal } from '../types';
import { storageKeys } from '../utils';

const QUEUE_SIZE = 10;

export const useMatchGameStore = defineStore('matchgame', () => {
  const modalStore = useModalStore();
  const profileStore = useProfileStore();
  const filterStore = useFiltersStore();

  const profiles = ref<ProfileCard[]>([]);
  const direction = ref<1 | -1>(1);
  const profilesAvailable = ref<boolean>(true);
  const noMoreProfilesText = ref<string>('');

  async function fetchProfiles() {
    if (profiles.value.length >= QUEUE_SIZE) return;

    const data = await profileStore.getMatchGameProfiles(QUEUE_SIZE);

    if (!data.profiles) return;
    for (const newProfile of data.profiles) {
      profiles.value.push(newProfile);
    }

    profilesAvailable.value = !data.noProfiles;
  }

  async function pressButton(like: boolean) {
    direction.value = like ? 1 : -1;

    const profile = profiles.value.shift();
    if (!profile) return;

    const matched = await profileStore.likeFunction(profile.profile_id, like);
    if (like && matched) {
      modalStore.showModalComponent(EModal.matchedModalComponent, false, profile);
    }

    await fetchProfiles();
  }

  function getSavedtutorialState() {
    const savedState = sessionStorage.getItem(storageKeys.matchGame.tutorial);
    if (savedState != null && savedState != undefined) {
      return JSON.parse(savedState);
    }
    return true;
  }

  watch(
    () => filterStore.filtersUpdated,
    async () => {
      profilesAvailable.value = true;
      profiles.value = [];
      await fetchProfiles();
    }
  );

  return {
    profiles,
    direction,
    noMoreProfilesText,
    profilesAvailable,

    getSavedtutorialState,
    fetchProfiles,
    pressButton
  };
});

