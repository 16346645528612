import { defineStore } from 'pinia';
import { useFiltersStore, useChatStore, useSiteConfigStore } from '../stores';
import { createProfilesFetchClient } from '../composables/apiClients';
import type {
  HorizonNuxtErrorData,
  field,
  ProfileData,
  ProfileCard,
  ProfileQuery,
  TranslationVars
} from '../types';
import dayjs from 'dayjs';
import { createError } from '#app';
import type { FetchResponseError } from '@btr/fetch-frog/types/utils';
import { storeToRefs, useNuxtApp } from '#imports';

export const useProfileStore = defineStore('profile', () => {
  const filterStore = useFiltersStore();
  const profileFetchClient = createProfilesFetchClient();
  const locale = useSiteConfigStore().siteSettings?.localeCode as string;
  const { $translate } = useNuxtApp();
  const { translations } = storeToRefs(useSiteConfigStore());

  function throwError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: FetchResponseError<any> | null,
    whileVar: TranslationVars['whileVar'],
    whatVar: TranslationVars['whatVar'],
    devtitle: string,
    isFatal: boolean = true
  ) {
    const vars: TranslationVars = {
      whileVar: whileVar,
      whatVar: whatVar
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.whileError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        devTitle: devtitle,
        devMessage: error?.data?.error
      },
      stack: error?.stack,
      fatal: isFatal
    });
  }

  async function favoriteFunction(profileID: string, addToFavorite: boolean) {
    if (addToFavorite) {
      addProfiletoFavorites(profileID);
      return;
    }
    removeProfilefromFavorites(profileID);
  }

  async function addProfiletoFavorites(profileID: string) {
    const { error: profilesError } = await profileFetchClient(
      '/profile-user/favorite/{profileId}',
      {
        method: 'POST',
        path: { profileId: profileID }
      }
    );
    if (profilesError) {
      throwError(
        profilesError,
        'adding profile to favorites',
        '',
        'call to profile-s2d-api POST /profile-user/favorite/{profileId} failed'
      );
    }
    const content = 'Hi! Ik favorite je terug!';
    useChatStore().sendOther(profileID, content);
  }

  async function removeProfilefromFavorites(profileID: string) {
    const { error } = await profileFetchClient('/profile-user/unfavorite/{profileId}', {
      method: 'DELETE',
      path: { profileId: profileID }
    });
    if (error) {
      throwError(
        error,
        'removing profile from favorites',
        '',
        'call to profile-s2d-api DELETE /profile-user/unfavorite/{profileId} failed'
      );
    }
  }

  async function getProfileById(id: string) {
    const { data: profileResponse, error: profileError } = await profileFetchClient(
      '/profile/{profileId}',
      {
        method: 'GET',
        path: {
          profileId: id
        }
      }
    );
    if (profileError) {
      throwError(
        profileError,
        'retrieving',
        'profile',
        'call to profile-s2d-api POST /profile/{profileId} failed',
        true
      );
    }
    return profileResponse as ProfileData;
  }

  const agefilters = ['minAge', 'maxAge'];

  function buildFilterQuery() {
    const usefilters = (filterStore.activeFilterKeys as string[]) ?? [];
    const appliedfilters = filterStore.appliedFilters ?? [];
    const fields: field[] = [];
    const subfields: field[] = [];

    for (const [key, value] of Object.entries(appliedfilters)) {
      if (!usefilters?.includes(key)) {
        continue;
      }
      if (key === 'location') {
        continue;
      }
      if (value === 'all' || value === 'All') {
        continue;
      }
      if (value === 'grey' || value === 'gray') {
        // tempfix until all data has either grey OR gray as attribute/tag value.
        const grayField = buildFilter(key, 'gray' as field['value']);
        subfields.push(grayField);
        const greyField = buildFilter(key, 'grey' as field['value']);
        subfields.push(greyField);
        continue;
      }
      const field: field = agefilters.includes(key)
        ? buildAgeFilter(key, value as field['value'])
        : buildFilter(key, value as field['value']);
      fields.push(field);
    }
    const subQuery = buildGreyGrayQuery(subfields);
    return { filters: fields, subquery: subQuery };
  }

  function buildGreyGrayQuery(fields: field[]) {
    const Or: ProfileQuery['operation'] = 'OR';
    const And: ProfileQuery['operation'] = 'AND';
    if (fields.length < 1) return [];

    return [
      {
        filter: {
          fields: fields,
          operation: Or
        },
        operation: And
      }
    ];
  }

  function buildFilter(key: field['name'], value: field['value']) {
    let op: field['op'] = 'eq';
    if (key?.startsWith('min')) {
      op = 'gt';
    } else if (key?.startsWith('max')) {
      op = 'lt';
    }

    const field: field = {
      name: key,
      op: op,
      value: value
    };
    return field;
  }

  function buildAgeFilter(key: field['name'], value: field['value']) {
    const op: field['op'] = key === 'minAge' ? 'lt' : 'gt';
    const offset = key === 'minAge' ? 0 : 1;
    const today = dayjs(new Date());
    const DoBUnix = today.subtract(Number(value) - offset, 'year').unix() - 1;

    const field: field = {
      name: 'date_of_birth',
      op: op,
      value: String(DoBUnix)
    };
    return field;
  }

  function _buildLengthFilter(key: field['name'], value: field['value']) {
    const op: field['op'] = key === 'minLength' ? 'lt' : 'gt';
    const field: field = {
      name: 'length',
      op: op,
      value: value
    };
    return field;
  }

  function _buildGeoFilter(): ProfileQuery[] {
    if (!filterStore.activeFilterKeys?.includes('location')) {
      return [];
    }

    const location = filterStore.appliedFilters['location'];
    if (location === 'all') {
      return [];
    }
    const geo_regions: string[] = ['geo_region_1', 'geo_region_2', 'geo_region_3', 'geo_region_4'];
    const geo_fields: field[] = [];
    for (const region of geo_regions) {
      const field: field = {
        name: region,
        op: 'eq',
        value: location
      };
      geo_fields.push(field);
    }

    const Or: ProfileQuery['operation'] = 'OR';
    const And: ProfileQuery['operation'] = 'AND';
    return [
      {
        filter: {
          fields: geo_fields,
          operation: Or
        },
        operation: And
      }
    ];
  }

  async function getProfiles(page: number, ITEMS_PER_PAGE: number) {
    const query = buildFilterQuery();

    const filters: field[] = query.filters;

    // const geoQuery = buildGeoFilter();
    const subQuery = query.subquery;

    const { data, error: profilesError } = await profileFetchClient('/profile/filter', {
      method: 'POST',
      body: {
        limit: ITEMS_PER_PAGE,
        offset: page * ITEMS_PER_PAGE,
        should_order_randomly: true,
        query: {
          filter: {
            fields: filters,
            operation: 'AND'
          },
          operation: 'AND',
          sub_queries: subQuery
        },
        search: '',
        sort: ['created_at:asc'],
        referer: '',
        relational_filter: filterStore.relationalFilter
      }
    });

    if (profilesError) {
      throwError(
        profilesError,
        'retrieving',
        'profiles',
        'call to profile-s2d-api POST /profile/filter failed',
        true
      );
    }

    return {
      profiles:
        data?.no_profiles_found && filterStore.relationalFilter != 'All'
          ? []
          : (data?.profiles ?? ([] as ProfileCard[])),
      noProfiles: data?.no_profiles_found ?? false
    };
  }

  async function getSplashProfiles() {
    const { data, error: profilesError } = await profileFetchClient('/profile/sfw/filter', {
      method: 'POST',
      body: {
        locale: locale
      }
    });

    if (profilesError || !data) {
      return {
        profiles: [],
        noProfiles: true
      };
    }

    return {
      profiles: data?.profiles ?? ([] as ProfileCard[]),
      noProfiles: data.no_profiles_found
    };
  }

  function getTooltipText(lang: string = 'ENG') {
    return lang ? 'Physical contact not possible' : 'Fysiek contact niet mogelijk';
  }

  async function getProfileImages(ids: string[], size: number) {
    try {
      const { data } = await profileFetchClient('/profile/profile-images', {
        method: 'POST',
        body: {
          profile_ids: ids,
          size: size
        }
      });
      return data;
    } catch (e) {
      console.error(`unable to load profile image: ${e}`);
    }

    return [];
  }

  function extractCardFromProfile(profileData: ProfileData): ProfileCard {
    const profileCard: ProfileCard = {
      name: '',
      date_of_birth: '',
      city: '',
      image: profileData.avatar,
      profile_id: profileData.profile_id
    };

    if (profileData.attributes) {
      for (const attribute of profileData.attributes) {
        switch (attribute.name) {
          case 'profile_name':
            profileCard.name = attribute.value ?? '';
            break;
          case 'date_of_birth':
            profileCard.date_of_birth = attribute.value ?? '';
            break;
          case 'city':
            profileCard.city = attribute.value ?? '';
            break;
        }
      }
    }

    return profileCard;
  }
  async function getMatchGameProfiles(amount: number) {
    const query = buildFilterQuery();

    const filters: field[] = query.filters;

    // const geoQuery = buildGeoFilter();
    const subQuery = query.subquery;
    const { data, error: profilesError } = await profileFetchClient('/match', {
      method: 'POST',
      body: {
        limit: amount,
        offset: 1,
        should_order_randomly: true,
        query: {
          filter: {
            fields: filters,
            operation: 'AND'
          },
          operation: 'AND',
          sub_queries: subQuery
        },
        search: '',
        sort: ['created_at:asc']
      }
    });

    if (profilesError) {
      throwError(
        profilesError,
        'retrieving',
        'profiles',
        'call to profile-s2d-api POST /match failed',
        true
      );
    }
    return {
      profiles: data?.profiles ?? ([] as ProfileCard[]),
      noProfiles: data?.no_profiles_found
    };
  }

  async function likeFunction(profileID: string, like: boolean) {
    if (!profileID) return;
    const { data, error: profilesError } = await profileFetchClient(
      like ? '/match/like/{profileId}' : '/match/dislike/{profileId}',
      {
        method: 'POST',
        path: { profileId: profileID }
      }
    );

    if (profilesError) {
      const path = like ? '/match/like/{profileId}' : '/match/dislike/{profileId}';
      throwError(
        profilesError,
        like ? 'liking' : 'disliking',
        'profiles',
        'call to profile-s2d-api POST ' + path + ' failed'
      );
    }

    // TODO: Check
    return data?.matched ?? false;
  }

  async function unlikeFunction(profileID: string) {
    if (!profileID) return;
    const { error: profilesError } = await profileFetchClient('/match/unlike/{profileId}', {
      method: 'POST',
      path: { profileId: profileID }
    });

    if (profilesError) {
      throwError(
        profilesError,
        'unliking',
        'profiles',
        'call to profile-s2d-api POST /match/unlike/{profileId} failed'
      );
    }
  }

  async function deleteMe() {
    const { error: profilesError } = await profileFetchClient('/profile-user/me', {
      method: 'DELETE'
    });
    if (profilesError !== null) {
      console.error(profilesError);
      return false;
    }
    return true;
  }

  return {
    getProfileById,
    getProfiles,
    getSplashProfiles,
    getTooltipText,
    getProfileImages,
    extractCardFromProfile,
    favoriteFunction,
    getMatchGameProfiles,
    likeFunction,
    unlikeFunction,
    deleteMe
  };
});

