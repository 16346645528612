import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { NoRegionRegisterAccount, Nullable } from '../types';
import type { RegisterAccountBody } from '../types/identity/identityApi';

interface ConstantGlobals {
  profileGrid: ProfileGridConstantGlobals;
}
interface ProfileGridConstantGlobals {
  ITEM_WIDTH: number;
  MAX_ITEM_WIDTH: number;
  MIN_ITEM_WIDTH: number;
  ITEMS_PER_PAGE: number;
  MAX_COL_COUNT: number;
}

interface DynamicGlobals {
  profileGrid: ProfileGridDynamicGlobals;
  registration: RegistrationDynamicGlobals;
}

interface ProfileGridDynamicGlobals {
  forceFooter: boolean;
}

interface RegistrationDynamicGlobals {
  modal: 'form' | 'confirm' | 'finished';
  account: Nullable<NoRegionRegisterAccount>;
  registerBody: RegisterAccountBody | undefined;
}

export const useGlobalsStore = defineStore('globals', () => {
  const constantGlobals: ConstantGlobals = {
    profileGrid: {
      ITEM_WIDTH: 175,
      MAX_ITEM_WIDTH: 300,
      MIN_ITEM_WIDTH: 160,
      ITEMS_PER_PAGE: 48,
      MAX_COL_COUNT: 6
    }
  }; // TODO: make configurable in config file
  const dynamicDefaults: DynamicGlobals = {
    profileGrid: {
      forceFooter: false
    },
    registration: {
      modal: 'form',
      account: {
        username: null,
        password: null,
        confirmPassword: null,
        gender: 'm',
        lookingForGender: 'f',
        email: null,
        dateofbirth: null,
        city: null
      },
      registerBody: undefined
    }
  };
  const dynamicGlobals = ref<DynamicGlobals>(dynamicDefaults);

  return {
    constantGlobals,
    dynamicGlobals
  };
});

