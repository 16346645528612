import { useNuxtApp, useRequestEvent } from '#app';
import { trace, type Context, type Span } from '@opentelemetry/api';

export function getNuxtSpanContext(): Context | undefined {
  const spanContexts = useRequestEvent()?.node.req.spanContexts;
  if (!spanContexts || spanContexts.length === 0) return undefined;

  return spanContexts[spanContexts.length - 1];
}

export function startSpanWithNuxtContext(spanName: string): Span | undefined {
  const spanContext = getNuxtSpanContext();
  if (!spanContext) return undefined;

  const $tracer = useNuxtApp().$tracer;
  if (!$tracer) return undefined;

  const span = $tracer.startSpan(spanName, {}, spanContext);

  const req = useRequestEvent()?.node?.req;
  if (req) {
    req.spanContexts ??= [];
    req.spanContexts.push(trace.setSpan(spanContext, span))
  }

  return span;
}
