import type { MessageDocument } from '@horizon/chat-protocol-ts';
import type { chatProfileData } from '../types';
import { toRaw } from 'vue';

export function displayLastMessageContent(user: chatProfileData) {
  const lastMessage: MessageDocument | undefined = user.messages?.at(-1);

  if (lastMessage === undefined) {
    return '';
  }
  const content = lastMessage?.content;
  return lastMessage.pokeMessageType === 'normal' || lastMessage.pokeMessageType === 'flirt'
    ? 'You: ' + content
    : content;
}

export function displayLastMessageContentfromArray(messages: MessageDocument[]) {
  const rawMessages = toRaw(messages);
  if (rawMessages) {
    const lastIndex = rawMessages.length - 1;
    const lastMessage = rawMessages[lastIndex];
    if (lastMessage === undefined) {
      return '';
    }
    const content = lastMessage?.content;
    return content;
  }
  return '';
}

export function isChatRead(messages: MessageDocument[]) {
  if (!messages || messages.length < 1) {
    return true;
  }
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].isRead === false) return false;
  }
  return true;
}

