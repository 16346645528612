<script setup lang="ts">
import { createError } from '#app';
import {
  createProfilesFetchClient,
  createUseProfilesFetchClient
} from '../../composables/apiClients';
import type {
  AccountImage,
  HorizonNuxtErrorData,
  HorizonComponentProps,
  TranslationVars
} from '../../types';
import { ref } from 'vue';
import { useSiteConfigStore } from '../../stores';
import { storeToRefs, useNuxtApp } from '#imports';

const { $translate } = useNuxtApp();
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);

const props = defineProps<HorizonComponentProps>();

const alertStatus = ref<{ success: boolean; message: string }>();

const profileApi = createProfilesFetchClient();
const useProfileApi = createUseProfilesFetchClient();

const {
  data: profilesData,
  error: profilesError,
  refresh: profilesFetch
} = await useProfileApi('/profile-user/me', {});
if (!profilesData.value || profilesError.value) {
  const vars: TranslationVars = {
    whatVar: 'account details'
  };
  throw createError<HorizonNuxtErrorData>({
    data: {
      title: $translate(translations?.value?.loadError, vars),
      subtitle: $translate(translations?.value?.tryAgain),
      devTitle: 'Call to profile-s2d-api GET /profile-user/me failed',
      devMessage: profilesError.value?.message
    },
    stack: profilesError.value?.stack,
    fatal: true
  });
}

async function uploadImages(event: Event) {
  alertStatus.value = undefined;

  const fileList = (event.target as HTMLInputElement).files;
  if (!fileList) return;

  const formData = new FormData();
  Array.from(fileList).forEach((file) => {
    formData.append('files', file);
  });

  const postResult = await profileApi('/profile-user/me/upload-image', {
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: formData as any
  });

  alertStatus.value = postResult.error
    ? { success: false, message: 'Failed to upload picture...' }
    : { success: true, message: 'Picture upload was succesful!' };

  await profilesFetch();
}

async function makeImagePrimary(image: AccountImage) {
  if (!image.id) return;
  const attachment_ids = (profilesData.value?.image
    ?.map((x) => x.id)
    .filter((x) => !!x && x !== image.id) ?? []) as string[];
  attachment_ids.unshift(image.id);

  const patchResult = await profileApi('/profile-user/me/reorder', {
    method: 'PATCH',
    body: {
      attachment_ids
    }
  });

  alertStatus.value = patchResult.error
    ? { success: false, message: 'Failed to set profile picture...' }
    : { success: true, message: 'Profile picture has been set!' };

  await profilesFetch();
}

async function deleteImage(image: AccountImage) {
  alertStatus.value = undefined;
  if (!image.id) return;

  if (!window.confirm('Are you sure you want to delete this image?')) {
    return;
  }

  const deleteResult = await profileApi('/profile-user/me/delete-image', {
    method: 'DELETE',
    body: {
      attachment_ids: [image.id]
    }
  });

  alertStatus.value = deleteResult.error
    ? { success: false, message: 'Failed to delete image...' }
    : { success: true, message: 'image has been deleted!' };

  await profilesFetch();
}
</script>

<template>
  <div class="mx-auto max-w-6xl md:p-8">
    <div
      v-if="alertStatus"
      class="mb-4 w-full rounded-lg py-2 text-center text-sm text-white"
      :class="[alertStatus.success ? 'bg-success' : 'bg-danger']"
    >
      <strong class="text-white">{{ alertStatus.message }}</strong>
    </div>

    <section v-if="profilesData">
      <ul
        v-if="profilesData.image && profilesData.image.length > 0"
        class="mb-8 grid grid-cols-2 gap-4 md:min-w-40"
      >
        <li v-for="(image, index) in profilesData.image" :key="image.url" class="w-52">
          <button
            class="bg-primary text-btn-primary-text relative left-[180px] top-6 rounded-sm"
            type="button"
            :data-testid="props.testId + '/DeleteButton' + index"
            @click="deleteImage(image)"
          >
            <Icon size="20" name="material-symbols:delete" />
          </button>
          <img :src="image.url" alt="" class="rounded-m w-full border-2 border-black" />

          <p class="mt-1 flex items-center justify-between gap-2">
            <span v-if="image.isPrimary"></span>
            <button
              v-else
              class="w-full cursor-pointer rounded-md bg-gray-600 text-center font-thin"
              :data-testid="props.testId + '/SetProfilePictureButton' + index"
              @click="makeImagePrimary(image)"
            >
              Set as Profile Picture
            </button>
          </p>
        </li>
        <div class="text-header-text mt-4 w-full text-sm">
          <!-- Accepted mimetypes from profile-service / media api: profile-service/services/media/types.go -->
          <input
            id="image-upload"
            class="hidden"
            type="file"
            name="image-upload"
            accept="image/jpeg, image/jp2, image/jxl, image/png, image/gif"
            multiple
            :data-testid="props.testId + '/Upload2nd+ImagesInput'"
            @change="uploadImages"
          />
          <label
            for="image-upload"
            class="bg-btn-primary-bg text-btn-primary-text shadow-glow flex w-full cursor-pointer flex-row place-items-center rounded-lg py-2"
          >
            <Icon size="25" :name="'bi:plus-circle'" class="ml-auto mr-2" />
            <span class="mr-auto text-inherit"> Add Picture </span>
          </label>
        </div>
      </ul>

      <div v-else class="mb-4 mt-1 flex flex-col place-items-center">
        <div class="text-header-text/60 text-xs">You do not have any pictures uploaded yet</div>
        <div class="text-header-text mt-3 text-2xl">Using pictures attracts more visitors</div>
        <div class="text-header-text mt-2">
          Start uploading some pictures to increase your chances!
        </div>
        <div class="text-header-text mt-4 w-full text-sm">
          <!-- Accepted mimetypes from profile-service / media api: profile-service/services/media/types.go -->
          <input
            id="image-upload"
            class="hidden"
            type="file"
            name="image-upload"
            accept="image/jpeg, image/jp2, image/jxl, image/png, image/gif"
            multiple
            :data-testid="props.testId + '/Upload1stImagesInput'"
            @change="uploadImages"
          />
          <label
            for="image-upload"
            class="bg-btn-primary-bg text-btn-primary-text shadow-glow flex w-full cursor-pointer flex-row place-items-center rounded-lg py-2"
          >
            <Icon size="25" :name="'bi:plus-circle'" class="ml-auto mr-2" />
            <span class="mr-auto text-inherit"> Add Picture </span>
          </label>
        </div>
      </div>
    </section>
  </div>
</template>

