import { useState } from '#app';
import TTLCache from "@isaacs/ttlcache"

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

const cache = new TTLCache<string, unknown>({
  ttl: FIVE_MINUTES_IN_MS
})

export async function useCachedFn<T>(key: string, fn: () => Promise<T>): Promise<T> {
  const hydrationPayload = useState<T>('cached-' + key);

  if (import.meta.server) {
    if (cache.has(key)) {
      const oldData = cache.get(key) as T;
      hydrationPayload.value = oldData; // Always fill CSR hydration payload
      return oldData;
    }

    const result = await fn();
    cache.set(key, result);
    hydrationPayload.value = result; // Always fill CSR hydration payload
    return result;
  }

  if (hydrationPayload.value) {
    return hydrationPayload.value;
  }

  return fn();
}
