<script setup lang="ts">
import type { HorizonComponentProps } from '../../types';
import { overrideStyles } from '../../utils';
import { inject, type Component } from 'vue';
import type { Props } from './Center.schema';

const isDev = import.meta.dev;
const props = defineProps<HorizonComponentProps<Props>>();
const components = inject<Record<string, Component>>('components');
</script>

<template>
  <main v-if="components" class="flex flex-col items-center justify-center">
    <div v-for="(component, index) in props.children" :key="index">
      <component
        :is="components[component.name]"
        :data="component.data"
        :data-component="isDev ? component.name : null"
        :children="component.children"
        :overrides="component.overrides"
        :style="overrideStyles(component.overrides)"
        :data-testid="props.testId"
      />
    </div>
  </main>
</template>

