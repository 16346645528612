import { useAuthStore, useSiteConfigStore } from '@horizon/library/stores';
import type { HorizonNuxtErrorData, Page, TranslationVars } from '@horizon/library/types';
import { navigateTo } from '#app';

export default defineNuxtRouteMiddleware(async (to) => {
  useRequestEvent()?.node.req.middlewareSpan?.addEvent('route guard');

  // IGNORE_ROUTE_GUARD is added to the route by apps/horizon/horizon-ui-site/app/router.options.ts
  if (to?.meta?.IGNORE_ROUTE_GUARD) return;
  const { $translate } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const authStore = useAuthStore();
  const siteConfigStore = useSiteConfigStore();
  const { siteSettings, pages, theme, translations } = storeToRefs(siteConfigStore);

  const guestHomeRoute = siteSettings.value?.unauthorizedRedirectRoute ?? '/';
  const userHomeRoute = siteSettings.value?.authorizedRedirectRoute ?? '/';

  const page: Page | undefined = getPageFromRoutePath(pages.value ?? [], to.path);
  if (!page) {
    const guestPage: Page | undefined = getPageFromRoutePath(pages.value ?? [], guestHomeRoute);
    if (!guestPage) {
      const vars: TranslationVars = {
        invalidVar: 'site configuration'
      };
      throw createError<HorizonNuxtErrorData>({
        data: {
          title: $translate(translations?.value?.invalidError, vars),
          subtitle: $translate(translations?.value?.pageNotFound),
          devTitle: 'route guard middleware',
          devMessage: 'page not found AND guest page not found...',
          theme: theme.value
        },
        fatal: true
      });
    }

    return navigateTo(guestHomeRoute, { redirectCode: 404 });
  }

  const shouldValidateAuth = !runtimeConfig.public.isInPreviewer;
  if (!shouldValidateAuth) return;

  await authStore.initialLoad();

  if (page.auth && !authStore.isAuthorized) {
    return navigateTo(guestHomeRoute, {
      redirectCode: 401
    });
  }

  if (page.path === guestHomeRoute && authStore.isAuthorized) {
    return navigateTo(userHomeRoute, {
      redirectCode: 302
    });
  }
});

