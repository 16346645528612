import type { Page } from '@horizon/library/types';
import { checkUserConsent } from '@horizon/library/utils/consentChecker';
import configData from '~/static/config.json';

export default defineNuxtRouteMiddleware(async (to) => {
  useRequestEvent()?.node.req.middlewareSpan?.addEvent('consent checker');

  // If page does not need authentication, do not show consent modal
  const page = getPageFromRoutePath(configData.pages as Page[], to.path);
  if (!page?.auth) {
    return;
  }

  await checkUserConsent();
});

