<script setup lang="ts">
import { navigateTo } from '#app';
import { storeToRefs } from 'pinia';
import type { VNode } from 'vue';
import { h, ref } from 'vue';
import { fetchLegalText } from '../../composables/legalTextFetch';
import { useSiteConfigStore } from '../../stores';
import type { HorizonComponentProps } from '../../types/config/types';
import ContactForm from '../Forms/Contact.vue';
import PricingTable from './PricingTable.vue';
import SponsorSettings from './SponsorSettings.vue';
import type { TextFetcherProps } from './TextFetcher.schema';
import TextRenderer from './TextRenderer.vue';

const siteConfigStore = useSiteConfigStore();
const { localeCode, companyId } = storeToRefs(siteConfigStore);
const props = defineProps<
  {
    legalTextfetchError: string;
  } & HorizonComponentProps<TextFetcherProps>
>();
const components = ref<VNode[]>([]);
const hasError = ref<boolean>();

async function loadLegalText() {
  const legalTextKey = props?.data?.legalKey;
  if (!localeCode.value || !legalTextKey) {
    return await navigateTo('/');
  }

  const legalData = await fetchLegalText(localeCode.value, legalTextKey, companyId.value).catch(
    () => {
      hasError.value = true;
    }
  );

  if (!legalData) return;

  const replacedText = legalData?.replacedText;

  // Create a list of VNodes by splitting up the html into blocks of html content and the different vue components.
  components.value = replacedText
    .split(/\[(ContactForm|PricingTable|SponsorSettings)\]/)
    .map((htmlSegment) => {
      switch (htmlSegment) {
        case 'ContactForm':
          return h(ContactForm);
        case 'PricingTable':
          return h(PricingTable);
        case 'SponsorSettings':
          return h(SponsorSettings);
        default:
          return h(TextRenderer, {
            data: { text: htmlSegment, footer: props.data?.footer ?? false },
            testId: null
          });
      }
    });
}

await loadLegalText();

type Renderer = {
  text: string;
  footer?: boolean;
  externalUrl?: string;
};
const renderData: Renderer = { text: props.legalTextfetchError! };
</script>

<template>
  <div class="container">
    <TextRenderer
      v-if="hasError"
      class="text-text"
      :data="renderData"
      :test-id="null"
    ></TextRenderer>

    <!-- eslint-disable-next-line vue/valid-v-for -->
    <component :is="component" v-for="component of components" v-else />
  </div>
</template>

