<script setup lang="ts">
import { navigateTo, useRuntimeConfig } from '#app';
import { storeToRefs } from '#imports';
import { useFocus, useDebounceFn } from '@vueuse/core';
import { onMounted, ref, watch, computed } from 'vue';
import type { ZodFormattedError } from 'zod';
import { useAuthStore, useModalStore, useSiteConfigStore, useGlobalsStore } from '../../../stores';
import { useGeoStore } from '../../../stores/geo.store';
import {
  EModal,
  NoRegionRegisterAccountSchema,
  type Nullable,
  type NoRegionRegisterAccount,
  type HorizonComponentProps
} from '../../../types';
import type { RegisterAccountBody } from '../../../types/identity/identityApi';
import { getDeviceType } from '../../../utils/deviceType';
import { storageKeys } from '../../../utils';
import ButtonGoogleTransparent from '../../Button/GoogleTransparent.vue';
import FormErrorRenderer from '../../Forms/FormErrorRenderer.vue';
import { usePartnerTrackingStore } from '../../../stores/partnerTracking';
import type { GeoWizardCity, CityDropdownItem } from '../../../types/geo/geo';
import { fetchLegalVariables } from '../../../composables/legalTextFetch';

const props = defineProps<HorizonComponentProps>();
const googleSsoUrl = useRuntimeConfig().public.googleSsoUrl as string;
const siteConfigStore = useSiteConfigStore();
const { localeCode, companyId } = storeToRefs(siteConfigStore);
const domain = siteConfigStore.siteSettings?.name;
const logo = { src: siteConfigStore.siteSettings?.favicon, alt: 'logo' };
const modalStore = useModalStore();
const authStore = useAuthStore();
const globals = useGlobalsStore();
const geoStore = useGeoStore();
const { geoCityOptions } = storeToRefs(geoStore);
const geoCity = ref<GeoWizardCity>();
const cityInput = ref<string>('');
const showCitiesDropdown = ref<boolean>(false);

const legalVariables = await fetchLegalVariables(
  localeCode.value,
  'companyInformation',
  companyId.value
);

const modalToShow = ref<'form' | 'confirm' | 'finished'>(globals.dynamicGlobals.registration.modal);

const account = ref<Nullable<NoRegionRegisterAccount>>(globals.dynamicGlobals.registration.account);
const accountErrors = ref<ZodFormattedError<NoRegionRegisterAccount> | undefined>();
const acceptedTerms = ref<boolean>(false);
const showAlert = ref<null | 'confirmTerms' | 'activateEmail' | 'resendSucces' | 'resendFail'>(
  null
);
const registerBody = ref<RegisterAccountBody | undefined>(
  globals.dynamicGlobals.registration.registerBody
);
const isRegistering = ref<boolean>(false);
const dobPicker = ref<HTMLInputElement>();
const showTooltip = ref<boolean>(false);
const mailBoxURL = computed(() => getMailboxURL(account.value.email));

watch(
  () => account.value,
  () => {
    globals.dynamicGlobals.registration.account = account.value;
  },
  { deep: true }
);

watch(
  () => modalToShow.value,
  () => {
    globals.dynamicGlobals.registration.modal = modalToShow.value;
  },
  { deep: true }
);

watch(
  () => registerBody.value,
  () => {
    globals.dynamicGlobals.registration.registerBody = registerBody.value;
  },
  { deep: true }
);
onMounted(async () => {
  // If we sign in via sso, the redirect to the /sso-auth page loads 'ssoEmail' in the authStore, meaning we skip the first modal page
  if (authStore.ssoEmail) {
    account.value.email = authStore.ssoEmail;
    account.value.password = 'SSOSSO';
    account.value.confirmPassword = 'SSOSSO';
  }

  await geoStore.fetchGeoRegions();
});

useFocus(dobPicker, { initialValue: true });

async function register() {
  accountErrors.value = undefined;
  const parsedForm = NoRegionRegisterAccountSchema.safeParse(account.value);

  if (!parsedForm.success) {
    accountErrors.value = parsedForm.error.format();
    return;
  }

  if (!geoCity.value) {
    accountErrors.value!._errors.push('City not found.');
    return;
  }

  const location = {
    city: geoCity.value.city,
    iso: geoStore.countryCode as string,
    language: siteConfigStore.localeCode?.split('_')[0] as string,
    latitude: geoCity.value.latitude,
    locality: geoCity.value.city,
    longitude: geoCity.value.longitude,
    region1: geoCity.value.region_1,
    region2: geoCity.value.region_2,
    region3: geoCity.value.region_3,
    region4: geoCity.value.region_4,
    timezone: geoCity.value.timezone === '' ? 'America/New_York' : geoCity.value.timezone
  };
  if (!location) {
    accountErrors.value!._errors.push('Location not found.');
    return;
  }

  const device = getDeviceType();
  const domain = window.location.hostname;
  registerBody.value = {
    username: parsedForm.data.username,
    password: parsedForm.data.password,
    gender: parsedForm.data.gender,
    lookingForGender: parsedForm.data.lookingForGender,
    location: location,
    email: parsedForm.data.email,
    dateOfBirth: parsedForm.data.dateofbirth,
    device,
    domain,
    isSSO: false,
    isHorizon: true
  };

  const partnerTrackingStore = usePartnerTrackingStore();
  const partnerParams = partnerTrackingStore.buildPartnerParams();
  Object.assign(registerBody.value, partnerParams);

  if (authStore.ssoEmail) {
    registerBody.value.isSSO = true;
    registerBody.value.password = undefined;
  }

  showAlert.value = null;
  modalToShow.value = 'confirm';
}

async function confirmTerms() {
  isRegistering.value = true;
  const succes = await _confirmTerms();
  isRegistering.value = false;
  if (succes) modalToShow.value = 'finished';
}

async function _confirmTerms() {
  showAlert.value = null;

  if (!acceptedTerms.value) {
    showAlert.value = 'confirmTerms';
    return false;
  }

  accountErrors.value = {
    _errors: []
  };

  const registerResult = await authStore.registerAccount(registerBody.value!);
  if (typeof sessionStorage !== 'undefined')
    sessionStorage.setItem(storageKeys.register.Result, JSON.stringify(registerResult.data));

  if (registerResult.errorCode) {
    accountErrors.value._errors.push('Account registration failed.');
    return false;
  }

  if (registerResult.data?.token) {
    await immediateSignon(registerResult.data.token);
  }

  authStore.ssoEmail = undefined;
  return true;
}

async function immediateSignon(token: string) {
  const { errorCode } = await authStore.loginWithCustomToken(token);
  if (errorCode) return;

  modalStore.clearModal();

  const authorizedRedirectRoute = siteConfigStore.siteSettings?.authorizedRedirectRoute ?? '/';

  await navigateTo(authorizedRedirectRoute);
}

function showLoginModal() {
  modalStore.showModalComponent(EModal.loginModalComponent);
}

async function updateCityOptions() {
  account.value.city = null;
  if (cityInput.value.length > 2) {
    await geoStore.fetchCitiesQuery(cityInput.value);
    showCitiesDropdown.value = true;
  } else {
    showCitiesDropdown.value = false;
  }
}

const debounceUpdateCityOptions = useDebounceFn(async () => {
  await updateCityOptions();
}, 250);

function setCity(cityOption: CityDropdownItem) {
  if (cityOption.value === null) return;
  cityInput.value = `${cityOption.label} (${cityOption.value?.state})`;
  geoCity.value = cityOption.value;
  account.value.city = cityOption.label;
  showCitiesDropdown.value = false;
}

const microsoftDomains = ['outlook', 'hotmail', 'msn', 'live'];
const appleDomains = ['icloud', 'me', 'mac'];
function getMailboxURL(email: string | null) {
  if (email === null) return undefined;
  const domain = email.split('@')[1];
  const provider = domain.split('.')[0].toLowerCase();
  if (provider === 'gmail') return 'https://mail.google.com/mail/u/2/#search/Horizonsingles';
  if (provider === 'yahoo') return 'https://login.yahoo.com/';
  if (microsoftDomains.includes(provider)) return 'https://login.live.com/';
  if (appleDomains.includes(provider)) return 'https://www.icloud.com/mail';
  return undefined;
}

const debouncedResendEmail = useDebounceFn(async () => {
  const succes = await authStore.resendActivationMail();
  showAlert.value = succes ? 'resendSucces' : 'resendFail';
  setTimeout(() => {
    showAlert.value = null;
  }, 3000);
}, 250);
</script>

<template>
  <div
    class="bg-background absolute max-h-full max-w-full overflow-auto rounded-2xl p-6"
    :class="{
      'w-modal-2xl': modalToShow === 'finished',
      'w-modal-lg': modalToShow !== 'finished'
    }"
  >
    <div
      class="border-header-text/20 absolute right-2 top-2 flex h-10 w-10 cursor-pointer place-content-center place-items-center rounded-md border"
    >
      <Icon
        name="ic:sharp-close"
        size="25"
        class="text-header-text hover:text-primary"
        :data-testid="props.testId + '/CloseButton'"
        @click="modalStore.clearModal()"
      />
    </div>
    <div v-if="modalToShow === 'form'">
      <div class="flex flex-col">
        <img class="mx-auto mb-4 h-6 w-6" :src="logo.src" :alt="logo.alt" />
        <h1 class="text-header-text mx-auto pb-1 text-2xl font-bold">Sign up for Free!</h1>
        <h2 class="text-footer-text mx-auto pb-4">No Credit Card required!</h2>
      </div>

      <p v-if="authStore.ssoEmail" class="text-header-text text-center text-sm">
        Registering with Google: {{ authStore.ssoEmail }}
      </p>
      <ButtonGoogleTransparent
        v-else
        class="mx-auto mb-3 w-52 text-sm"
        :data="{
          href: googleSsoUrl,
          type: 'register'
        }"
        :test-id="props.testId"
      />

      <div class="my-8 border border-[#333741]"></div>
    </div>

    <FormErrorRenderer class="mb-3" :zod-errors="accountErrors" />

    <template v-if="modalToShow === 'form'">
      <div>
        <form class="grid gap-4 md:grid-cols-6 md:gap-8" @submit.prevent="register()">
          <div class="md:col-span-3">
            <p class="text-header-text">I am a</p>
            <select
              id="gender"
              v-model="account.gender"
              name="gender"
              class="control"
              :class="{ '!border-danger': !!accountErrors?.gender }"
              :data-testid="props.testId + '/GenderSelect'"
            >
              <option selected value="m" :data-testid="props.testId + '/ManGenderOption'">
                Man
              </option>
              <option value="f" :data-testid="props.testId + '/WomanGenderOption'">Woman</option>
            </select>
            <FormErrorRenderer :zod-errors="accountErrors" property="gender" />
          </div>

          <div class="md:col-span-3">
            <p class="text-header-text">I am looking for</p>

            <div class="relative">
              <select
                id="lookingForGender"
                v-model="account.lookingForGender"
                name="lookingForGender"
                class="control"
                :class="{ '!border-danger': !!accountErrors?.lookingForGender }"
                :data-testid="props.testId + '/Looking4GenderSelect'"
              >
                <option
                  selected
                  value="f"
                  :data-testid="props.testId + '/WomanLooking4GenderOption'"
                >
                  Women
                </option>
                <option value="m" :data-testid="props.testId + '/ManLooking4GenderOption'">
                  Men
                </option>
              </select>

              <VTooltip
                v-model="showTooltip"
                class="absolute bottom-2 right-6"
                placement="top-start"
                distance="10"
                :show-triggers="['click']"
                :hide-triggers="['click']"
              >
                <div>
                  <Icon
                    class="hover:text-primary text-gray-600"
                    name="fa6-solid:circle-question"
                    size="24px"
                  />
                </div>

                <template #popper>
                  <div class="w-80">
                    <strong class="text-primary">Why?</strong>
                    I am aware that by filling in my sexual preference,
                    {{ legalVariables.company.name }}
                    will save and process my personal data.
                    <NuxtLink class="underline" to="/privacy" @click="modalStore.clearModal()">
                      Click here for more information
                    </NuxtLink>
                  </div>
                </template>
              </VTooltip>
            </div>
            <FormErrorRenderer :zod-errors="accountErrors" property="lookingForGender" />
          </div>

          <div class="md:col-span-3">
            <p class="text-header-text">Date of Birth</p>

            <div class="relative">
              <input
                id="dateofbirth"
                ref="dobPicker"
                v-model="account.dateofbirth"
                type="date"
                name="dateofbirth"
                placeholder="Birthdate"
                class="control"
                :class="{ '!border-danger': !!accountErrors?.dateofbirth }"
                :data-testid="props.testId + '/DOBinput'"
              />

              <Icon
                name="mdi:calendar"
                size="25"
                class="text-header-text hover:text-primary absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2"
                :data-testid="props.testId + '/DOBPickerButton'"
                @click="dobPicker?.showPicker()"
              />
            </div>

            <FormErrorRenderer :zod-errors="accountErrors" property="dateofbirth" />
          </div>

          <div class="relative md:col-span-3">
            <p class="text-header-text">Select city</p>
            <input
              id="city"
              v-model="cityInput"
              type="text"
              placeholder="Which city are you in?"
              class="control-no-color"
              :class="{
                '!border-danger': !!accountErrors?.city,
                'text-primary': account.city === null,
                'text-header-text': account.city != null
              }"
              name="city"
              :data-testid="props.testId + '/CityInput'"
              @input="debounceUpdateCityOptions"
            />
            <ul
              v-if="showCitiesDropdown"
              class="bg-modal-bg text-modal-text/60 max-h-modal-sm absolute w-full overflow-y-auto border border-[#2a3b65]"
            >
              <li
                v-for="city of geoCityOptions"
                :key="city._id"
                class="hover:text-modal-text hover:bg-primary py-2 pl-2"
                @click="setCity(city)"
              >
                {{ `${city.label} (${city.value?.state})` }}
              </li>
            </ul>
            <FormErrorRenderer :zod-errors="accountErrors" property="city" />
          </div>

          <div v-if="!authStore.ssoEmail" class="md:col-span-3">
            <p class="text-header-text">E-mail</p>
            <input
              id="email"
              ref="emailInput"
              v-model="account.email"
              type="text"
              name="email"
              placeholder="Your e-mail address"
              class="control"
              :class="{ '!border-danger': !!accountErrors?.email }"
              :data-testid="props.testId + '/EmailInput'"
            />
            <FormErrorRenderer :zod-errors="accountErrors" property="email" />
          </div>

          <div class="md:col-span-3">
            <p class="text-header-text">Username</p>
            <input
              id="username"
              ref="usernameInput"
              v-model="account.username"
              type="text"
              name="username"
              placeholder="Insert a username; e.g. Stargazer"
              class="control"
              :class="{ '!border-danger': !!accountErrors?.username }"
              :data-testid="props.testId + '/UsernameInput'"
            />
            <FormErrorRenderer :zod-errors="accountErrors" property="username" />
          </div>

          <div v-if="!authStore.ssoEmail" class="md:col-span-6">
            <p class="text-header-text">Password</p>
            <input
              id="password"
              v-model="account.password"
              type="password"
              name="password"
              placeholder="Create a password"
              class="control"
              :class="{ '!border-danger': !!accountErrors?.password }"
              :data-testid="props.testId + '/PasswordInput'"
            />
            <FormErrorRenderer :zod-errors="accountErrors" property="password" />
          </div>

          <div v-if="!authStore.ssoEmail" class="md:col-span-6">
            <p class="text-header-text">Confirm Password</p>
            <input
              id="passwordConfirm"
              v-model="account.confirmPassword"
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              class="control"
              :class="{ '!border-danger': !!accountErrors?.password }"
              :data-testid="props.testId + '/ConfirmPasswordInput'"
            />
            <FormErrorRenderer :zod-errors="accountErrors" property="confirmPassword" />
          </div>

          <ButtonSimple
            class="w-full md:col-span-6"
            type="submit"
            :data="{
              label: 'Join now!',
              isLarge: true,
              wideSpacing: true
            }"
            :test-id="props.testId"
          />
        </form>
      </div>
      <div class="group mb-4 flex flex-row place-content-center text-sm">
        <a
          class="text-footer-text mt-3 block text-center"
          href="#"
          :data-testid="props.testId + '/LoginLinkPt1'"
          @click="showLoginModal()"
        >
          Already have an account?
        </a>
        <a
          class="text-header-text group-hover:text-primary ml-2 mt-3 block text-center"
          href="#"
          :data-testid="props.testId + '/LoginLinkPt2'"
          @click="showLoginModal()"
        >
          Log in
        </a>
      </div>
    </template>
    <template v-if="modalToShow === 'confirm'">
      <div
        v-if="showAlert === 'confirmTerms'"
        class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
      >
        <strong>You need to accept the Terms & Conditions to make use of this website</strong>
      </div>

      <h2 class="text-header-text mb-2 text-center text-2xl">
        Please check your details and confirm your profile
      </h2>

      <div class="flex flex-col">
        <div class="mx-auto mb-3">
          <input
            id="username"
            type="checkbox"
            name="username"
            class="accent-primary"
            checked
            :data-testid="props.testId + '/UsernameCheckbox'"
          />
          <div class="ms-2 inline-flex flex-col">
            <label class="text-header-text" for="username"
              >Your username: <strong class="text-primary">{{ account.username }}</strong></label
            >
          </div>
        </div>

        <div class="mx-auto mb-3">
          <input
            id="email"
            type="checkbox"
            name="email"
            class="accent-primary"
            checked
            :data-testid="props.testId + '/EmailCheckbox'"
          />
          <div class="ms-2 inline-flex flex-col">
            <label class="text-header-text" for="email"
              >Your email: <strong class="text-primary">{{ account.email }}</strong></label
            >
          </div>
        </div>
      </div>

      <ButtonSimple
        v-if="showAlert !== 'activateEmail'"
        class="w-full"
        :data="{
          label: 'Confirm',
          isLarge: true,
          isDisabled: !acceptedTerms,
          isSaving: isRegistering,
          wideSpacing: true,
          uppercase: true
        }"
        :test-id="props.testId"
        @click="confirmTerms()"
      />

      <div class="text-header-text mt-3 text-sm leading-4">
        <input
          id="terms"
          v-model="acceptedTerms"
          class="accent-primary me-2"
          type="checkbox"
          name="terms"
          :data-testid="props.testId + '/TermsCheckbox'"
        />
        <label for="terms">
          By becoming a member, I agree to the
          <strong class="text-primary italic">
            <NuxtLink
              to="/agreement"
              target="_blank"
              :data-testid="props.testId + '/Terms&ConditionsLink'"
            >
              Terms and Conditions
            </NuxtLink>
          </strong>
          and the use of fictional entertainers’ profiles, marked with a heart icon, with which
          physical contact is not possible. By submitting my account information, I understand that
          Inovo Internet Innovation B.V. will process my personal data in accordance with the
          <strong class="text-primary italic">
            <NuxtLink to="/privacy" target="_blank" :data-testid="props.testId + '/PrivacyLink'"
              >Privacy statement</NuxtLink
            > </strong
          >.
        </label>
      </div>
    </template>
    <template v-else-if="modalToShow === 'finished'">
      <Transition>
        <div
          v-if="showAlert === 'resendSucces'"
          class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm text-black"
        >
          <strong>Activation mail has been resend!</strong>
        </div>
      </Transition>
      <Transition>
        <div
          v-if="showAlert === 'resendFail'"
          class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
        >
          <strong>Something went wrong. Please try again later...</strong>
        </div>
      </Transition>

      <div class="flex flex-col">
        <h2 class="text-primary mb-4 text-3xl font-bold uppercase">Congratulations!</h2>
        <div class="mb-4">
          <h2 class="text-header-text text-xl">
            You've successfully created your profile on {{ domain }}
          </h2>
          <h2 class="text-header-text/50 text-lg">
            Open the e-mail from us and click on the "Complete registration" button.
          </h2>
        </div>
        <label class="text-primary mb-8 text-lg font-bold" for="email"
          >Your email: <strong class="text-primary">{{ account.email }}</strong></label
        >
        <a
          v-if="account.email && mailBoxURL !== undefined"
          :href="mailBoxURL"
          target="_blank"
          rel="nofollow"
          class="btn bg-primary mb-4 flex h-12 w-3/5 border-0 uppercase"
          :data-testid="props.testId + '/GoToMailButton'"
        >
          <p class="text-modal-bg mx-auto my-auto text-xl font-bold">Go to your e-mail</p>
        </a>

        <p
          class="text-header-text hover:text-primary mb-4 w-1/3 text-xl underline hover:cursor-pointer"
          @click="debouncedResendEmail"
        >
          Resend activation e-mail
        </p>

        <div>
          <h1 class="text-header-text text-xl font-bold uppercase">important:</h1>
          <p class="text-header-text text-lg">
            &#x2022; It can take up to 5 minutes for the activation e-mail to arrive
          </p>
          <p class="text-header-text text-lg">
            &#x2022; Check you re-mail account's "Spam/Junk" folder to make sure our e-mail hasn't
            landed there
          </p>
          <p class="text-header-text text-lg">
            &#x2022; Mark us as "Not junk/spam" to ensure that you receive all important e-mails
            from {{ domain }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.control {
  @apply text-header-text bg-header-bg border-header-text/20 h-10 w-full rounded-md border px-2 py-1 text-sm;
}

.control-no-color {
  @apply bg-header-bg border-header-text/20 h-10 w-full rounded-md border px-2 py-1 text-sm;
}

[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input[type='date']::-webkit-datetime-edit-day-field:focus,
input[type='date']::-webkit-datetime-edit-month-field:focus,
input[type='date']::-webkit-datetime-edit-year-field:focus {
  @apply text-header-text bg-primary outline-none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

