import type { FirebaseError} from 'firebase/app';
import { getApps, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, type Auth } from 'firebase/auth';
import type { Firestore} from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { defineStore } from 'pinia';
import { createChatFetchClient } from '../composables/apiClients';
import { useRuntimeConfig } from '#app';
import { computed, ref } from 'vue';
import { jwtDecode, type JwtPayload } from 'jwt-decode';

const CLOUD_FIRESTORE_APP_NAME = 'cloud';

interface CHBToken extends JwtPayload {
  claims: {
    email: string;
    geos: string[];
    locale: string;
    snowflake: boolean;
    tenant: string;
    user_id: string;
  };
}

export const useCloudFireStore = defineStore('cloudFirestore', () => {
  const chatPostClient = createChatFetchClient();
  const { chatHomeBaseFirestoreConfig } = useRuntimeConfig().public;

  const chbToken = ref<CHBToken>();

  const isTokenExpired = computed<boolean>(() => {
    if (!chbToken.value) return true;
    if (chbToken.value.exp === undefined) return true;

    const inTenMinutes = Date.now() + 1000 * 60 * 10;
    return inTenMinutes >= chbToken.value.exp * 1000;
  });

  const isSnowflake = computed<boolean>(() =>
    chbToken.value //
      ? chbToken.value.claims.snowflake
      : true
  );

  async function getCloudFirestoreToken(): Promise<string | undefined> {
    const { data, error: messageError } = await chatPostClient('/auth/token', {
      method: 'POST'
    });

    if (!data || messageError) {
      console.error(`unable to get firestore token: ${messageError}`);
      return undefined;
    }

    return data.token;
  }

  async function authenticateCloudFirestoreApp(cloudAuth: Auth, customToken: string) {
    let resultCode: string | undefined;
    const result = await signInWithCustomToken(cloudAuth, customToken).catch((e: FirebaseError) => {
      resultCode = e.code;
    });

    if (resultCode || !result) {
      return {
        errorCode: resultCode ?? 'auth/invalid-credential'
      };
    }

    const idToken = await result.user.getIdToken().catch();
    if (!idToken)
      return {
        errorCode: 'auth/idtoken-fetch-failed'
      };

    return {
      errorCode: undefined
    };
  }

  async function getCloudFirestore(): Promise<Firestore | undefined> {
    const existingCloudApp = getApps().find((x) => x.name === CLOUD_FIRESTORE_APP_NAME);
    if (existingCloudApp) getFirestore(existingCloudApp);

    if (!chatHomeBaseFirestoreConfig) {
      console.error('Could not initialize cloud firestore instance: firebaseOptions not provided');
      return;
    }

    const cloudApp = initializeApp(chatHomeBaseFirestoreConfig, CLOUD_FIRESTORE_APP_NAME);
    const cloudAuth = getAuth(cloudApp);

    if (isTokenExpired.value) {
      const customToken = await getCloudFirestoreToken();
      if (!customToken) {
        console.error('Error authenticating with firestore: unable to get firestore token');
        chbToken.value = undefined;
        return;
      }

      const authenticationResult = await authenticateCloudFirestoreApp(cloudAuth, customToken);
      if (authenticationResult.errorCode != undefined) {
        console.error('Error authenticating with firestore', authenticationResult.errorCode);
        chbToken.value = undefined;
        return;
      }

      chbToken.value = jwtDecode<CHBToken>(customToken);
      // TODO: Remove after testing in acceptance
      console.info('chbToken', chbToken.value)
    }

    return getFirestore(cloudApp);
  }

  return {
    chbToken,
    isSnowflake,

    getCloudFirestore
  };
});

