<script setup lang="ts">
import type { HorizonComponentProps } from '../../types';

const props = defineProps<HorizonComponentProps>();
</script>
<template>
  <form class="text-text mt-3 flex flex-col gap-3" action="#">
    <input
      class="border-button border-2 border-solid px-3 py-2"
      type="text"
      required
      placeholder="Your name:"
      :data-testid="props.testId + '/NameInput'"
    />

    <input
      class="border-button border-2 border-solid px-3 py-2"
      type="email"
      required
      placeholder="Your email:"
      :data-testid="props.testId + '/EmailInput'"
    />

    <select
      class="border-button border-2 border-solid px-3 py-2 text-black"
      required
      :data-testid="props.testId + '/SubjectSelect'"
    >
      <option value="" selected="true" disabled>Choose subject:</option>
      <option>Payment</option>
      <option>Technical issue</option>
      <option>Feedback</option>
      <option>Other</option>
    </select>

    <textarea
      class="border-button border-2 border-solid px-3 py-2"
      required
      placeholder="Write a message..."
      :data-testid="props.testId + '/MessageTextArea'"
    ></textarea>

    <button class="btn w-min" :data-testid="props.testId + '/SendButton'">SEND</button>
  </form>
</template>

