<script lang="ts" setup>
import type { MessageDocument } from '@horizon/chat-protocol-ts';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, toRaw, watch } from 'vue';
import { useRoute } from 'vue-router';
import {
  useChatStore,
  useMailStore,
  useSiteConfigStore
} from '../../stores';
import {
  getEmptyProfile,
  getSingleRouteParam,
  isChatRead,
  randomBool, // TODO: replace with actual func when we have profile = online/offline data
  unixToFromNowDate
} from '../../utils';
import { getFlirtDataFromText } from '../../utils/flirt';
import ChatBox from '../ChatBox/Modernfling.vue';
import type { chatProfileData, HorizonComponentProps } from '../../types';

const props = defineProps<HorizonComponentProps>();
const chatStore = useChatStore();
const tempRouteVar = useRoute();
const route = toRaw(tempRouteVar);

const { ids, profiles } = storeToRefs(chatStore);
const currentTab = ref('chat');
const pageProfiles = computed<chatProfileData[]>(() =>
  profiles.value.filter(
    (storeProfile: chatProfileData) =>
      storeProfile.conversation === (currentTab.value === 'chat' ? true : false)
  )
);

const siteConfigStore = useSiteConfigStore();
const image =
  siteConfigStore.siteSettings?.chatlogo?.src ?? siteConfigStore.siteSettings?.logo.src ?? '';
const name = siteConfigStore.siteSettings?.name ?? '';
const emptyProfile = getEmptyProfile(name, image);
const paramID = getSingleRouteParam(route.params, 'id');

const { width, height } = useWindowSize();
const pageHeight = ref<number>(height.value);
const isMobileThreshold = 768;
const isMobile = computed<boolean>(() => width.value < isMobileThreshold);

const noScrollOffset = 2; //the offset is to keep the page non-scrollable in px

watch(height, () => {
  getMaxHeight();
});

function getMaxHeight() {
  if (!document) return 0;
  setTimeout(() => {
    const headerHeight = document.getElementById('pageHeader')?.scrollHeight ?? 0;
    pageHeight.value = height.value - headerHeight - noScrollOffset;
  });
}

onMounted(() => {
  getMaxHeight();
  currentTab.value = pageProfiles.value.length > 0 ? 'chat' : 'mail';
});

async function selectFirstUser() {
  if (selectedChatUser.value) {
    return currentChatUser.value;
  }
  if (!paramID) {
    selectedChatUser.value = true;
    return emptyProfile;
  }
  const profile = chatStore.getProfileByID(paramID);
  if (profile === undefined) {
    const newProfile = await chatStore.newProfile(paramID);
    selectedChatUser.value = true;
    currentTab.value = 'mail';
    return newProfile;
  }
  currentTab.value = profile.conversation ? 'chat' : 'mail';
  selectedChatUser.value = true;
  await chatStore.readChat(paramID);
  return profile;
}

const selectedChatUser = ref<boolean>(false);
const tmp: chatProfileData = (await selectFirstUser()) ?? emptyProfile;
const currentChatUser = ref<chatProfileData>(tmp);

watch(ids.value, async () => {
  if (currentChatUser.value === undefined) {
    currentChatUser.value = (await selectFirstUser()) ?? emptyProfile;
  }
});

function pressedSend() {
  currentTab.value = 'chat';
}

function timeAvailable(user: chatProfileData) {
  if (user.messages?.at(-1) != undefined && user.messages.at(-1)?.createdAt != undefined)
    return true;
  if (user.createdAt != undefined || user.createdAt != null) return true;
  return false;
}

const mailStore = useMailStore();

const currentMail = ref<MessageDocument>();
const isSelected = paramID ? ref<boolean>(true) : ref<boolean>(false);

currentMail.value = mailStore.mailData[0];

function backFromChat() {
  isSelected.value = false;
  currentTab.value = 'chat';
}
</script>

<template>
  <div v-if="isSelected && isMobile">
    <div class="flex h-screen w-full flex-col md:hidden">
      <ChatBox
        v-if="currentTab === 'chat' && currentChatUser"
        :data="{
          user: currentChatUser,
          shouldScrollDownOnMessage: true,
          mobile: true,
          tab: currentTab,
          noScrollOffset: noScrollOffset
        }"
        :test-id="props.testId + '/ChatBox'"
        class="inherit bottom-0 right-0 flex"
        @clicked-back="backFromChat()"
        @press-send="pressedSend()"
      />
    </div>
  </div>
  <div v-else class="container overflow-y-hidden">
    <div class="">
      <div class="mx-auto flex w-full justify-center" :style="`height: ${pageHeight}px;`">
        <div class="flex size-full justify-center border-b border-l border-[#868686] md:w-[35%]">
          <div class="flex w-full flex-col border-r border-[#868686]">
            <div class="h-12 w-full border-b border-[#868686]">
              <button
                class="bg-background h-full w-1/2"
                :class="{
                  'bg-background text-header-text': currentTab != 'chat',
                  'bg-modal-backdrop text-primary border-b-2 border-white': currentTab === 'chat'
                }"
                :data-testid="props.testId + '/ChatBoxButton'"
                @click="currentTab = 'chat'"
              >
                <Icon name="ph:chats-circle" />
                Chats
              </button>
              <button
                class="h-full w-1/2"
                :class="{
                  'bg-background text-header-text': currentTab != 'mail',
                  'bg-modal-backdrop text-primary border-b-2 border-white': currentTab === 'mail'
                }"
                :data-testid="props.testId + '/MailBoxButton'"
                @click="currentTab = 'mail'"
              >
                <Icon name="fluent:mail-inbox-16-regular" />
                Mailbox
              </button>
            </div>

            <ul v-if="pageProfiles" class="hidden-scrollbar flex flex-col">
              <li
                v-for="(user, index) in pageProfiles"
                :key="index"
                :class="{
                  'bg-modal-backdrop/70':
                    currentChatUser?.card.profile_id === user.card.profile_id && !isMobile
                }"
                class="my-auto border-b border-[#868686] py-4 hover:cursor-pointer"
                :data-testid="props.testId + '/SelectChat|MailButton' + index"
                @click="
                  {
                    isSelected = true;
                    currentChatUser = user;
                  }
                "
              >
                <NuxtLink :to="`/message/${user.card.profile_id}`">
                  <div
                    class="grid grid-cols-[3rem_1fr_1rem_1rem] items-center justify-start gap-2 pl-4"
                  >
                    <div>
                      <Icon
                        :name="
                          randomBool()
                            ? 'fluent-emoji-flat:green-circle'
                            : 'fluent-emoji-flat:red-circle'
                        "
                        size="10"
                        class="relative left-10 top-[3.25rem] z-10"
                      />
                      <img
                        class="size-12 rounded-full object-cover drop-shadow-xl"
                        :src="user.card.image.url"
                        :alt="user.card.name"
                      />
                    </div>

                    <div class="relative top-4 w-full overflow-hidden">
                      <h3 class="self-start font-medium leading-none">
                        {{ user.card.name }}
                      </h3>
                      <p
                        v-if="timeAvailable(user)"
                        class="text-header-text/60 overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {{ unixToFromNowDate(user.messages?.at(-1)?.createdAt ?? user.createdAt) }}
                      </p>
                    </div>

                    <div v-if="user" class="relative top-4 flex w-4 flex-col">
                      <div
                        v-if="
                          user.messages &&
                          !isChatRead(user.messages) &&
                          user.messages?.at(-1)?.pokeMessageType != 'normal'
                        "
                        class="bg-primary size-4 rounded-full"
                      ></div>
                    </div>
                  </div>
                  <div class="ml-4 mt-2 flex">
                    <div
                      v-if="
                        user.messages?.at(-1)?.pokeMessageType === 'flirt' ||
                        user.messages?.at(-1)?.pokeMessageType === 'normal'
                      "
                      class="flex"
                    >
                      <p class="text-header-text/60 mr-1 mt-auto text-sm">{{ 'You:' }}</p>
                      <img
                        class="size-auto max-h-7 max-w-7"
                        :src="getFlirtDataFromText(user.messages?.at(-1)?.content ?? '').emoji"
                        alt=""
                      />
                    </div>
                    <p
                      class="text-header-text/60 ml-1 mt-auto overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                    >
                      {{ user.messages?.at(-1)?.content }}
                    </p>
                    <Icon
                      v-if="user.messages?.at(-1) && user.messages?.at(-1)?.attachment"
                      name="ic:round-attachment"
                      class="text-primary ml-1"
                    />
                  </div>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>

        <div class="hidden h-full w-[65%] md:flex">
          <ChatBox
            :data="{
              user: currentChatUser,
              shouldScrollDownOnMessage: true,
              mobile: true,
              tab: currentTab,
              noScrollOffset: noScrollOffset
            }"
            class="flex"
            :test-id="props.testId + '/ChatBox'"
            @press-send="pressedSend()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

