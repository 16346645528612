<script setup lang="ts">
import { navigateTo } from '#app';
import { ref } from 'vue';
import type { ZodFormattedError } from 'zod';
import { createIdentityFetchClient } from '../../../composables/apiClients';
import { useAuthStore, useModalStore, useSiteConfigStore } from '../../../stores';
import {
  ResetPasswordSchema,
  type ResetPassword,
  type HorizonComponentProps
} from '../../../types';
import FormErrorRenderer from '../../Forms/FormErrorRenderer.vue';

const props = defineProps<HorizonComponentProps>();
const authStore = useAuthStore();
const modalStore = useModalStore();
const siteConfigStore = useSiteConfigStore();
const identityApiClient = createIdentityFetchClient();

const formInfo = ref<ResetPassword>({
  password: '',
  confirmPassword: ''
});
const formErrors = ref<ZodFormattedError<ResetPassword> | undefined>();
const fetchStatus = ref<'idle' | 'sending' | 'success' | 'error'>('idle');

async function resetPassword() {
  formErrors.value = undefined;
  fetchStatus.value = 'sending';

  const parsedForm = ResetPasswordSchema.safeParse(formInfo.value);
  if (!parsedForm.success) {
    formErrors.value = parsedForm.error.format();
    fetchStatus.value = 'idle';
    return;
  }

  const token = authStore.resetPasswordToken;
  if (!token) return;

  const { data, error } = await identityApiClient('/identity/password-reset', {
    method: 'POST',
    body: {
      new_password: parsedForm.data.password,
      token
    }
  });

  if (data && !error) {
    await login(data.token);
  }

  fetchStatus.value = !error ? 'success' : 'error';
}

async function login(ssoToken: string) {
  const { errorCode } = await authStore.loginWithCustomToken(ssoToken);

  if (errorCode) return;

  modalStore.clearModal();

  const authorizedRedirectRoute = siteConfigStore.siteSettings?.authorizedRedirectRoute ?? '/';

  await navigateTo(authorizedRedirectRoute);
}
</script>

<template>
  <div class="bg-background relative w-11/12 max-w-lg overflow-y-auto rounded-2xl border-0 p-6">
    <Icon
      name="ic:sharp-close"
      size="2em"
      class="text-text hover:text-primary absolute right-2 top-2 cursor-pointer rounded-full"
      :data-testid="props.testId + '/CloseButton'"
      @click="modalStore.clearModal()"
    />

    <div class="mb-4 flex flex-col">
      <h2 class="text-text mx-auto pb-4 text-lg font-bold">Reset Password</h2>
    </div>

    <div
      v-if="fetchStatus === 'success'"
      class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm text-white"
    >
      Password has been reset!
    </div>
    <div
      v-if="fetchStatus === 'error'"
      class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
    >
      Failed to reset password, please try again later..
    </div>

    <form v-if="fetchStatus !== 'success'" @submit.prevent="resetPassword()">
      <div class="mb-3 flex flex-col gap-3">
        <input
          id="password"
          v-model="formInfo.password"
          type="password"
          name="password"
          placeholder="Enter new password"
          class="control text-black"
          :class="{ '!border-danger': !!formErrors?.password }"
          :data-testid="props.testId + '/PasswordInput'"
        />
        <FormErrorRenderer :zod-errors="formErrors" property="password" />

        <input
          id="confirmpassword"
          v-model="formInfo.confirmPassword"
          type="password"
          name="confirmpassword"
          placeholder="confirm new password"
          class="control text-black"
          :class="{ '!border-danger': !!formErrors?.confirmPassword }"
          :data-testid="props.testId + '/ConfirmPasswordInput'"
        />
        <FormErrorRenderer :zod-errors="formErrors" property="confirmPassword" />
      </div>

      <ButtonSimple
        class="shadow-buttonShadow w-full uppercase tracking-wide"
        :data="{
          label: 'Send',
          isLarge: true,
          isSaving: fetchStatus === 'sending'
        }"
        type="submit"
        :test-id="props.testId"
      />
    </form>
  </div>
</template>

<style scoped lang="postcss">
.control {
  @apply text-header-text bg-header-bg border-header-text/20 h-10 w-full rounded-md border px-2 py-1 text-sm;
}
</style>

