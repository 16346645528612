<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useSiteConfigStore } from '../../../stores/siteConfig.store';
import type { HorizonComponentProps } from '../../../types';

const props = defineProps<HorizonComponentProps>();
const siteConfigStore = useSiteConfigStore();
const pageName = String(useRoute().name);
const visitorPage = siteConfigStore.pages?.find((page) => page.key === 'visitors');
const membersPage = siteConfigStore.pages?.find((page) => page.key === 'members');
</script>

<template>
  <div v-if="pageName === visitorPage?.name" class="mt-16 flex flex-col place-items-center">
    <div class="text-header-text/60 text-xs">You haven’t had any visitors yet</div>
    <div class="text-header-text mt-3 text-2xl">Use Find Match to attract more visitors</div>
    <div class="text-header-text mt-2">
      Start matching on some profiles and we’re sure your going to get some visits soon!
    </div>
    <div class="text-header-text mt-4 text-sm">
      <NuxtLink
        :to="'/cards'"
        class="bg-primary flex h-8 w-36 flex-row place-content-center place-items-center gap-1 rounded-lg py-4"
        :data-testid="props.testId + '/ToMatchgGameButton'"
      >
        <span class="text-inherit"> Find Match </span>
        <Icon size="25" :name="'lucide:user-round-plus'" />
      </NuxtLink>
    </div>
  </div>
  <div
    v-if="pageName != membersPage?.name && pageName != visitorPage?.name"
    class="mt-16 flex flex-col place-items-center text-center"
  >
    <div class="text-header-text/60 text-xs">
      You haven’t added any profiles to your favorites yet
    </div>
    <div class="text-header-text mt-3 text-wrap text-2xl">
      Visit profiles and add them to your favorites
    </div>
    <div class="text-header-text mt-2">Start adding profiles to keep track of your favorites!</div>
    <div class="text-header-text mt-4 text-sm">
      <NuxtLink
        :to="'/members'"
        class="bg-primary flex h-8 w-40 flex-row place-content-center place-items-center gap-1 rounded-lg py-4"
        :data-testid="props.testId + '/ToMembersButton'"
      >
        <span class="text-inherit"> Members Area </span>
        <Icon size="25" :name="'ph:squares-four'" />
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>

