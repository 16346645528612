import { useSiteConfigStore } from '@horizon/library/stores';
import { storeToRefs } from 'pinia';
import type { HorizonNuxtErrorData, SiteConfig, TranslationVars } from '@horizon/library/types';
import configData from '~/static/config.json';
import { createError } from '#app';
import { getPageFromRoutePath } from '@horizon/library/utils';
import { buildHeadContent } from '~/utils/pageLoader';
import { I18N_COOKIE } from '~/constants/translation';

export default defineNuxtRouteMiddleware((to) => {
  if (!import.meta.server) return;
  useRequestEvent()?.node.req.middlewareSpan?.addEvent('site config');
  const { $translate } = useNuxtApp();

  const siteConfigStore = useSiteConfigStore();
  const { pages, siteSettings, theme, translations } = storeToRefs(siteConfigStore);

  siteConfigStore.setConfig(configData as SiteConfig);

  if (!pages.value || pages.value.length === 0) {
    const vars: TranslationVars = {
      invalidVar: 'site configuration'
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.invalidError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: theme.value,
        devTitle: 'horizon route middleware: no pages found',
        devMessage: 'siteConfigStore pages not found or empty'
      },
      fatal: true
    });
  }

  if (siteSettings.value && theme.value) {
    const page = getPageFromRoutePath(pages.value, to.path);
    const headContent = buildHeadContent(siteSettings.value, theme.value, page);
    useHead(headContent);
    useCookie(I18N_COOKIE).value = siteSettings.value.localeCode;
  }
});

