import { z } from 'zod';
import { Genders } from '../profile/gender';
import { dateToAge } from '../../utils';

const zodGenderOptions = z.enum(Genders);

const SimpleRegisterAccountSchemaBase = z.object({
  email: z
    .string({
      required_error: 'The username field is required.',
      invalid_type_error: 'The username field is required.'
    })
    .email({ message: 'Must be a valid email' }),
  password: z
    .string({
      required_error: 'The password field is required.',
      invalid_type_error: 'The password field is required.'
    })
    .min(6, { message: 'The password field must be 6 or more characters.' }),
  confirmPassword: z.string({
    required_error: 'The password verification field is required.',
    invalid_type_error: 'The password verification field is required.'
  })
});

export const SimpleRegisterAccountSchema = SimpleRegisterAccountSchemaBase.superRefine(
  ({ password, confirmPassword }, ctx) => {
    if (password !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'The password did not match',
        path: ['confirmPassword']
      });
    }
  }
);

const noRegionRegisterAccountSchemaBase = SimpleRegisterAccountSchemaBase.extend({
  username: z.string({
    required_error: 'The username field is required.',
    invalid_type_error: 'The username field is required.'
  }),
  password: z
    .string({
      required_error: 'The password field is required.',
      invalid_type_error: 'The password field is required.'
    })
    .min(6, { message: 'The password field must be 6 or more characters.' }),
  confirmPassword: z.string({
    required_error: 'The password verification field is required.',
    invalid_type_error: 'The password verification field is required.'
  }),
  gender: zodGenderOptions,
  lookingForGender: zodGenderOptions,
  email: z
    .string({
      required_error: 'The email field is required.',
      invalid_type_error: 'The email field is required.'
    })
    .email({ message: 'Must be a valid email' }),
  dateofbirth: z.string({
    required_error: 'The birthday field is required.',
    invalid_type_error: 'The birthday field is required.'
  }),
  city: z.string({
    required_error: 'City field is required',
    invalid_type_error: 'City field is required'
  })
});

export const NoRegionRegisterAccountSchema = noRegionRegisterAccountSchemaBase
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (password !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'The password did not match',
        path: ['confirmPassword']
      });
    }
  })
  .superRefine(({ dateofbirth }, ctx) => {
    const age = dateToAge(dateofbirth);
    if (age < 18) {
      ctx.addIssue({
        code: 'custom',
        message: 'You must be 18 years or older to use this site',
        path: ['dateofbirth']
      });
    }
  });

const RegisterAccountSchemaBase = noRegionRegisterAccountSchemaBase.extend({
  region: z.string({
    required_error: 'Region field is required',
    invalid_type_error: 'Region field is required'
  })
});
export const RegisterAccountSchema = RegisterAccountSchemaBase.superRefine(
  ({ password, confirmPassword }, ctx) => {
    if (password !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'The password did not match',
        path: ['confirmPassword']
      });
    }
  }
).superRefine(({ dateofbirth }, ctx) => {
  const age = dateToAge(dateofbirth);
  if (age < 18) {
    ctx.addIssue({
      code: 'custom',
      message: 'You must be 18 years or older to use this site',
      path: ['dateofbirth']
    });
  }
});

export const LoginAccountSchema = z.object({
  email: z
    .string({
      required_error: 'The email field is required.',
      invalid_type_error: 'The email field is required.'
    })
    .min(1, { message: 'The email field is required.' }),
  password: z
    .string({
      required_error: 'The password field is required.',
      invalid_type_error: 'The password field is required.'
    })
    .min(6, { message: 'The password field must be 6 or more characters.' })
});

export const ForgotPasswordSchema = z.object({
  email: z
    .string({
      required_error: 'The email field is required.',
      invalid_type_error: 'The email field is required.'
    })
    .email({ message: 'Must be a valid email' })
});

export const ResetPasswordSchema = z
  .object({
    password: z
      .string({
        required_error: 'The password field is required.',
        invalid_type_error: 'The password field is required.'
      })
      .min(6, { message: 'The password field must be 6 or more characters.' }),
    confirmPassword: z.string({
      required_error: 'The password verification field is required.',
      invalid_type_error: 'The password verification field is required.'
    })
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (password !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword']
      });
    }
  });

export type RegisterAccount = z.infer<typeof RegisterAccountSchema>;
export type NoRegionRegisterAccount = z.infer<typeof NoRegionRegisterAccountSchema>;
export type SimpleRegisterAccount = z.infer<typeof SimpleRegisterAccountSchema>;
export type LoginAccount = z.infer<typeof LoginAccountSchema>;
export type ForgotPassword = z.infer<typeof ForgotPasswordSchema>;
export type ResetPassword = z.infer<typeof ResetPasswordSchema>;

