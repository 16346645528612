import type { ExtractResponse } from "@btr/fetch-frog";
import type { paths } from "../schemas/collectconsent";
import { useSiteConfigStore } from "../stores";

export type CompanyVariables = ExtractResponse<
  paths,
  "/locale/{localeCode}/legal/{key}",
  "get"
>["variables"]["company"];

export type ContentVariables = {
  company: CompanyVariables;
  modifiedAt: string;
  launchYear: number;
  minCreditPrice?: number;
  maxCreditPrice?: number;
};

export function replaceVariables(
  content: string,
  variables: ContentVariables
): string {
  const currentYear = new Date().getFullYear().toString();
  const launchYear = variables.launchYear.toString();
  const modifiedDate = new Date(variables.modifiedAt).toLocaleDateString();

  const siteConfigStore = useSiteConfigStore();
  const domainName = siteConfigStore.siteSettings?.domainName;
  const cookieStatementPath = siteConfigStore.pages?.find(
    (x) => x.key === "cookieStatement"
  )?.path;
  const termsConditionsPath = siteConfigStore.pages?.find(
    (x) => x.key === "termsAndConditions"
  )?.path;
  const privacyStatementPath = siteConfigStore.pages?.find(
    (x) => x.key === "privacyStatement"
  )?.path;

  return content
    .replaceAll("{{companyCocInfo}}", variables.company.cocInfo || "-")
    .replaceAll("{{companyAddress3}}", variables.company.countryName ?? "-")
    .replaceAll("{{companyName}}", variables.company.name)
    .replaceAll(
      "{{companyAddress1}}",
      variables.company.streetAndHouseNumber ?? "-"
    )
    .replaceAll("{{companyVatInfo}}", variables.company.vatInfo || "-")
    .replaceAll("{{companyAddress2}}", variables.company.zipCodeAndCity ?? "-")
    .replaceAll("{{companyPhoneNumber}}", variables.company.telInfo || "-")

    .replaceAll("{{domainName}}", domainName ?? "-")
    .replaceAll("{{currentYear}}", currentYear)
    .replaceAll("{{modifiedDate}}", modifiedDate)
    .replaceAll("{{launchYear}}", launchYear)

    .replaceAll("{{cookieStatementPath}}", cookieStatementPath ?? "/")
    .replaceAll("{{privacyStatementPath}}", privacyStatementPath ?? "/")
    .replaceAll("{{termsConditionsPath}}", termsConditionsPath ?? "/")

    .replaceAll("{{minCreditPrice}}", variables.minCreditPrice ? variables.minCreditPrice.toString() : "-")
    .replaceAll("{{maxCreditPrice}}", variables.maxCreditPrice ? variables.maxCreditPrice.toString() : "-")

    .replaceAll(
      encodeURIComponent("{{cookieStatementPath}}"),
      cookieStatementPath ?? "/"
    )
    .replaceAll(
      encodeURIComponent("{{privacyStatementPath}}"),
      privacyStatementPath ?? "/"
    )
    .replaceAll(
      encodeURIComponent("{{termsConditionsPath}}"),
      termsConditionsPath ?? "/"
    );

  // TODO
  // .replaceAll('{{partnerUrlAppend}}', '')
}
