import { defineStore } from 'pinia';
import type { BaseDropdownItem } from '@harlem-next/core/types/types';
import { createProfilesFetchClient } from '../composables/apiClients';
import { ref } from 'vue';
import type { TagCategoriesMap } from '../types';
import { capitalize } from '../utils/text';
import { profileHeight } from '../utils/profileHeight';
import { useSiteConfigStore } from '../stores';

type DropdownTagOptions = {
  adult_level: BaseDropdownItem[];
  appearance: BaseDropdownItem[];
  attachment_adult_level: BaseDropdownItem[];
  build: BaseDropdownItem[];
  characteristics: BaseDropdownItem[];
  civil_status: BaseDropdownItem[];
  eye_color: BaseDropdownItem[];
  gender: BaseDropdownItem[];
  general: BaseDropdownItem[];
  hair_color: BaseDropdownItem[];
  housing_situation: BaseDropdownItem[];
  length: BaseDropdownItem[];
  passion: BaseDropdownItem[];
  profile_type: BaseDropdownItem[];
  sexual_orientation: BaseDropdownItem[];
  sexual_preference: BaseDropdownItem[];
  transportation: BaseDropdownItem[];
};

export const useTagStore = defineStore('tag', () => {
  const isUS = useSiteConfigStore().localeCode?.split('_')[1] === 'US';
  const profileFetchClient = createProfilesFetchClient();

  const tagCategories = ref<TagCategoriesMap | null>(null);
  const dropdownTagOptions = ref<DropdownTagOptions>({
    adult_level: [],
    appearance: [],
    attachment_adult_level: [],
    build: [],
    characteristics: [],
    civil_status: [],
    eye_color: [],
    gender: [],
    general: [],
    hair_color: [],
    housing_situation: [],
    length: [],
    passion: [],
    profile_type: [],
    sexual_orientation: [],
    sexual_preference: [],
    transportation: []
  });

  async function fetchTagCategories() {
    if (tagCategories.value) return;

    const fetchResult = await profileFetchClient('/tags', {
      method: 'GET'
    }).catch(() => {});

    if (!fetchResult || fetchResult.error) {
      return;
    }

    const transformedData: TagCategoriesMap = {};
    fetchResult.data.forEach((x) => {
      if (x && x.name) {
        transformedData[x.name] = x;
      }
    });

    tagCategories.value = transformedData;
    dropdownTagOptions.value = tagsToDropdownOptions(tagCategories.value);
  }

  function tagsToDropdownOptions(tagCategories: TagCategoriesMap) {
    const options = {} as DropdownTagOptions;

    Object.values(tagCategories).forEach((tagCategory) => {
      if (tagCategory.name === 'length') {
        const step1 = tagCategory.tags
          .sort(function (a, b) {
            return Number(a.name) - Number(b.name);
          })
          .map((tag) => ({
            _id: tag.id,
            label: profileHeight(tag.name, isUS),
            value: tag.id
          }));
        const seen = new Set<string>();
        options[tagCategory.name as keyof DropdownTagOptions] = step1.filter(function (tag) {
          if (seen.has(tag.label)) return false;
          seen.add(tag.label);
          return true;
        });
      } else {
        options[tagCategory.name as keyof DropdownTagOptions] = tagCategory.tags.map((tag) => ({
          _id: tag.id,
          label: capitalize(tag.name),
          value: tag.id
        }));
      }
    });

    return options;
  }

  return { tags: tagCategories, dropdownTagOptions, fetchTagCategories };
});

