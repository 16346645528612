import { defu } from "defu";
import type { SiteTheme, Page, SiteSettings } from "@horizon/library/types";
import type { MetaObject } from "nuxt/schema";
import { overrideStyles } from "@horizon/library/utils";

export function buildHeadContent(
  siteSettings: SiteSettings,
  theme: SiteTheme,
  page: Page | undefined
): MetaObject {
  const mergedTheme = defu(page?.overrides ?? {}, theme);
  // TODO: add merged site settings?

  const styles: string[] = [];
  if (siteSettings.fonts?.header)
    styles.push(`--header-font: ${siteSettings.fonts.header}`);
  if (siteSettings.fonts?.body)
    styles.push(`--body-font: ${siteSettings.fonts.body}`);

  return {
    title: `${siteSettings.name}${page?.name ? " | " + page.name : ""}`,
    meta: [{ name: "description", content: siteSettings.description }],
    link: [
      {
        rel: "icon",
        type: "image/png",
        href: siteSettings.favicon || "/favicon.png",
      },
      {
        rel: "preconnect",
        href: "https://fonts.googleapis.com",
      },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossorigin: "",
      },
      {
        rel: "stylesheet",
        href: `https://fonts.googleapis.com/css2?family=${
          siteSettings.fonts?.header ?? "Inter"
        }:wght@400;500;600;700&display=swap&family=${
          siteSettings.fonts?.body ?? "Inter"
        }:wght@400;500;600;700&display=swap`,
      },
    ],
    style: [
      `:root { ${overrideStyles(mergedTheme as SiteTheme)} ${styles.join(
        ";"
      )} }`,
    ],
  };
}
