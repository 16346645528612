<script setup lang="ts">
import type { ISourceOptions } from '@tsparticles/engine';

const particleOptions: ISourceOptions = {
  fullScreen: {
    zIndex: 1
  },
  emitters: {
    position: {
      x: 50,
      y: 100
    },
    rate: {
      quantity: 4,
      delay: 0.2
    }
  },
  particles: {
    move: {
      decay: 0.05,
      direction: 'top',
      enable: true,
      gravity: {
        enable: true,
        acceleration: 100,
        inverse: true
      },
      outModes: {
        top: 'none',
        default: 'split'
      },
      speed: {
        min: 75,
        max: 200
      }
    },
    number: {
      value: 0
    },
    color: {
      animation: {

      },
      value: '#E31B54'
    },
    opacity: {
      value: 0.5
    },
    rotate: {
      value: {
        min: 0,
        max: 360
      },
      direction: 'random',
      animation: {
        enable: true,
        speed: 30
      }
    },
    roll: {
      darken: {
        enable: true,
        value: 25
      },
      enlighten: {
        enable: true,
        value: 25
      },
      enable: true,
      speed: {
        min: 5,
        max: 15
      }
    },
    shape: {
      type: 'image',
      options: {
        image: [
          {
            src: 'https://api.iconify.design/fa/heart-o.svg',
            width: 64,
            height: 64,
            particles: {
              size: {
                value: 64
              },
            },
            replaceColor: true
          },
          {
            src: 'https://api.iconify.design/fa/heart.svg',
            width: 64,
            height: 64,
            particles: {
              size: {
                value: 64
              }
            },
            replaceColor: true
          }
        ]
      }
    }
  },
  responsive: [
    {
      maxWidth: 600,
      mode: 'canvas',
      options: {
        emitters: {
          position: {
            x: 50,
            y: 100
          },
          rate: {
            quantity: 2,
            delay: 0.4
          }
        },
        particles: {
          move: {
            speed: {
              min: 50,
              max: 100
            }
          },
        }
      },
    }
  ]
};
</script>

<template>
    <NuxtParticles id="tsparticles" class="z-50" :options="particleOptions"></NuxtParticles>
</template>

<style scoped>

</style>
