<script setup lang="ts">
import { storeToRefs, useNuxtApp } from '#imports';
import { useModalStore, useSiteConfigStore } from '../../stores';
import { EModal, type HorizonComponentProps } from '../../types';
import { computed } from 'vue';

type BlurProps = {
  isLoading: boolean;
};

const props = defineProps<HorizonComponentProps<BlurProps>>();
const loading = computed(() => props.data.isLoading);
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const { $translate } = useNuxtApp();

const modalStore = useModalStore();

function showLoginModal() {
  modalStore.showModalComponent(EModal.loginModalComponent);
}

function showRegisterModal() {
  modalStore.showModalComponent(EModal.registrationModalComponent, false);
}
</script>

<template>
  <div class="z-30 w-full" :class="{ 'backdrop-blur-sm': !loading }">
    <div class="flex h-[30rem] sm:h-[40rem]">
      <div
        class="bg-header-bg mx-auto my-auto flex h-[20rem] w-[35rem] flex-col place-items-center rounded-xl p-4 sm:p-8"
      >
        <p class="text-footer-text mt-1 text-sm">{{ $translate(translations?.preTitle) }}</p>
        <p class="text-header-text text-4xl">{{ $translate(translations?.title) }}</p>
        <p class="text-footer-text text-wrap pt-2 text-center text-lg leading-5">
          {{ $translate(translations?.description) }}
        </p>
        <div class="flex flex-col pb-2 pt-4 sm:pb-0">
          <button
            class="bg-btn-primary-bg hover:bg-btn-primary-hover-bg flex h-8 w-44 place-content-center rounded-lg"
            :data-testid="props.testId + '/SignupButton'"
            @click="showRegisterModal()"
          >
            <h1 class="relative top-1">{{ $translate(translations?.signupButton) }}</h1>
            <Icon name="ic:round-login" class="text-header-text ml-1" size="30" />
          </button>
          <p class="text-footer-text mx-auto mt-2 text-sm">
            {{ $translate(translations?.betweenButtonsText) }}
          </p>
          <a
            class="text-primary block text-center hover:underline"
            href="#"
            :data-testid="props.testId + '/LoginLink'"
            @click="showLoginModal()"
          >
            {{ $translate(translations?.loginButton) }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

