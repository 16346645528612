import { z } from "zod";

export const AuthUserSchema = z.object({
  uid: z.string(),
  email: z.string().email().optional(),
  emailVerified: z.boolean(),
  displayName: z.string().optional(),
  isAnonymous: z.boolean(),
  providerData: z.array(
    z.object({
      providerId: z.string(),
      uid: z.string(),
      displayName: z.string().nullable(),
      email: z.string().email(),
      phoneNumber: z.string().nullable(),
      photoURL: z.string().nullable(),
    })
  ),
  stsTokenManager: z.object({
    accessToken: z.string(),
  }),
  createdAt: z.number({ coerce: true }),
  lastLoginAt: z.number({ coerce: true }),
  phoneNumber: z.string().optional(),
  phoneURL: z.string().optional(),
});

export type AuthUser = z.infer<typeof AuthUserSchema>;
