<script setup lang="ts">
import { useSiteConfigStore, useMatchGameStore, useGlobalsStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { overrideStyles, getTestId } from '../../utils';
import { inject, type Component } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import type { HorizonComponentProps } from '../../types';

const props = defineProps<HorizonComponentProps>();
const globals = useGlobalsStore();
const ITEM_WIDTH = globals.constantGlobals.profileGrid.ITEM_WIDTH;

const components = inject<Record<string, Component>>('components');

const siteConfigStore = useSiteConfigStore();
const { profileFiltersComponent } = storeToRefs(siteConfigStore);

const mgStore = useMatchGameStore();
const { profilesAvailable } = storeToRefs(mgStore);

const pressButton = useDebounceFn(async (like: boolean) => {
  await mgStore.pressButton(like);
}, 250);
</script>

<template>
  <div class="container">
    <component
      :is="components[profileFiltersComponent.name]"
      v-if="profileFiltersComponent && components"
      :style="overrideStyles(profileFiltersComponent?.overrides)"
      :column-count="6"
      :item-width="ITEM_WIDTH"
      :home-page="false"
      :data-testid="props.testId + '/' + getTestId(profileFiltersComponent)"
      :test-id="props.testId + '/' + getTestId(profileFiltersComponent)"
      class="mt-4"
    />
  </div>
  <div v-if="components" class="mx-auto max-w-xl">
    <div v-if="!profilesAvailable" class="pb-10">
      <h1 class="text-header-text text-wrap text-center font-bold">
        No Results - Displaying Popular Profiles.
      </h1>
    </div>

    <CardSwiperModernfling :test-id="props.testId + '/CardsWiper'" :data @click="pressButton">
    </CardSwiperModernfling>
  </div>
</template>

