import routerOptions0 from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/pangaea/apps/horizon/horizon-ui-site/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}