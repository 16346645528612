import type { HorizonComponent } from '../types';
export function getTestId(component: HorizonComponent) {
  if (component.testId) return component.testId;
  if (component.path.includes('Support')) {
    const split = component.path.split('/');
    return split[0] + split[2];
  }
  if (component.path.includes('Modals')) {
    const split = component.path.split('/');
    return split[1] + 'Modal';
  }
  return component.path.split('/')[0];
}

