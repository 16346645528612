<script lang="ts">
export type ScrollState<T> = {
  page: number;
  firstFetchEmpty: boolean;
  items: T[];
  scrollPos: number;
};
</script>

<script setup lang="ts" generic="T">
import { useIntersectionObserver } from '@vueuse/core';
import { onBeforeUnmount, onMounted, onUpdated, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { storageKeys } from '../../utils';
import { type ProfileCard } from '../../types';
import { RecycleScroller, type RecycleScrollerInstance } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
/**
 * 1. Props and emits
 */

type Props = {
  itemWidth: number;
  itemHeight: number;
  page: number;
  firstFetchEmpty: boolean;
  items: T[];
  keyField?: string;
  colCount: number;
  maxColCount?: number;
  minColCount?: number;
  isLoading?: boolean;
};
const props = defineProps<Props>();
const { itemWidth, itemHeight, page, firstFetchEmpty, items, keyField, colCount, isLoading } =
  toRefs(props);

const emit = defineEmits<{
  (e: 'fetch'): void;
  (e: 'loadState', state: ScrollState<T>): void;
}>();

/**
 * 3. Infinite scroll
 */

const scrollerElement = ref<RecycleScrollerInstance>();
const gridEnd = ref();
useIntersectionObserver(gridEnd, async ([{ isIntersecting }]) => {
  if (isIntersecting) {
    emit('fetch');
  }
});

/**
 * 4. Storing and loading state
 */
const route = useRoute();
const STATE_STORAGE_KEY = String(route.name) + storageKeys.profileGrid.scrollState;

function storeState() {
  if (isLoading.value) return;
  try {
    const filteredItems = (items.value as ProfileCard[]).filter(
      (x) => !(items.value as ProfileCard[]).find((y) => y.profile_id.split('#')[1] !== undefined)
    );
    const scrollState: ScrollState<ProfileCard> = {
      page: page.value,
      firstFetchEmpty: firstFetchEmpty.value,
      items: filteredItems,
      scrollPos: 0
    };

    const lastScrollPos = scrollerElement.value?.getScroll()?.start;
    if (lastScrollPos !== undefined) {
      scrollState.scrollPos = lastScrollPos;
    }

    sessionStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(scrollState));
  } catch {
    //
  }
}

async function loadState() {
  try {
    const stateString = sessionStorage.getItem(STATE_STORAGE_KEY);
    if (!stateString) return;

    const cachedState = JSON.parse(stateString) as ScrollState<T>;
    emit('loadState', cachedState);

    if (scrollerElement.value && cachedState.scrollPos !== 0) {
      // Timeout to let the scroller calculate height first, otherwise scrolling won't work
      setTimeout(() => {
        scrollerElement.value?.scrollToPosition(cachedState.scrollPos);
      }, 1);
    }
  } catch {
    //
  }
}

onMounted(() => {
  window.addEventListener('beforeunload', storeState);
  loadState();
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', storeState);
  // storeState();
});

onUpdated(() => {
  storeState();
});
</script>

<template>
  <div id="scrollerWrapper" ref="scrollerWrapper" class="scroller-wrapper mb-16 md:mb-0">
    <RecycleScroller
      ref="scrollerElement"
      v-slot="{ item }"
      class="scroller"
      :key-field="keyField as keyof T"
      :items="items"
      :item-size="itemHeight"
      :buffer="600"
      :page-mode="true"
      :item-secondary-size="itemWidth"
      :grid-items="colCount"
      :style="`max-width: ${colCount * itemWidth}px;`"
    >
      <slot :item="item" :index="items.indexOf(item)"></slot>
    </RecycleScroller>
  </div>

  <div ref="gridEnd" :key="items.length"></div>
</template>

<style scoped>
.scroller {
  height: 100%;
  margin: 0 auto;
}
</style>

