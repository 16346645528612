import DOMPurify from 'isomorphic-dompurify';

export function sanitizeContent(text: string | undefined | null): string {
    if (!text) return '';

    return DOMPurify.sanitize(text, {
        FORBID_TAGS: ['form', 'script', 'style']
    });
}

