<script setup lang="ts">
import { createError } from '#app';
import { useDebounceFn } from '@vueuse/core';
import { storeToRefs, useNuxtApp } from '#imports';
import { reactive, ref, watchEffect, onMounted } from 'vue';
import {
  createProfilesFetchClient,
  createUseProfilesFetchClient
} from '../../composables/apiClients';
import { useAuthStore, useSiteConfigStore } from '../../stores';
import { useAttributesStore } from '../../stores/attributes.store';
import { useGeoStore } from '../../stores/geo.store';
import { useTagStore } from '../../stores/tag.store';
import type {
  HorizonNuxtErrorData,
  UpdateAccountBody,
  HorizonComponentProps,
  TranslationVars
} from '../../types';
import type { CityDropdownItem, GeoWizardCity } from '../../types/geo/geo';
import { inputDateFormat } from '../../utils/time';

const { $translate } = useNuxtApp();

const props = defineProps<HorizonComponentProps>();

const pageState = ref<
  'loading' | 'load-failed' | 'load-success' | 'updating' | 'update-failed' | 'update-succeeded'
>('loading');

const useProfileFetchClient = createUseProfilesFetchClient();
const profileFetchClient = createProfilesFetchClient();

const tagStore = useTagStore();
const { dropdownTagOptions } = storeToRefs(tagStore);

const attributeStore = useAttributesStore();
const { attributes: allAttributes } = storeToRefs(attributeStore);

const geoStore = useGeoStore();
const { geoRegionOptions, geoCityOptions } = storeToRefs(geoStore);

const siteConfigStore = useSiteConfigStore();
const { localeCode, translations } = storeToRefs(siteConfigStore);

const profileFetchPromise = useProfileFetchClient('/profile-user/me', {});

const geoCity = ref<GeoWizardCity | null>();
const cityInput = ref<string>('');
const showCitiesDropdown = ref<boolean>(false);

await Promise.allSettled([
  profileFetchPromise,
  attributeStore.fetchAttributes(),
  tagStore.fetchTagCategories(),
  geoStore.fetchGeoRegions()
]);

const { data: profilesData, error: profilesError } = profileFetchPromise;

if (!profilesData.value || profilesError.value || !allAttributes.value) {
  pageState.value = 'load-failed';
  const vars: TranslationVars = {
    whatVar: 'account details'
  };
  throw createError<HorizonNuxtErrorData>({
    data: {
      title: $translate(translations?.value?.loadError, vars),
      subtitle: $translate(translations?.value?.tryAgain),
      devTitle: 'Call to profile-s2d-api GET /profile-user/me failed',
      devMessage: profilesError.value?.message
    },
    stack: profilesError.value?.stack,
    fatal: true
  });
}

type GeoAttributes =
  | 'geo_region_1'
  | 'geo_region_2'
  | 'geo_region_3'
  | 'geo_region_4'
  | 'geo_latitude'
  | 'geo_longitude'
  | 'geo_locality'
  | 'geo_timezone';
type FormAttributes = Record<
  'about_me' | 'date_of_birth' | 'city' | GeoAttributes,
  { attributeId: string; value: string }
>;

const formAttributes = reactive<FormAttributes>({
  about_me: {
    attributeId: '',
    value: ''
  },
  date_of_birth: {
    attributeId: '',
    value: ''
  },
  city: {
    attributeId: '',
    value: ''
  },
  geo_region_1: {
    attributeId: '',
    value: ''
  },
  geo_region_2: {
    attributeId: '',
    value: ''
  },
  geo_region_3: {
    attributeId: '',
    value: ''
  },
  geo_region_4: {
    attributeId: '',
    value: ''
  },
  geo_latitude: {
    attributeId: '',
    value: ''
  },
  geo_longitude: {
    attributeId: '',
    value: ''
  },
  geo_locality: {
    attributeId: '',
    value: ''
  },
  geo_timezone: {
    attributeId: '',
    value: ''
  }
});

type FormTags = {
  gender: string;
  length: string;
  build: string;
  civil_status: string;
  hair_color: string;
  eye_color: string;
  [key: string]: string; // Potentialy more tags that are not shown in the UI, such as snowflake
};

const formTags = reactive<FormTags>({
  gender: '',
  length: '',
  build: '',
  civil_status: '',
  hair_color: '',
  eye_color: ''
  // ... Potentialy more tags that are not shown in the UI, such as snowflake
});

function loadAttributeIds() {
  if (!allAttributes.value) return;

  formAttributes.about_me.attributeId = allAttributes.value.about_me;
  formAttributes.date_of_birth.attributeId = allAttributes.value.date_of_birth;
  formAttributes.city.attributeId = allAttributes.value.city;
  formAttributes.geo_region_1.attributeId = allAttributes.value.geo_region_1;
  formAttributes.geo_region_2.attributeId = allAttributes.value.geo_region_2;
  formAttributes.geo_region_3.attributeId = allAttributes.value.geo_region_3;
  formAttributes.geo_region_4.attributeId = allAttributes.value.geo_region_4;
  formAttributes.geo_latitude.attributeId = allAttributes.value.geo_latitude;
  formAttributes.geo_longitude.attributeId = allAttributes.value.geo_longitude;
  formAttributes.geo_locality.attributeId = allAttributes.value.geo_locality;
  formAttributes.geo_timezone.attributeId = allAttributes.value.geo_timezone;
}
loadAttributeIds();

watchEffect(async () => {
  if (!profilesData.value) return;

  profilesData.value?.attributes?.forEach((x) => {
    const attribute = formAttributes[x.name as keyof FormAttributes];
    if (attribute) {
      if (x.name === 'date_of_birth') {
        const dateOnly = x.value?.split(' ')[0];
        attribute.value = inputDateFormat(dateOnly);
      } else {
        attribute.value = x.value;
      }
    }
  });

  const tagCategories = profilesData.value?.tags ?? [];
  tagCategories.forEach((tagCategory) => {
    // Multi tag category: add every possible child tag, such as snowflake to the formTags
    if (tagCategory.multi) {
      tagCategory.tags?.forEach((tag) => {
        const { id, name } = tag;
        if (!id || !name) return;
        formTags[name] = id;
      });
      return;
    }

    // Single tag category: the current category value is the first available tag's id
    const tag = tagCategory.tags?.at(0);
    if (!tag || !tag.id || !tagCategory.name) return;
    formTags[tagCategory.name] = tag.id;
  });

  pageState.value = 'load-success';
});

function attributeMapFunc(x: { attributeId: string; value: string }) {
  if (x.attributeId === formAttributes.date_of_birth.attributeId) {
    return {
      id: x.attributeId,
      value: x.value
    };
  }

  return {
    id: x.attributeId,
    value: x.value
  };
}

async function updateAccount() {
  pageState.value = 'updating';

  const attributes = Object.values(formAttributes).map(attributeMapFunc);

  const tags = Object.values(formTags)
    .map((x) => x)
    .filter((x) => !!x);

  const body: UpdateAccountBody = {
    attributes,
    tags
  };

  const profileId = useAuthStore().authUser?.uid;
  if (!profileId) return;

  const { error } = await profileFetchClient('/profile-user/{profileId}/update', {
    method: 'PUT',
    path: {
      profileId
    },
    body
  });

  pageState.value = !error ? 'update-succeeded' : 'update-failed';
}

function updateGeoAttributes(place: GeoWizardCity | null) {
  if (!place) return;

  formAttributes.city.value = place.city;
  formAttributes.geo_latitude.value = place.latitude;
  formAttributes.geo_longitude.value = place.longitude;
  formAttributes.geo_timezone.value = place.timezone;
  formAttributes.geo_region_1.value = place.region_1;
  formAttributes.geo_region_2.value = place.region_2;
  formAttributes.geo_region_3.value = place.region_3;
  formAttributes.geo_region_4.value = place.region_4;
}

async function updateCityOptions() {
  geoCity.value = null;
  if (cityInput.value.length > 2) {
    await geoStore.fetchCitiesQuery(cityInput.value);
    showCitiesDropdown.value = true;
  } else {
    showCitiesDropdown.value = false;
  }
}

const debounceUpdateCityOptions = useDebounceFn(async () => {
  await updateCityOptions();
}, 250);

function setCity(cityOption: CityDropdownItem) {
  if (cityOption.value === null) return;
  cityInput.value = `${cityOption.label} (${cityOption.value?.state})`;
  showCitiesDropdown.value = false;
  geoCity.value = cityOption.value;
  updateGeoAttributes(cityOption.value);
}

onMounted(() => {
  cityInput.value = formAttributes.city.value;
});
</script>

<template>
  <div class="container mx-auto mb-12 tracking-wider">
    <div
      v-if="pageState === 'update-failed'"
      class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm"
    >
      <strong class="text-white">Failed to save changes...</strong>
    </div>
    <div
      v-if="pageState === 'update-succeeded'"
      class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm"
    >
      <strong class="text-white">Changes saved!</strong>
    </div>

    <section class="mx-4 rounded-xl py-2">
      <h2 class="text-text text-base font-semibold uppercase leading-loose">General information</h2>
      <p class="text-footer-text text-sm leading-loose">
        Update your photo and personal details here.
      </p>

      <form class="gap-4 md:grid md:grid-cols-2" @submit.prevent="">
        <div>
          <label for="gender">Gender</label>
          <select
            id="gender"
            v-model="formTags.gender"
            name="gender"
            class="control-no-color"
            :data-testid="props.testId + '/GenderSelect'"
          >
            <option v-if="!formTags.gender" value="">-- Please select --</option>
            <option
              v-for="option in dropdownTagOptions.gender"
              :key="option._id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'Option'"
            >
              {{ option.label }}
            </option>
          </select>

          <label for="dateOfBirth">Date of Birth</label>
          <input
            id="dateOfBirth"
            v-model="formAttributes.date_of_birth.value"
            type="date"
            name="dateOfBirth"
            class="control-no-color"
          />

          <label for="city">City</label>
          <input
            id="city"
            v-model="cityInput"
            type="text"
            placeholder="Enter a city"
            class="control-no-color"
            :class="{
              'text-primary': geoCity === null,
              'text-header-text': geoCity != null
            }"
            name="city"
            @input="debounceUpdateCityOptions"
          />
          <ul
            v-if="showCitiesDropdown"
            class="bg-modal-bg text-modal-text/60 max-h-modal-sm absolute w-3/5 overflow-y-auto border border-[#2a3b65]"
          >
            <li
              v-for="city of geoCityOptions"
              :key="city._id"
              class="hover:text-modal-text hover:bg-primary py-2 pl-2"
              @click="setCity(city)"
            >
              {{ `${city.label} (${city.value?.state})` }}
            </li>
          </ul>

          <label for="length">Height</label>
          <select
            id="length"
            v-model="formTags.length"
            name="length"
            class="control-no-color"
            :data-testid="props.testId + '/HeightSelect'"
          >
            <option v-if="!formTags.length" value="">-- Please select --</option>
            <option
              v-for="option in dropdownTagOptions.length"
              :key="option._id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'Option'"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <div>
          <label for="bodyType">Body type</label>
          <select
            id="bodyType"
            v-model="formTags.build"
            name="bodyType"
            class="control-no-color"
            :data-testid="props.testId + '/BodySelect'"
          >
            <option v-if="!formTags.build" value="">-- Please select --</option>
            <option
              v-for="option in dropdownTagOptions.build"
              :key="option._id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'Option'"
            >
              {{ option.label }}
            </option>
          </select>

          <label for="civilStatus">Marital status</label>
          <select
            id="civilStatus"
            v-model="formTags.civil_status"
            name="civilStatus"
            class="control-no-color"
            :data-testid="props.testId + '/MaritalSelect'"
          >
            <option v-if="!formTags.civil_status" value="">-- Please select --</option>
            <option
              v-for="option in dropdownTagOptions.civil_status"
              :key="option._id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'Option'"
            >
              {{ option.label }}
            </option>
          </select>

          <label for="hairColor">Hair color</label>
          <select
            id="hairColor"
            v-model="formTags.hair_color"
            name="hairColor"
            class="control-no-color"
            :data-testid="props.testId + '/HairColorSelect'"
          >
            <option v-if="!formTags.hair_color" value="">-- Please select --</option>
            <option
              v-for="option in dropdownTagOptions.hair_color"
              :key="option._id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'Option'"
            >
              {{ option.label }}
            </option>
          </select>

          <label for="eyeColor">Eye color</label>
          <select
            id="eyeColor"
            v-model="formTags.eye_color"
            name="eyeColor"
            class="control-no-color"
            :data-testid="props.testId + '/EyeColorSelect'"
          >
            <option v-if="!formTags.eye_color" value="">-- Please select --</option>
            <option
              v-for="option in dropdownTagOptions.eye_color"
              :key="option._id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'Option'"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </form>
      <div class="mt-2 grid h-8 w-full gap-4 md:grid-cols-2">
        <ButtonSimple
          class="w-full font-bold md:col-start-1 md:w-1/3"
          :data="{ label: 'Save Changes', isDisabled: pageState === 'updating', isSmall: true }"
          :test-id="props.testId"
          @click="updateAccount()"
        />
      </div>
    </section>

    <section class="mx-4 mb-8 rounded-xl py-2">
      <h2 class="text-text text-sm font-semibold leading-loose">About me</h2>

      <textarea
        v-model="formAttributes.about_me.value"
        class="bg-background border-header-text/20 h-48 w-full resize-none rounded border-2 p-4 placeholder:pl-2 placeholder:pt-2 placeholder:text-sm"
        placeholder="Write a couple of lines about yourself"
        :data-testid="props.testId + '/AboutMeTextarea'"
      ></textarea>
      <p class="mb-2 text-sm">
        <span class="text-primary">{{ formAttributes.about_me.value?.length ?? 0 }}</span>
        / 700 karakters
      </p>

      <div class="grid gap-4 md:grid-cols-2">
        <ButtonSimple
          class="w-full p-2 font-bold md:col-start-1 md:w-1/3"
          :data="{ label: 'Save Changes', isDisabled: pageState === 'updating', isSmall: true }"
          :test-id="props.testId"
          @click="updateAccount()"
        />
      </div>
    </section>
  </div>
</template>

<style scoped lang="postcss">
textarea,
input,
select {
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;

  &:focus {
    border-color: #cbd5e0;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input,
select {
  height: 2.5rem;
  margin-bottom: 0.75rem;
}

option {
  background-color: #fff;
  color: #4a5568;
}

label {
  @apply text-text mt-2 block font-medium;
}

.control-no-color {
  @apply bg-background border-header-text/20 h-10 w-full rounded-md border px-2 py-1 text-sm;
}
</style>

