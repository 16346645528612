<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore, useChatStore, useModalStore, useSiteConfigStore } from '../../stores';
import { type HorizonComponentProps, EModal } from '../../types';
import type { Props } from './ModernFling.schema';
import { storeToRefs, useNuxtApp } from '#imports';

interface NavItem {
  label: string;
  mobileLabel?: string;
  icon: {
    name: string;
    flip?: 'horizontal' | 'vertical';
  };
  to: string;
  count?: number;
  class?: string;
}

const route = useRoute();
const homepage = computed(() => {
  return route.name === 'Home';
});
const props = defineProps<HorizonComponentProps<Props>>();
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const { $translate } = useNuxtApp();
const authStore = useAuthStore();

const modalStore = useModalStore();
const chatStore = useChatStore();
const sourceLogo = props.data?.logo ?? siteConfigStore.siteSettings?.logo;
const backUpLogo = { src: '', alt: 'logo' };
const logo = sourceLogo ?? backUpLogo;
const verifyCredits = ref<number>(0);

function defaultClass(page: string, extra: string = '') {
  if (extra)
    return route.path.startsWith(page) ? 'text-primary hover:text-link-hover' + extra : extra;
  return route.path.startsWith(page) ? 'text-primary hover:text-link-hover' : '';
}

const accountClass =
  route.path.startsWith('/account') ||
  route.path.startsWith('/my-pictures') ||
  route.path.startsWith('/settings')
    ? 'text-primary'
    : '';

const chatClass =
  route.path.startsWith('/chat') || route.path.startsWith('/message') ? 'text-primary' : '';

const creditsButton = ref<NavItem>({
  label: $translate(translations.value?.creditsPage),
  icon: { name: 'la:coins' },
  to: '/credits',
  count: chatStore.creditCount ?? 0,
  class: defaultClass('/credits')
});

interface NavItems {
  [page: string]: NavItem;
}

const navItems = ref<NavItems>({
  leden: {
    label: $translate(translations.value?.membersPage),
    mobileLabel: $translate(translations.value?.membersPageMobile),
    icon: { name: 'ic:baseline-search' },
    to: '/members',
    class: defaultClass('/members')
  },
  matches: {
    label: $translate(translations.value?.matchesPage),
    mobileLabel: $translate(translations.value?.matchesPageMobile),
    icon: { name: 'lucide:user-round-plus' },
    to: '/cards',
    class: defaultClass('/cards')
  },
  visitors: {
    label: $translate(translations.value?.visitorsPage),
    icon: { name: 'fa6-regular:eye' },
    to: '/visitors',
    class: defaultClass('/visitors')
  },
  favorites: {
    label: $translate(translations.value?.favoritesPage),
    mobileLabel: $translate(translations.value?.favoritesPageMobile),
    icon: { name: 'ic:baseline-favorite-border' },
    to: '/favorites',
    class: defaultClass('/favorites')
  },
  chat: {
    label: $translate(translations.value?.chatPage),
    mobileLabel: $translate(translations.value?.chatPageMobile),
    icon: { name: 'ic:outline-mail' },
    to: '/chat',
    count: chatStore.unreadCount ?? 0,
    class: chatClass
  }
});

const mobileNavItems = Object.values(navItems.value).filter(
  (item) => item.label != $translate(translations.value?.visitorsPage)
);

watch(
  () => chatStore.unreadCount,
  () => {
    if (navItems.value.chat.count != chatStore.unreadCount) {
      navItems.value.chat.count = chatStore.unreadCount;
    }
  }
);

watch(
  () => chatStore.creditCount,
  () => {
    if (creditsButton.value.count != chatStore.creditCount) {
      creditsButton.value.count = chatStore.creditCount;
      verifyCredits.value++;
    }
  }
);

watch(verifyCredits, async () => {
  setTimeout(async () => {
    await chatStore.getCredits();
    setTimeout(async () => {
      creditsButton.value.count = await chatStore.getCredits(true);
    }, 1000);
  }, 1000);
});

function showcreditsModal() {
  modalStore.showModalComponent(EModal.insufficientCreditsModalComponent);
}

function showLoginModal() {
  modalStore.showModalComponent(EModal.loginModalComponent);
}

function showHeaderModal() {
  modalStore.showModalComponent(EModal.headerMenuModalComponent, false, creditsButton.value.count);
}

onMounted(async () => {
  if (authStore.isAuthorized) {
    await chatStore.initStore();
  }
});
</script>

<template>
  <div :class="{ 'bg-header-bg sticky top-0 z-30': !homepage }">
    <div
      v-if="authStore.isAuthorized"
      id="pageHeader"
      class="container flex items-center justify-between py-2 lg:py-4"
    >
      <div class="flex items-center gap-2 lg:gap-4">
        <div
          class="hover:cursor-pointer lg:hidden"
          :data-testid="props.testId + '/BurgerMenu'"
          @click="showHeaderModal()"
        >
          <Icon name="iconamoon:menu-burger-horizontal-bold" class="text-header-text" size="25" />
        </div>

        <NuxtLink
          to="/"
          class="mx-auto shrink-0 grow-0 md:ml-1 md:mr-0"
          :data-testid="props.testId + '/Logo'"
        >
          <img class="h-12 sm:h-16" :src="logo.src" :alt="logo.alt" />
        </NuxtLink>

        <div
          class="bg-header-bg fixed bottom-0 left-0 z-30 w-full place-content-center md:static md:w-auto md:bg-transparent min-[1460px]:w-full"
        >
          <nav
            class="text-header-text hidden pl-4 text-lg lg:flex lg:text-base min-[1460px]:text-lg"
          >
            <ul class="mx-aut flex flex-row gap-4">
              <li v-for="(navItem, index) of navItems" :key="index">
                <NuxtLink
                  :to="navItem.to"
                  class="hover:text-link-hover flex flex-row place-content-center place-items-center gap-1 py-4"
                  :class="navItem.class"
                  :data-testid="props.testId + '/' + navItem.label + 'Button'"
                >
                  <Icon size="21" :name="navItem.icon.name" :flip="navItem.icon.flip" />
                  <span class="text-inherit">
                    {{ navItem.label }}
                  </span>
                  <div
                    v-if="navItem.count !== undefined"
                    class="counter text-header-text r bg-primary relative bottom-4 right-2 max-h-5 min-w-5 rounded-full text-center text-xs"
                    :data-testid="props.testId + '/' + navItem.label + 'Counter'"
                  >
                    {{ navItem.count > 9 ? '9+' : navItem.count }}
                  </div>
                </NuxtLink>
              </li>
            </ul>
          </nav>
          <nav
            class="text-header-text flex h-16 w-full text-lg md:hidden md:text-base min-[1460px]:text-lg"
          >
            <ul class="mx-auto flex flex-row gap-4">
              <li v-for="(navItem, index) of mobileNavItems" :key="index">
                <NuxtLink
                  :to="navItem.to"
                  class="flex flex-row place-content-center place-items-center gap-1 py-4"
                  :class="navItem.class"
                  :data-testid="props.testId + '/' + navItem.mobileLabel + 'MobileButton'"
                >
                  <div class="flex flex-col">
                    <Icon
                      size="24"
                      :name="navItem.icon.name"
                      :flip="navItem.icon.flip"
                      class="mx-auto"
                    />
                    <span class="text-sm text-inherit">
                      {{ navItem.mobileLabel ?? navItem.label }}
                    </span>
                  </div>
                  <div
                    v-if="navItem.count !== undefined"
                    class="counter text-header-text r bg-primary relative bottom-6 right-5 max-h-5 min-w-5 rounded-full text-center text-xs"
                    :data-testid="props.testId + '/Mobile' + navItem.label + 'Counter'"
                  >
                    {{ navItem.count > 9 ? '9+' : navItem.count }}
                  </div>
                </NuxtLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="flex h-full place-items-center gap-4">
        <button
          class="border-header-text/80 flex flex-row place-content-evenly items-center gap-2 rounded-lg border-2 p-2"
          :data-testid="props.testId + '/CreditButton'"
          @click="showcreditsModal()"
        >
          <Icon :name="creditsButton.icon.name" class="text-header-text" size="30" />
          <div
            v-if="creditsButton.count !== undefined"
            class="text-header-text bg-primary relative my-auto min-h-6 min-w-6 place-content-center rounded-full text-center text-xs"
            :data-testid="props.testId + '/CreditCounter'"
          >
            {{ creditsButton.count > 9 ? '9+' : creditsButton.count }}
          </div>
        </button>

        <NuxtLink
          to="/account"
          class="border-header-text/70 hidden place-items-center rounded-full border-2 p-3 lg:flex"
          :class="accountClass"
          :data-testid="props.testId + '/AccountButton'"
        >
          <Icon name="tdesign:user-1" class="text-header-text mx-auto flex" size="25" />
        </NuxtLink>
      </div>
    </div>

    <div v-else id="pageHeader" class="container flex flex-wrap justify-between gap-2 py-2">
      <NuxtLink to="/" class="shrink-0 grow-0" :data-testid="props.testId + '/Logo'">
        <img class="h-12 sm:h-16" :src="logo.src" :alt="logo.alt" />
      </NuxtLink>

      <button
        :data-testid="props.testId + '/LoginButton'"
        class="bg-btn-primary-bg hover:bg-btn-primary-hover-bg my-auto flex items-center justify-center gap-4 rounded-lg p-2"
        @click="showLoginModal()"
      >
        <h1 class="hidden text-nowrap sm:block">{{ $translate(translations?.loginButton) }}</h1>
        <Icon name="ic:round-login" class="text-header-text flex" size="30" />
      </button>
    </div>

    <div class="col-span-5 border border-[#333741]/80"></div>
  </div>
</template>

