<script setup lang="ts">
import { usePaymentStore } from "../../stores/payment.store";
import { storeToRefs } from "pinia";

const paymentStore = usePaymentStore();
const { paymentProducts } = storeToRefs(paymentStore);
await paymentStore.fetchProducts();

const creditProducts = (paymentProducts.value ?? [])
  .filter((x) => x.reward_type === "credits")
  .map((x) => ({
    id: x.id,
    totalPrice: x.price,
    amount: x.reward_quantity,
    itemPrice: Math.round((x.price / x.reward_quantity) * 100) / 100,
  }));

creditProducts.sort((a, b) => a.itemPrice - b.itemPrice);
</script>

<template>
  <div v-if="creditProducts.length > 0" class="relative overflow-x-auto">
    <table class="text-sm text-left text-text">
      <thead class="text-xs uppercase">
        <tr>
          <th scope="col" class="px-4 py-3 border border-bg">Credits</th>
          <th scope="col" class="px-4 py-3 border border-bg">Prijs</th>
          <th scope="col" class="px-4 py-3 border border-bg">
            Prijs per bericht
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="product of creditProducts" :key="product.id">
          <td
            scope="row"
            class="px-4 py-2 font-medium whitespace-nowrap border border-bg"
          >
            {{ product.amount }}
          </td>
          <td scope="row" class="px-4 py-2 border border-bg">
            € {{ product.totalPrice }}
          </td>
          <td scope="row" class="px-4 py-2 border border-bg">
            € {{ product.itemPrice }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
