import { defineStore, storeToRefs } from 'pinia';
import type {
  GeoWizardCity,
  CityDropdownItem,
  RegionDropdownItem,
  CountryCode
} from '../types/geo/geo';
import { computed, ref } from 'vue';
import { useSiteConfigStore } from './siteConfig.store';
import { createGeoFetchClient } from '../composables/apiClients';
import type { LabelValuePair } from '../types';

export const useGeoStore = defineStore('geo', () => {
  const geoWizardClient = createGeoFetchClient();
  const siteConfigStore = useSiteConfigStore();
  const { localeCode } = storeToRefs(siteConfigStore);
  const countryCode = (localeCode?.value?.split('_')[1] ?? '') as CountryCode;

  const geoRegions = ref<string[]>();

  const geoRegionOptions = computed<RegionDropdownItem[]>(() => {
    if (!geoRegions.value) {
      return [
        {
          _id: -1,
          label: "Regio's laden...",
          value: null,
          default: true
        }
      ];
    }

    const options: RegionDropdownItem[] = [
      {
        _id: -1,
        label: 'Selecteer een regio...',
        value: null,
        default: true
      }
    ];

    geoRegions.value.forEach((x, i) => {
      options.push({
        _id: i,
        label: x,
        default: false,
        value: x
      });
    });

    return options;
  });

  const geoRegionFilters = computed<LabelValuePair<string>[]>(() => {
    const locations: LabelValuePair<string>[] = [{ label: 'All regions', value: 'all' }];

    geoRegions.value?.forEach((x) => {
      locations.push({
        label: x,
        value: x
      });
    });

    return locations;
  });

  /**
   * @returns whether or not the request was succesful
   */
  async function fetchGeoRegions(): Promise<boolean> {
    if (geoRegions.value) return true;

    if (!localeCode.value) return false;

    if (!countryCode) return false;

    const { data: response, error } = await geoWizardClient(
      '/locations/{country-code}/states/search/',
      {
        method: 'GET',
        path: {
          'country-code': countryCode
        }
      }
    );

    if (!response || error) {
      return false;
    }
    geoRegions.value = response.states;
    return true;
  }

  async function fetchCitiesQuery(query: string): Promise<boolean> {
    if (!localeCode.value) return false;

    if (!countryCode) return false;

    const { data: response, error } = await geoWizardClient(
      '/locations/{country-code}/cities/search/{query}',
      {
        method: 'GET',
        path: {
          'country-code': countryCode,
          query: query
        }
      }
    );

    if (!response || !response.cities || error) {
      return false;
    }

    geoCities.value = response.cities;
    return true;
  }

  async function fetchCitiesByState(state: string): Promise<boolean> {
    if (!localeCode.value) return false;

    if (!countryCode) return false;

    const { data: response, error } = await geoWizardClient(
      '/locations/{country-code}/states/{state}/cities/search/',
      {
        method: 'GET',
        path: {
          'country-code': countryCode,
          state: state
        }
      }
    );

    if (!response || !response.cities || error) {
      return false;
    }

    geoCities.value = response.cities;
    return true;
  }

  const noRegionSelected: GeoWizardCity = {
    city: 'please select a region',
    state: '', //
    timezone: '', // Europe/London
    region_1: '', // England
    region_2: '',
    region_3: '', // England
    region_4: '', // West Midlands
    latitude: '', // 52.47061
    longitude: '' // -1.875034
  };

  const geoCities = ref<GeoWizardCity[]>([noRegionSelected]);

  const geoCityOptions = computed(() => {
    if (!geoCities.value) {
      return [
        {
          _id: -1,
          label: '-',
          value: null,
          default: true
        }
      ];
    }

    const options: CityDropdownItem[] = [];

    geoCities.value.forEach((x, i) => {
      options.push({
        _id: i,
        label: x.city,
        default: false,
        value: x
      });
    });

    return options;
  });

  /**
   * @returns whether or not the request was succesful
   */
  async function fetchGeoCities(state: string): Promise<boolean> {
    if (!geoRegions.value) return true;

    if (!localeCode.value) return false;

    if (!countryCode) return false;

    const { data: response, error } = await geoWizardClient(
      '/locations/{country-code}/states/{state}/cities/search/',
      {
        method: 'GET',
        path: {
          'country-code': countryCode,
          state: state
        }
      }
    );
    if (!response || error) {
      return false;
    }

    geoCities.value = response.cities;
    return true;
  }

  return {
    countryCode,
    geoRegions,
    geoRegionOptions,
    geoRegionFilters,
    geoCities,
    geoCityOptions,

    fetchGeoRegions,
    fetchGeoCities,
    fetchCitiesQuery,
    fetchCitiesByState
  };
});

