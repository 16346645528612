<script setup lang="ts">
import { navigateTo } from '#app';
import { useModalStore, useAuthStore, useChatStore } from '../../../stores';
import { onMounted, ref } from 'vue';
import { EModal, type HorizonComponentProps } from '../../../types';

const props = defineProps<HorizonComponentProps>();
const chatStore = useChatStore();
const modalStore = useModalStore();
const credits = ref<number>();

interface HeaderBurgerButtons {
  [page: string]: HeaderBurgerButton;
}
interface HeaderBurgerButton {
  title: string;
  subTitle: string;
  icon: string;
  function: () => void;
}

async function logout() {
  await useAuthStore().logout();
}

const headerButtons: HeaderBurgerButtons = {
  profile: {
    title: 'Profile',
    subTitle: 'The more your profile is filled out, the better we can match you to profiles.',
    icon: 'fa6-regular:user',
    function: () => {
      navigateTo('/account');
      useModalStore().clearModal();
    }
  },
  visitors: {
    title: 'Visitors',
    subTitle: 'This is where you can view the users that visited your profile.',
    icon: 'fa6-regular:eye',
    function: () => {
      navigateTo('/visitors');
      useModalStore().clearModal();
    }
  },
  Credits: {
    title: 'Credits',
    subTitle: 'Buy credits to send more messages, flirts, and sex proposals.',
    icon: 'la:coins',
    function: () => useModalStore().showModalComponent(EModal.insufficientCreditsModalComponent)
  },
  settings: {
    title: 'Settings',
    subTitle: 'Set your e-mail, pictures, and manage your notifications.',
    icon: 'heroicons:cog-6-tooth',
    function: () => {
      navigateTo('/settings');
      useModalStore().clearModal();
    }
  },
  logOut: {
    title: 'Log Out',
    subTitle: '',
    icon: 'fa-regular:arrow-alt-circle-right',
    function: () => logout()
  }
};

function exitModal() {
  navigateTo('/members');
  modalStore.clearModal();
}

onMounted(async () => {
  credits.value = await chatStore.getCredits(true);
});
</script>

<template>
  <div class="bg-header-bg flex size-full">
    <div class="mx-auto my-auto flex w-full">
      <div class="mx-auto flex flex-col">
        <button
          v-for="button of headerButtons"
          :key="button.title"
          class="hover:bg-btn-secondary-hover-bg mb-4 flex h-20 w-screen max-w-[606px] flex-row rounded-md px-4"
          :data-testid="props.testId + '/' + button.title + 'Button'"
          @click="() => button.function()"
        >
          <div class="my-auto flex size-14">
            <Icon :name="button.icon" class="text-primary my-auto ml-2" size="30" />
          </div>
          <div class="flex h-full flex-col">
            <div
              class="flex flex-row"
              :class="{
                'mt-6': button.subTitle != '',
                'my-auto': button.subTitle === ''
              }"
            >
              <p class="text-header-text text-left text-sm">{{ button.title }}</p>
              <div
                v-if="button.title === 'Credits' && credits != undefined"
                class="text-header-text bg-primary relative bottom-2 left-1 size-6 place-content-center rounded-full text-center text-xs"
              >
                {{ credits > 9 ? '9+' : credits }}
              </div>
            </div>
            <p class="text-footer-text text-left text-sm">{{ button.subTitle }}</p>
          </div>
          <Icon name="circum:paperplane" class="text-primary my-auto ml-auto mr-2" size="25" />
        </button>
        <button
          class="bg-primary flex h-10 rounded-md"
          :data-testid="props.testId + '/HomeButton'"
          @click="exitModal()"
        >
          <div class="mx-auto flex flex-row">
            <p class="text-btn-primary-text mr-2 mt-2.5">Back to home</p>
            <Icon name="typcn:home" class="text-modal-text ml-auto mr-2 mt-1.5" size="25" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>

