import type { Page } from "../types/config/types";
import type { RouteParams } from "vue-router";

/**
 * Get single param from a nuxt route
 */
export function getSingleRouteParam(route: RouteParams, param: string) {
  const value = route[param];
  if (!value) return undefined;
  return Array.isArray(value) ? value[0] : value;
}

/**
 * Find page from routePath
 *
 * /profiles/:id -> template
 * /profiles/1 -> route
 *
 * @param {Page[]} pages
 * @param {string} routePath
 */
export function getPageFromRoutePath(pages: Page[] | undefined, routePath: string) {
  return pages?.find((page) => {
    if (page.path === routePath) return true;

    // match slugs
    const routeParts = routePath.split("/");
    const pageParts = page.path.split("/");

    if (routeParts.length !== pageParts.length) return false;

    for (let i = 0; i < routeParts.length; i++) {
      if (pageParts[i].startsWith(":")) continue;
      if (routeParts[i] !== pageParts[i]) return false;
    }

    return true;
  });
}
