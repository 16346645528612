export function genPaginationPages(
  page: number,
  totalPages: number,
  length: number
) {
  if (length > totalPages) length = totalPages;

  let left = page - Math.floor(length / 2);
  left = Math.max(left, 0);
  left = Math.min(left, totalPages - length);

  return Array.from({ length }, (_, i) => left + i);
}
