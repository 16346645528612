<script lang="ts" setup>
import { type HorizonComponentProps, EModal } from '../../types';
import { overrideStyles } from '../../utils';
import { useModalStore } from '../../stores';
import type { CtaSimpleCentredProps } from './SimpleCentred.schema';

const props = defineProps<HorizonComponentProps<CtaSimpleCentredProps>>();
const modalStore = useModalStore();
function showRegisterModal() {
  modalStore.showModalComponent(EModal.registrationModalComponent);
}
</script>

<template>
  <section v-if="props.data" class="mx-auto mt-5 max-w-3xl px-4">
    <h2 v-if="props.data.title" class="mb-4 text-center text-5xl font-bold">
      {{ props.data.title }}
    </h2>
    <p v-if="props.data.description" class="mb-8 text-center text-lg leading-relaxed text-gray-600">
      {{ props.data.description }}
    </p>

    <div v-if="props.data.linkButton" class="mx-auto w-max">
      <NuxtLink
        class="btn text-center text-sm font-medium"
        :to="props.data.linkButton.data.href || '#'"
        :test-id="props.testId + '/LinkButton'"
      >
        {{ props.data.linkButton.data.text || 'Get started' }}
      </NuxtLink>
    </div>
    <div v-if="props.data.modalButton" class="mx-auto w-max">
      <ButtonSimple
        :data="props.data.modalButton?.data"
        :style="overrideStyles(props.data.modalButton?.overrides)"
        :test-id="props.testId"
        @click="showRegisterModal()"
      />
    </div>
  </section>
</template>

