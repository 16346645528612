import type { ExtractResponse } from '@btr/fetch-frog';
import type { paths } from '../schemas/collectconsent';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { usePartnerTrackingStore } from './partnerTracking';
import { useSiteConfigStore } from './siteConfig.store';
import { useAuthStore } from '.';
import { useRoute } from '#app';

type UserConsentState = ExtractResponse<paths, '/locale/{localeCode}/user/consent', 'get'>;

export const useUserConsentStore = defineStore('userConsent', () => {
  const siteConfigStore = useSiteConfigStore();

  const partnerTrackingStore = usePartnerTrackingStore();

  const authStore = useAuthStore();

  const userConsentState = ref<UserConsentState>();

  const hasAccess = ref<boolean>(false);

  const consentSegments = computed(() =>
    userConsentState.value?.consentSegments
      .filter((x) => !x.hasAccepted)
      .map((x) => x.consentSegment)
  );

  const totalSegmentCount = computed(() => consentSegments.value?.length ?? 0);

  function loadUserConsentState(state: UserConsentState) {
    userConsentState.value = state;

    if (state.hasAccess) {
      grantUserConsentAccess();
    }
  }

  function grantUserConsentAccess() {
    if (userConsentState.value) {
      userConsentState.value.hasAccess = true;
    }
    hasAccess.value = true;

    // Only load doi pixels if they are delayed in this site config.
    // If this is false, these pixel will already have been loaded right after activation.
    // (auth.store.ts registerAccount() for SSO, and email-token-confirm.vue for email)
    const isDoiDelayed = siteConfigStore.partnerPixelConfig && siteConfigStore.partnerPixelConfig.delayedDoi;
    if (isDoiDelayed) {
      // If DOI is delayed, we no longer have access to the users date of birth form data, thus we pass it along the identity api -> flow handler -> sending api as url parameter
      const profileID = authStore.authUser?.uid;
      const age = useRoute().query['age'];

      partnerTrackingStore.loadDoiPixels(profileID, age ? Number(age) : undefined)
    }
  }

  return {
    userConsentState,
    consentSegments,
    totalSegmentCount,
    hasAccess,

    loadUserConsentState,
    grantUserConsentAccess
  };
});

