import { z } from 'zod';

const zodPixelStrategies = z.enum( ['iframe', 'image', 'server'] as const);
const pixelSchema = z.object({
  strategy: zodPixelStrategies,
  url: z.string()
});
export type PixelStrategy = z.infer<typeof zodPixelStrategies>;

export type PixelSchema = z.infer<typeof pixelSchema>;
export type LoadedPixel = PixelSchema & { id: number };

export const PixelParamKeys = z.enum(['enduser_id', 'transaction_id', 'sub_id', 'age'] as const);
export type PixelParamKey = z.infer<typeof PixelParamKeys>;

export const PartnerPixelsSchema = z.strictObject({
  p: z.strictObject({
    value: z.number(),
    isFixed: z.boolean()
  }),
  pi: z.strictObject({
    value: z.union([z.string(), z.number()]),
    isFixed: z.boolean()
  }),
  pe: z.strictObject({
    value: z.union([z.string(), z.number()]),
    isFixed: z.boolean()
  }),
  soiPixels: z.array(pixelSchema),
  doiPixels: z.array(pixelSchema),
  delayedDoi: z.boolean(),
  requiredParams: z.array(PixelParamKeys)
});

export type PartnerPixelConfig = z.infer<typeof PartnerPixelsSchema>;