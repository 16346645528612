import { defineNuxtPlugin, useNuxtApp } from '#app';
import { storeToRefs } from '#imports';
import { useSiteConfigStore } from '@horizon/library/stores';

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp();
  const { t } = $i18n;

  const siteConfigStore = useSiteConfigStore();
  const { translationConsts } = storeToRefs(siteConfigStore);

  return {
    provide: {
      translate: (key: string | undefined, vars?: Record<string, string>) => {
        if (!key) {
          throw new Error('Key does not excist or is not set correctly');
        }

        if (!translationConsts.value) {
          throw new Error('Could not get translation variables from config');
        }
        if (vars) {
          const translationVars = { ...translationConsts.value, ...vars };
          return t(key, translationVars);
        }
        return t(key, translationConsts.value);
      }
    }
  };
});

