<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useProfileStore, useModalStore } from '../../stores';
import { dateToAge, getSingleRouteParam, storageKeys, getStoredValue } from '../../utils';
import { useProfilePage } from './useProfilePage';
import { watchDebounced, useWindowSize } from '@vueuse/core';
import type { HorizonComponentProps } from '../../types';

const props = defineProps<HorizonComponentProps>();
const route = useRoute();
const id = getSingleRouteParam(route.params, 'id');

const profileStore = useProfileStore();
const modalStore = useModalStore();
function showFlirtModal() {
  if (!id) return;
  
  modalStore.showFlirtModal(true, {
    profileId: id
  });
}

const { width } = useWindowSize();
watchDebounced([width], resize, { debounce: 100 });

function resize() {
  if (width.value > 768 && imageIndex.value >= 1) imageIndex.value--;
}

const imageIndex = ref(0);

const { profile, hasFavorited, fillProfileData, favoriteFunc } = useProfilePage();

if (id) {
  const profileData = await profileStore.getProfileById(id).catch(() => {});
  if (profileData) {
    fillProfileData(profileData);
  }
}
let prevProfilePage = 'Members';
onMounted(() => {
  prevProfilePage = getStoredValue(storageKeys.profileGrid.prevPage) ?? 'Members';
});
</script>

<template>
  <div class="container flex w-full flex-col md:pb-4 md:pt-6">
    <div class="flex py-1 md:mb-6 md:py-0">
      <NuxtLink
        :to="{ name: prevProfilePage }"
        class="my-auto md:mr-0.5"
        :data-testid="props.testId + '/BackButton'"
      >
        <Icon name="ic:chevron-left" class="text-header-text" size="30" />
      </NuxtLink>
      <p class="text-header-text mt-auto text-2xl">
        {{ profile.name }}
      </p>
      <span class="text-primary ml-2 mt-auto text-xl">
        {{ dateToAge(profile.dateOfBirth) }} Years
      </span>
      <VTooltip>
        <Icon name="mdi:heart-circle" class="text-header-text ml-1" size="17" />
        <template #popper>
          {{ profileStore.getTooltipText() }}
        </template>
      </VTooltip>
    </div>

    <div class="hidden grid-cols-[repeat(auto-fit,330px)] justify-center gap-2 lg:grid">
      <img
        v-for="image of profile.images"
        :key="image.id"
        :src="image.url"
        class="flex h-full w-full object-cover lg:rounded-xl"
        alt=""
      />
    </div>

    <div class="relative flex lg:hidden">
      <ul
        v-if="profile.images && profile.images.length > 1"
        class="top-22 absolute flex w-full flex-row justify-center gap-2 px-4 lg:hidden"
      >
        <li v-for="(image, index) in profile.images" :key="index" class="w-full">
          <button
            aria-label="goto image {{ index }}"
            :class="{
              'bg-primary': imageIndex === index,
              'bg-gray-700 opacity-50': imageIndex !== index
            }"
            class="h-1 w-full rounded-full"
            :data-testid="props.testId + '/GoToImage' + index + 'Button'"
            @click="imageIndex = index"
          ></button>
        </li>
      </ul>

      <div class="w-full">
        <button
          v-if="profile.images && imageIndex < profile.images.length - 1"
          aria-label="next image"
          class="absolute right-0 top-1/2 -translate-y-1/2"
          :data-testid="props.testId + '/NextImageButton'"
          @click="
            () => {
              imageIndex = imageIndex + 1;
              if (profile.images && imageIndex > profile.images.length - 1) {
                imageIndex = 0;
              }
            }
          "
        >
          <Icon name="ic:chevron-right" class="text-primary text-shado" size="55" />
        </button>

        <img
          v-if="profile.images?.[imageIndex]"
          :src="profile.images[imageIndex].url"
          class="aspect-square h-full w-full object-cover"
          alt=""
        />

        <button
          v-if="imageIndex > 0"
          aria-label="previous imageButton"
          class="image absolute left-0 top-1/2 -translate-y-1/2"
          :data-testid="props.testId + '/PrevImageButton'"
          @click="
            () => {
              imageIndex = imageIndex - 1;
              if (profile.images && imageIndex < 0) {
                imageIndex = profile.images.length - 1;
              }
            }
          "
        >
          <Icon name="ic:chevron-left" class="imageButton text-primary" size="55" />
        </button>
      </div>
    </div>

    <div
      id="fav/msg buttons"
      class="relative bottom-7 mt-3 grid h-9 w-full gap-2 px-4 sm:grid-cols-2 md:bottom-0"
    >
      <div>
        <button
          class="bg-btn-secondary-bg hover:bg-btn-secondary-hover-bg text-header-text size-full rounded-md py-2 text-sm md:text-base lg:rounded-xl"
          :data-testid="props.testId + '/FavoriteButton'"
          @click="favoriteFunc"
        >
          <span class="text-btn-primary-text my-auto"
            >{{ hasFavorited ? 'Remove from' : 'Add to' }} Favorites</span
          >
          <Icon
            :name="hasFavorited ? 'fa6-solid:heart-circle-minus' : 'fa6-solid:heart-circle-plus'"
            class="ml-2 size-5 text-gray-400"
          />
        </button>
      </div>

      <div>
        <NuxtLink
          class="btn bg-btn-primary-bg hover:bg-btn-primary-hover-bg text-header-text size-full place-content-center rounded-md border-0 p-0 py-2 lg:rounded-xl"
          :to="`/message/${profile.id}`"
          :data-testid="props.testId + '/ToChatButton'"
        >
          <span class="text-btn-primary-text text-sm md:text-base"> Send Message</span>
          <Icon name="mage:message-conversation-fill" class="text-secondary ml-2" size="25" />
        </NuxtLink>
      </div>
    </div>

    <div id="about me" class="mt-8 px-4 md:px-2">
      <strong class="text-header-text font-bold">About me</strong>
      <p class="text-footer-text">{{ profile.aboutMe || '-' }}</p>
    </div>

    <button
      class="bg-btn-secondary-bg hover:bg-btn-secondary-hover-bg text-header-text w-9/10 mx-auto mt-6 flex h-9 place-content-center rounded-md md:mx-2 md:w-full md:rounded-xl"
      :data-testid="props.testId + '/FlirtButton'"
      @click="showFlirtModal()"
    >
      <span class="text-btn-primary-text my-auto">Flirt</span>
      <Icon name="fa6-solid:hand-holding-heart" class="ml-2 mt-1 text-gray-400" size="25" />
    </button>

    <div id="personal information" class="mt-6 px-4 md:px-2">
      <strong class="text-header-text font-bold">Personal Information</strong>
      <div
        id="grid"
        class="grid grid-cols-2 grid-rows-6 gap-2 pb-8 md:grid-cols-3 md:grid-rows-4 md:gap-6 md:pb-0"
      >
        <div>
          <p class="text-footer-text">Age</p>
          <p class="text-header-text">{{ dateToAge(profile.dateOfBirth) || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">State</p>
          <p class="text-header-text">{{ profile.state || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Town</p>
          <p class="text-header-text">{{ profile.city || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Height</p>
          <p class="text-header-text">{{ profile.heightChoice || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Body type</p>
          <p class="text-header-text">{{ profile.bodyType || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Marital Status</p>
          <p class="text-header-text">{{ profile.civilStatus || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Hair color</p>
          <p class="text-header-text">{{ profile.hairColor || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Eye color</p>
          <p class="text-header-text">{{ profile.eyeColor || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Tattoo</p>
          <p class="text-header-text">{{ profile.tattoo || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Smoker</p>
          <p class="text-header-text">{{ profile.smoker || '-' }}</p>
        </div>
        <div>
          <p class="text-footer-text">Piercing</p>
          <p class="text-header-text">{{ profile.piercing || '-' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.imageButton {
  text-shadow: 1px 1px 2px #000;
}
</style>

