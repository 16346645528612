import { capitalize, reactive, ref } from 'vue';
import type { ProfileData, ProfileDataTag } from '../../types/profile/profile';
import { sanitizeContent } from '../../utils/sanitizeContent';
import { profileHeight } from '../../utils/profileHeight';
import { useProfileStore, useSiteConfigStore } from '../../stores';
import { useDebounceFn } from '@vueuse/core';
import { useGeoStore } from '../../stores/geo.store';

export function useProfilePage() {
  const profileStore = useProfileStore();
  const geoStore = useGeoStore();

  const hasFavorited = ref<boolean>(false);

  const hasLiked = ref<boolean | null>(null);

  const isUS = useSiteConfigStore().localeCode?.split('_')[1] === 'US';

  const profile = reactive({
    id: '',
    name: '',
    dateOfBirth: '',
    height: '',
    heightChoice: '',
    bodyType: '',
    eyeColor: '',
    hairColor: '',
    aboutMe: '',
    aboutYou: '',
    civilStatus: '',
    images: [] as {
      id: string;
      url: string;
      approved: boolean;
    }[],
    isEntertainment: true,
    city: '',
    state: '',
    gender: 'f',
    tattoo: 'No',
    piercing: 'No',
    smoker: 'No'
  });

  async function fillProfileData(profileData: ProfileData) {
    if (profileData.attributes) {
      for (const attribute of profileData.attributes) {
        switch (attribute.name) {
          case 'profile_name':
            profile.name = capitalize(sanitizeContent(attribute.value));
            break;
          case 'date_of_birth':
            profile.dateOfBirth = capitalize(sanitizeContent(attribute.value));
            break;
          case 'about_me':
            profile.aboutMe = capitalize(sanitizeContent(attribute.value));
            break;
          case 'city': {
            profile.city = capitalize(sanitizeContent(attribute.value)); // TODO: becomes seperate field https://harlemnext.atlassian.net/browse/HIL-41
            const fetched = await geoStore.fetchCitiesQuery(profile.city); // TODO: becomes seperate field https://harlemnext.atlassian.net/browse/HIL-41
            if (fetched) profile.state = geoStore.geoCities[0].state;
            break;
          }
          case 'about_you':
            profile.aboutYou = sanitizeContent(attribute.value);
            break;
          case 'length_choice': {
            const choices = capitalize(sanitizeContent(attribute.value)).split('-');
            profile.heightChoice = `from ${profileHeight(choices[0], isUS, true)} till ${profileHeight(choices[1], isUS, true)}`;
            break;
          }
        }
      }
    }

    if (profileData.tag_categories) {
      for (const tag of profileData.tag_categories) {
        switch (tag.name) {
          case 'length':
            profile.height = profileHeight(capitalize(sanitizeContent(tag.tags?.[0].name)), isUS);
            break;
          case 'build':
            profile.bodyType = capitalize(sanitizeContent(tag.tags?.[0].name));
            break;
          case 'eye_color':
            profile.eyeColor = capitalize(sanitizeContent(tag.tags?.[0].name));
            break;
          case 'hair_color':
            profile.hairColor = capitalize(sanitizeContent(tag.tags?.[0].name));
            break;
          case 'civil_status':
            profile.civilStatus = capitalize(sanitizeContent(tag.tags?.[0].name));
            break;
          case 'gender': {
            profile.gender = tag.tags?.[0].name;
            break;
          }
          case 'characteristics': {
            extractCharacteristics(tag.tags);
            break;
          }
        }
      }
    }

    profile.id = profileData.profile_id;
    profile.images = profileData.media;
    profile.isEntertainment = profileData.is_entertainment ?? true;
    // not yet applicible but will be https://harlemnext.atlassian.net/browse/HIL-41
    // profile.city = profileData.city
    // profile.state = profileData.state
    hasFavorited.value = profileData.favorite ?? false;
    hasLiked.value = profileData.liked;
  }

  function extractCharacteristics(chars: ProfileDataTag[]) {
    for (const tag of chars) {
      switch (tag.name) {
        case 'piercings':
          profile.piercing = 'Yes';
          break;
        case 'tattoo':
          profile.piercing = 'Yes';
          break;
        case 'smoker':
          profile.piercing = 'Yes';
          break;
      }
    }
  }

  const debouncedFavorite = useDebounceFn(() => {
    if (!profile?.id) return;
    profileStore.favoriteFunction(profile.id, hasFavorited.value);
  }, 500);

  function favoriteFunc() {
    hasFavorited.value = !hasFavorited.value;
    debouncedFavorite();
  }

  const debouncedLike = useDebounceFn(() => {
    if (!profile?.id) return;
    if (hasLiked.value === undefined) return;
    if (hasLiked.value === null) {
      profileStore.unlikeFunction(profile.id);
    } else {
      profileStore.likeFunction(profile.id, hasLiked.value);
    }
  }, 250);

  function likeFunc() {
    if (hasLiked.value === null) {
      hasLiked.value = true;
    } else if (hasLiked.value === true) {
      hasLiked.value = false;
    } else {
      hasLiked.value = null;
    }
    debouncedLike();
  }

  return {
    profile,
    hasFavorited,
    hasLiked,

    fillProfileData,
    favoriteFunc,
    likeFunc
  };
}

