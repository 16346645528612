<script setup lang="ts">
const props = defineProps<{
    url: string;
}>();
</script>

<template>
    <img :src="props.url" width="1" height="1">
</template>

