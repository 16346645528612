<script setup lang="ts">
import type { IProfileCardProps, HorizonComponentProps } from '../../../types';
import { EModal } from '../../../types';
import { useModalStore, useProfileStore } from '../../../stores';
import { dateToAge } from '../../../utils';
import { ref, toRefs, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useDebounceFn } from '@vueuse/core';

const profileStore = useProfileStore();
const modalStore = useModalStore();
function showFiltersModal() {
  modalStore.showModalComponent(EModal.filtersModalComponent);
}
const props = defineProps<HorizonComponentProps<IProfileCardProps>>();
const noMoreProfilesText = ref<string>('');
const { profile } = toRefs(props.data);
const route = useRoute();
const homepage = computed(() => {
  return route.name === 'Home';
});

const isSkeletonCard = computed<boolean>(() =>
  props.data.profile.profile_id.split('#')[1] ? true : false
);

if (profile.value && profile.value.profile_id?.startsWith('empty')) {
  noMoreProfilesText.value = profile.value.profile_id?.split('.')[1];
}

const debounceImgError = useDebounceFn(async (event: Event) => {
  await imgError(event);
}, 250);

async function imgError(event: Event) {
  if (event === null || event.target === null) return;
  (event.target as HTMLImageElement).onerror = null;
  const images = await profileStore.getProfileImages([profile.value.profile_id], 500);
  if (images && images.length >= 1) {
    profile.value.image = {
      ...profile.value.image,
      url: images[0].url
    };
  }
  return true;
}
</script>

<template>
  <div>
    <template v-if="props.data?.isLoading && isSkeletonCard">
      <div
        :class="{ 'cursor-point': !props.data?.isDetailPageCard }"
        class="card border-primary h-4/5 animate-pulse border-2 bg-slate-700"
      >
        <div class="aspect-square h-full w-4/5 transition-transform duration-700 ease-in-out"></div>
        <footer class="bg-header-bg border-primary h-16 border-t-2 py-1 text-center">
          <div class="mx-6 my-1 h-5 rounded bg-slate-700"></div>
          <div class="mx-12 my-1 h-4 rounded bg-slate-700"></div>
        </footer>
      </div>
    </template>
    <template v-else-if="profile.profile_id.startsWith('empty')">
      <div
        class="text-header-text relative hidden aspect-[5/6] rounded-xl text-lg md:block lg:text-base xl:text-lg"
      >
        <div
          class="shadow-softglow bg-primary absolute block h-full w-full overflow-hidden rounded-xl p-4 text-sm shadow-black/40"
        >
          <div class="bg-primary mb-2 flex flex-row items-center gap-4 text-center">
            <p>{{ noMoreProfilesText }}</p>
          </div>

          <div class="border-primary relative mb-2 w-full border-0 bg-transparent text-center">
            Change your filters for new results
          </div>
          <button
            class="btn relative w-full border border-white bg-transparent p-2 text-center text-sm"
            :data-testid="props.testId + '/FilterButton'"
            @click="showFiltersModal()"
          >
            Select filters
          </button>
        </div>
      </div>

      <div
        class="mobilecard text-header-text relative block aspect-[5/6] rounded-xl text-base md:hidden"
      >
        <div
          class="shadow-softglow bg-primary absolute block size-full overflow-hidden rounded-xl p-4 text-sm shadow-black/40"
        >
          <div class="bg-primary mb-2 flex flex-row items-center gap-4 text-center">
            <p>{{ noMoreProfilesText }}</p>
          </div>

          <div class="border-primary relative mb-2 w-full border-0 bg-transparent text-center">
            Change your filters for new results
          </div>
          <button
            class="btn relative w-full border border-white bg-transparent p-2 text-center text-sm"
            :data-testid="props.testId + '/MobileFilterButton'"
            @click="showFiltersModal()"
          >
            Select filters
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="props.data?.profile">
      <div
        class="relative hidden aspect-[5/6] rounded-xl text-lg text-white md:block"
        :class="{ card: !homepage }"
      >
        <div class="outer h-full w-full transition-transform duration-700 ease-in-out">
          <div
            class="front shadow-softglow absolute h-full w-full overflow-hidden rounded-xl shadow-black/40"
          >
            <div class="gradient pointer-events-none relative h-full w-full">
              <img
                v-if="profile.image?.url"
                class="h-full w-full rounded-xl object-cover"
                :src="profile.image.url"
                alt=""
                :onerror="debounceImgError"
              />
            </div>

            <div class="absolute bottom-0 z-10 flex w-full bg-black/20 p-3 backdrop-blur-[3px]">
              <Icon name="fluent-emoji-flat:green-circle" size="10" class="relative top-3 mr-2" />
              <div>
                <p class="text-sm font-bold">
                  {{ profile.name }}
                </p>
                <p class="text-xs">{{ dateToAge(profile.date_of_birth) }} years old</p>
              </div>
            </div>
          </div>

          <div
            v-if="!homepage"
            class="back shadow-softglow bg-primary absolute block h-full w-full overflow-hidden rounded-xl p-4 shadow-black/40"
          >
            <div class="bg-primary mb-4 flex flex-col items-center gap-1">
              <img
                v-if="profile.image?.url"
                class="h-12 w-12 rounded-full border-2 border-white object-cover"
                :src="profile.image.url"
                alt=""
                :onerror="debounceImgError"
              />
              <p class="text-sm">{{ profile.name }}</p>
            </div>

            <NuxtLink
              :to="`/profile/${profile.profile_id}`"
              class="btn relative mb-2 w-full border border-white bg-transparent text-center text-sm"
              :data-testid="props.testId + '/ProfileButton'"
            >
              View profile
            </NuxtLink>
            <NuxtLink
              class="btn relative w-full border border-white bg-transparent text-center text-sm"
              :to="`/message/${profile.profile_id}`"
              :data-testid="props.testId + '/ToChatButton'"
            >
              Send message
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="mobilecard relative block aspect-[5/6] rounded-xl text-lg text-white md:hidden">
        <div class="outer h-full w-full">
          <NuxtLink
            :to="`/profile/${profile.profile_id}`"
            class="relative block h-full w-full"
            :data-testid="props.testId + '/MobileCard'"
          >
            <div
              class="front shadow-softglow absolute h-full w-full overflow-hidden rounded-xl shadow-black/40"
            >
              <div class="gradient pointer-events-none relative h-full w-full">
                <img
                  v-if="profile.image?.url"
                  class="h-full w-full rounded-xl object-cover"
                  :src="profile.image.url"
                  alt=""
                  :onerror="debounceImgError"
                />
              </div>

              <div class="absolute bottom-0 z-10 flex w-full bg-black/20 p-3 backdrop-blur-[3px]">
                <Icon name="fluent-emoji-flat:green-circle" size="10" class="relative top-3 mr-2" />
                <div>
                  <p class="text-sm font-bold">
                    {{ profile.name }}
                  </p>
                  <p class="text-xs">{{ dateToAge(profile.date_of_birth) }} years old</p>
                </div>
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.card {
  transform-style: preserve-3d;
  perspective: 1000px;

  & > div {
    transform-style: preserve-3d;
  }

  &:hover > div {
    transform: rotateY(180deg);
  }

  .back,
  .front {
    backface-visibility: hidden;
    z-index: 1;
  }

  .back {
    transform: rotateY(180deg);
    z-index: 1;
  }
}

.mobilecard {
  .front {
    backface-visibility: hidden;
    z-index: 1;
  }
}

.gradient {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    height: 100%;
    width: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }
}
</style>

