export enum EModal {
  registrationModalComponent = 'registrationModal',
  loginModalComponent = 'loginModal',
  forgotPasswordModalComponent = 'forgotPasswordModal',
  resetPasswordModalComponent = 'resetPasswordModal',
  insufficientCreditsModalComponent = 'insufficientCreditsModal',
  onboardingNoticeModalComponent = 'onboardingNoticeModal',
  filtersModalComponent = 'filtersModal',
  matchedModalComponent = 'matchedModal',
  flirtModalComponent = 'flirtModal',
  headerMenuModalComponent = 'headerMenuModal'
}
