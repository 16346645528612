import payload_plugin_AbginUS7Cq from "/pangaea/node_modules/.pnpm/nuxt-vuefire@1.0.2_patch_hash=6d5yqnqk75p6ntibflyypzdh2q_@firebase+app-types@0.9.2_firebase-a_7pi2ro6xpgnut2skpts746mks4/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_xzO3RpqHeD from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bfGkLGcg3T from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EgSRD2Iz7W from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_e0DIgSTJmY from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_evxKfRKl9S from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aUJLIQNDeg from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dhlHt4LL4W from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SzpdXeJ6fz from "/pangaea/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.36_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/pangaea/apps/horizon/horizon-ui-site/.nuxt/components.plugin.mjs";
import prefetch_client_JVQ3jsEkUa from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _001_notivue_client_bE2FHKqHiu from "/pangaea/apps/horizon/horizon-ui-site/.nuxt/001.notivue.client.mjs";
import floating_vue_EIcJ7xiw0h from "/pangaea/apps/horizon/horizon-ui-site/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_57LyfmJ7pu from "/pangaea/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.20.0_vue@3.4.36_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_kEhVBL5iTx from "/pangaea/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.20.0_vue@3.4.36_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_Jg5Z79gxEH from "/pangaea/node_modules/.pnpm/nuxt-vuefire@1.0.2_patch_hash=6d5yqnqk75p6ntibflyypzdh2q_@firebase+app-types@0.9.2_firebase-a_7pi2ro6xpgnut2skpts746mks4/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/pangaea/apps/horizon/horizon-ui-site/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_srFQ6qO8iF from "/pangaea/node_modules/.pnpm/nuxt-vuefire@1.0.2_patch_hash=6d5yqnqk75p6ntibflyypzdh2q_@firebase+app-types@0.9.2_firebase-a_7pi2ro6xpgnut2skpts746mks4/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/pangaea/apps/horizon/horizon-ui-site/.nuxt/vuefire-plugin.mjs";
import translate_4QtWREQLTk from "/pangaea/packages/horizon-ui-library/plugins/translate.ts";
export default [
  payload_plugin_AbginUS7Cq,
  revive_payload_client_xzO3RpqHeD,
  unhead_bfGkLGcg3T,
  router_EgSRD2Iz7W,
  payload_client_e0DIgSTJmY,
  navigation_repaint_client_evxKfRKl9S,
  check_outdated_build_client_aUJLIQNDeg,
  chunk_reload_client_dhlHt4LL4W,
  plugin_vue3_SzpdXeJ6fz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JVQ3jsEkUa,
  _001_notivue_client_bE2FHKqHiu,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_57LyfmJ7pu,
  i18n_kEhVBL5iTx,
  plugin_client_Jg5Z79gxEH,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_srFQ6qO8iF,
  vuefire_plugin_8cq4FnzXKb,
  translate_4QtWREQLTk
]