<script setup lang="ts">
import { usePartnerTrackingStore } from '@horizon/library/stores/partnerTracking';
import type { PixelStrategy } from '@horizon/library/types/config/partnerPixelsSchema';
import { storeToRefs } from 'pinia';
import type { Component } from 'vue';
import IFrame from './strategies/IFrame.vue';
import Image from './strategies/Image.vue';

const strategyComponentMapping: Partial<Record<PixelStrategy, Component>> = {
    iframe: IFrame,
    image: Image,
}

const partnerTrackingStore = usePartnerTrackingStore();
const { activePixels } = storeToRefs(partnerTrackingStore);
</script>

<template>
    <component
        :is="strategyComponentMapping[pixel.strategy]"
        v-for="pixel of activePixels"
        :key="pixel.id"
        :url="pixel.url"
    ></component>
</template>

