import type { RouterConfig } from "@nuxt/schema";
import type { RouteRecordRaw } from "vue-router";
import configData from "~/static/config.json";
import { defu } from "defu";

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => {
    const newRoutes: RouteRecordRaw[] = configData.pages.map((page) => {
      return {
        name: page.name ?? page.path,
        path: page.path,
        component: () => import(/* @vite-ignore */ "~/pages/[...all]" + ".vue").then((r) => r.default || r)
      };
    });

    // Add all manually added pages to the router (sso-auth, email-token-confirm, possibly more in the future)
    _routes.forEach(_route => {
      if (_route.name === 'all') return;

      newRoutes.push(defu({ meta: { IGNORE_ROUTE_GUARD: true } }, _route))
    })

    return newRoutes;
  },
};
