<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';
import { Dropdown } from 'floating-vue';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue';
import type { SelectEmoji } from 'vue3-emoji-picker';
import { useChatStore, useModalStore, useSiteConfigStore } from '../../stores';
import { type HorizonComponentProps, EModal, type chatProfileData } from '../../types';
import {
  dateToAge,
  getEmptyProfileMessages,
  unixToChatDate,
  unixToDateTimeFormat
} from '../../utils';
import { getFlirtDataFromText } from '../../utils/flirt';

interface Props {
  user: chatProfileData | undefined;
  shouldScrollDownOnMessage: boolean;
  mobile?: boolean;
  tab: string;
  noScrollOffset: number;
}

// Temporary, should be 75, but i lowered it to 1 for the demo
const MINIMAL_AMOUNT_OF_CHARACTERS = process.env.NODE_ENV === 'development' ? 1 : 75;
const props = defineProps<HorizonComponentProps<Props>>();
const siteConfigStore = useSiteConfigStore();
const chatStore = useChatStore();
const { profiles } = storeToRefs(chatStore);
const pageProfiles = computed<chatProfileData[]>(() =>
  profiles.value.filter(
    (storeProfile: chatProfileData) =>
      storeProfile.conversation === (props.data.tab === 'chat' ? true : false)
  )
);
const modalStore = useModalStore();

const emit = defineEmits(['clickedBack', 'pressSend']);

const { user, tab } = toRefs(props.data);
const imageRefresher = ref<number>(0);
const isMobile = props.data.mobile ?? false;
const canSendFile = computed(() => siteConfigStore.siteSettings?.chatAllowFileUploads ?? false);
const cover = computed(() =>
  user?.value?.card.profile_id === 'none' ? 'object-contain' : 'object-cover'
);
const chatTextarea = ref<string>('');
const chatFile = ref<File | undefined>(undefined);
const canSendChatMessage = computed(() => {
  return chatTextarea.value.length >= MINIMAL_AMOUNT_OF_CHARACTERS || !!chatFile.value;
});
const { height } = useWindowSize();
const maxBoxHeight = ref<number>(height.value);

watch(height, () => {
  getMaxBoxHeight();
});

function getMaxBoxHeight() {
  if (!user.value) return 0;
  if (!document) return 0;
  setTimeout(() => {
    const boxheaderHeight = document.getElementById('boxHeader')?.scrollHeight ?? 0;
    const textboxHeight = document.getElementById('textBox')?.scrollHeight ?? 0;
    const pageHeaderHeight = document.getElementById('pageHeader')?.scrollHeight ?? 0;
    const maxheight =
      height.value - boxheaderHeight - textboxHeight - pageHeaderHeight - props.data.noScrollOffset; //same offset as chatPage
    maxBoxHeight.value = maxheight;
  });
}

function chatFileChange(e: Event) {
  const files = (e.target as HTMLInputElement).files;
  if (!files?.length) return;
  chatFile.value = files[0];
}

// Temp: func will be refactored when https://harlemnext.atlassian.net/browse/HIL-371 is implemented
function getFlirtEmojiFromText(text: string) {
  const emoji = getFlirtDataFromText(text).emoji;
  return emoji;
}

function scrollToBottom() {
  if (!user.value) return;
  if (!document) return;

  setTimeout(() => {
    const element = document.getElementById('messageBox');
    element?.scrollTo(0, element.scrollHeight);
  });
}
async function submitChatMessage(e: Event) {
  e.preventDefault();
  if (user.value === undefined || !user.value.card.profile_id) {
    return;
  }
  const response = await chatStore.sendMessage(
    user.value.chatID,
    user.value.card.profile_id,
    chatTextarea.value,
    chatFile.value
  );

  if (response?.error?.data?.error === 'user has insufficient credits to deduct') {
    modalStore.showModalComponent(EModal.insufficientCreditsModalComponent);
    return;
  }

  if (tab.value === 'mail') {
    emit('pressSend');
    chatStore.resetListeners();
  }

  // temporary for DEMO purposses
  if (chatTextarea.value.startsWith('dit is') || chatTextarea.value.startsWith('Dit is')) {
    dutchReply(chatTextarea.value);
  }
  if (chatTextarea.value.startsWith('this is') || chatTextarea.value.startsWith('This is')) {
    englishReply(chatTextarea.value);
  }
  chatFile.value = undefined;
  chatTextarea.value = '';
}

function dutchReply(text: string) {
  const originalText = text;
  const messageContent = originalText.split('een');
  let replyContent = messageContent[0] + 'een ';
  if (originalText.split(' ').includes('vraag')) {
    replyContent += 'antwoord op je ';
  } else {
    replyContent += 'reactie op je ';
  }
  for (const messagePart of messageContent[1].split(' ')) {
    if (messagePart === 'je') {
      replyContent += 'mijn';
      replyContent += ' ';
    } else {
      replyContent += messagePart;
      replyContent += ' ';
    }
  }
  setTimeout(
    async () => {
      if (user.value === undefined) {
        return;
      }
      await chatStore.sendOther(user.value.chatID, replyContent);
    },
    Math.random() * 2000 + 1000
  );
}
function aOrAn(text: string) {
  return ' ' + text.split(' ')[2] + ' ';
}

function englishReply(text: string) {
  const originalText = text;
  const split = aOrAn(text);
  const messageContent = originalText.split(split);
  let replyContent = messageContent[0];
  if (originalText.split(' ').includes('question')) {
    replyContent += ' an answer to your ';
  } else {
    replyContent += ' a reaction to your ';
  }
  for (const messagePart of messageContent[1].split(' ')) {
    if (messagePart === 'your') {
      replyContent += 'my';
      replyContent += ' ';
    } else {
      replyContent += messagePart;
      replyContent += ' ';
    }
  }
  setTimeout(
    async () => {
      if (user.value === undefined) {
        return;
      }
      await chatStore.sendOther(user.value.chatID, replyContent);
    },
    Math.random() * 2000 + 1000
  );
}

watch(
  () => user.value?.messages,
  () => {
    if (props.data.shouldScrollDownOnMessage) {
      scrollToBottom();
    }
  },
  { deep: true }
);

const textArea$ = ref<HTMLTextAreaElement>();
function changeTextareaHeight() {
  const element = textArea$.value;
  if (!element) return;

  element.style.height = 'auto';
  element.style.height = element.scrollHeight + 'px';
}

function onSelectEmoji(emoji: SelectEmoji) {
  chatTextarea.value += emoji.i;
}

await nextTick();
scrollToBottom();

const backupImgSrc = ref<string | undefined>();
const counter = ref<number>(0);
const interval = ref<ReturnType<typeof setInterval>>();

function noChatMessages(msgDelay: number = 1200) {
  const messages: number = getEmptyProfileMessages().length;
  interval.value = setInterval(() => {
    if (counter.value === messages) {
      clearInterval(interval.value);
      interval.value = undefined;
    } else {
      user.value?.messages?.push(
        getEmptyProfileMessages(pageProfiles.value.length < 1)[counter.value]
      );
      counter.value++;
    }
  }, msgDelay);
}

function clearMessages() {
  if (interval.value) {
    clearInterval(interval.value);
  }
}
// TODO: update once media api has a get image endpoint for FE
function getImage(_attachment: string) {
  // const image = await chatStore.getMessageAttachment(attachment)
  return 'TODO: get image via chat api'; // TODO: https://harlemnext.atlassian.net/browse/HIL-142
}

onMounted(async () => {
  getMaxBoxHeight();
  if (user.value?.card?.image?.url === '') {
    const id = user.value.card.profile_id;
    const img = await chatStore.getProfileImage(id, true);
    backupImgSrc.value = img?.url;
    imageRefresher.value++;
  }
  if (user.value?.card.profile_id === 'none') {
    noChatMessages();
  } else {
    clearMessages();
  }
});
onBeforeUnmount(() => {
  clearMessages();
});
</script>

<template>
  <div class="hidden-scrollbar flex size-full flex-col border-r border-[#868686]">
    <div class="flex w-full flex-col border-x border-[#868686] md:border-x-0">
      <div v-if="user" class="flex w-full flex-row border-b border-[#868686]">
        <button
          v-if="isMobile"
          class="inline-flex flex-row items-center text-4xl font-bold"
          @click="emit('clickedBack')"
          :data-testid="props.testId + '/BackButton'"
        >
          <Icon name="ic:chevron-left" class="text-primary" size="40" />
        </button>
        <div id="boxHeader" class="h-22 flex min-w-full">
          <NuxtLink
            :to="user.card.profile_id === 'none' ? '/members' : `/profile/${user.card.profile_id}`"
            :data-testid="props.testId + '/ProfileImageButton'"
          >
            <div class="flex flex-row items-center gap-3 pb-2 pl-3 pt-2">
              <img
                v-if="user?.card?.image?.url != ''"
                class="size-14 rounded-full"
                :class="cover"
                :src="user.card.image.url"
                :alt="user.card.profile_id"
              />
              <img
                v-else-if="backupImgSrc"
                :key="imageRefresher"
                class="size-14 rounded-full"
                :class="cover"
                :src="backupImgSrc"
                :alt="user.card.name"
              />
              <div>
                <h1
                  :class="{
                    'text-xl': isMobile === true
                  }"
                  class="text-lg font-bold"
                >
                  {{ user.card.name }}
                </h1>
                <p v-if="user.card.date_of_birth" class="text-header-text/60 text-xs">
                  {{ dateToAge(user.card.date_of_birth) }} Years old -
                </p>
              </div>
            </div>
          </NuxtLink>
          <NuxtLink
            v-if="!isMobile"
            :to="user.card.profile_id != 'none' ? `/profile/${user.card.profile_id}` : '/members'"
            class="bg-btn-secondary-bg hover:bg-btn-secondary-hover-bg my-auto ml-auto mr-4 flex h-9 w-32 rounded-lg"
            :data-testid="props.testId + '/Profile|MembersButton'"
          >
            <p class="text-header-text mx-auto my-auto">
              View{{ user.card.profile_id != 'none' ? ' Profile' : ' Members' }}
            </p></NuxtLink
          >
        </div>
      </div>
      <ul
        v-if="user"
        id="messageBox"
        ref="div"
        class="hidden-scrollbar flex grow-0 flex-col items-start gap-4 px-4 py-4"
        :style="`max-height: ${maxBoxHeight}px;`"
      >
        <li
          v-for="message of user.messages"
          :key="message?.id"
          :class="{
            'self-start': message?.senderType === 'agent',
            'self-end': message?.senderType === 'customer'
          }"
          class="max-w-9/10"
        >
          <div v-if="message" class="flex flex-col">
            <VTooltip>
              <div class="flex">
                <p
                  v-if="message.senderType === 'customer'"
                  class="text-header-text mr-auto text-sm"
                >
                  You&nbsp;
                </p>
                <time
                  v-if="message.createdAt"
                  class="text-header-text/60 ml-auto text-sm"
                  :datetime="unixToChatDate(message.createdAt, true)"
                >
                  {{ unixToChatDate(message.createdAt, true) }}
                </time>
              </div>
              <template #popper>
                {{ unixToDateTimeFormat(user.messages?.at(-1)?.createdAt ?? user.createdAt, true) }}
              </template>
            </VTooltip>
            <article
              :class="{
                'ml-auto bg-gray-200': message.senderType === 'agent',
                'bg-primary': message.senderType === 'customer'
              }"
              class="flex flex-col text-wrap rounded-lg p-3"
            >
              <img
                v-if="message.type === 'flirt' && message.content"
                class="mx-auto mb-1 h-auto max-h-44 w-auto max-w-fit"
                :src="getFlirtEmojiFromText(message.content)"
                alt=""
              />

              <img
                v-else-if="message.attachment"
                class="mb-1 size-auto max-h-44 max-w-fit"
                :src="getImage(message.attachment)"
                alt="image could not be retrieved"
              />

              <p
                :class="{
                  'text-gray-800': message.senderType === 'agent',
                  'text-white': message.senderType === 'customer'
                }"
                class="whitespace-normal break-words"
              >
                {{ message.content }}
              </p>
            </article>
          </div>
        </li>
      </ul>
    </div>
    <div class="flex-1 border-x border-[#868686] md:border-x-0"></div>
    <form
      id="textBox"
      class="mt-auto flex h-16 items-center bg-[#333741]"
      @submit="submitChatMessage($event)"
    >
      <div class="send-message-msg rounded-lg">
        <button
          type="button"
          class="text-header-text hover:text-link-hover relative me-2 ms-2 mt-1 grid size-10 min-w-[2.5rem] place-items-center"
          :data-testid="props.testId + '/AttachmentButton'"
        >
          <input
            class="send-message-file-input"
            name="attachment"
            type="file"
            @change="chatFileChange($event)"
          />
          <Icon
            v-if="canSendFile"
            :name="
              canSendFile
                ? chatFile
                  ? 'mdi:camera'
                  : 'mdi:camera-outline'
                : 'mdi:camera-odd-outline'
            "
            size="30"
            class="text-inherit"
          />
        </button>
        <button class="relative me-2" type="button" :data-testid="props.testId + '/EmojiButton'">
          <ClientOnly>
            <Dropdown>
              <div>
                <Icon
                  name="mdi:smiley-happy-outline"
                  size="30"
                  class="text-header-text hover:text-link-hover"
                />
              </div>

              <template #popper>
                <LazyChatBoxEmojiPicker
                  :test-id="props.testId"
                  :data="{}"
                  @on-select-emoji="onSelectEmoji($event)"
                />
              </template>
            </Dropdown>
          </ClientOnly>
        </button>
        <textarea
          id="chatarea"
          ref="textArea$"
          v-model="chatTextarea"
          class="bg-background text-header-text mb-2 me-2 max-h-11 w-full resize-none overflow-y-hidden rounded-md p-2 text-base placeholder-gray-400 md:mb-0"
          name="chatarea"
          placeholder="Write a message"
          :data-testid="props.testId + '/TextArea'"
          @input="changeTextareaHeight()"
        ></textarea>
      </div>
      <VTooltip>
        <button
          :disabled="!canSendChatMessage || user?.card.profile_id === 'none'"
          class="text-header-text ml-2 size-10 rounded-full md:me-4"
          type="submit"
          :data-testid="props.testId + '/SendButton'"
        >
          <Icon
            name="fa6-solid:paper-plane"
            class="mb-2 mr-4 text-gray-300 md:mb-0 md:mr-0"
            size="24"
          />
        </button>
        <template #popper>
          {{
            user?.card.profile_id === 'none'
              ? "Sorry! You can't send messages to " + user?.card.name
              : 'Minimum amount of characters: ' +
                chatTextarea.length +
                '/' +
                MINIMAL_AMOUNT_OF_CHARACTERS
          }}
        </template>
      </VTooltip>
    </form>
  </div>
</template>

<style scoped lang="postcss">
button:disabled {
  @apply cursor-not-allowed opacity-50;
}

.send-message-msg {
  @apply relative flex flex-1 items-center justify-center bg-[#333741];

  .send-message-file-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    opacity: 0;
    margin: 0.5em 0.5em 0.5em 0;
  }
}

form {
  box-shadow: 3px -5px 10px rgba(0, 0, 0, 0.2);
}
</style>

