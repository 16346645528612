<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { createUseFulfillmentFetchClient } from '../../../composables/apiClients';
import { fetchLegalVariables } from '../../../composables/legalTextFetch';
import { useModalStore, useSiteConfigStore } from '../../../stores';
import { usePaymentStore } from '../../../stores/payment.store';
import type { HorizonComponentProps, LegalVariables } from '../../../types';
import type { PaymentMethod, Product } from '../../../types/fulfillment';
import type { Props } from './Modernfling.schema';
import { useDebounceFn } from '@vueuse/core';
import { navigateTo } from '#app';

const props = defineProps<HorizonComponentProps<Props>>();
const modalStore = useModalStore();
const siteConfigStore = useSiteConfigStore();
const { localeCode, companyId } = storeToRefs(siteConfigStore);
const tenant_id = siteConfigStore.siteSettings?.tenantId ?? '';
const apiClient = createUseFulfillmentFetchClient();

const clickedHelp = ref<boolean>(false);

const paymentStore = usePaymentStore();
const { paymentError } = storeToRefs(paymentStore);
const disablePayment = ref<boolean>(false);

const { data } = await apiClient('/client/tenants/{tenant_id}/products', {
  method: 'GET',
  path: {
    tenant_id
  }
});

const paymentMethods = ref<PaymentMethod[]>();
// for use with payment gateway
const products = ref<Product[] | undefined>(data.value?.products);
// for use with mock products
// paymentMethods.value = ['CreditCard', 'GooglePay']; // for DEMO purposes
// const products = ref<Product[]>(await useGetMockProducts('ENG')); // for DEMO purposes
const selectedProduct = ref<Product>();
const lastSelectedProduct = ref<Product>({
  id: '',
  price: 0,
  recurring_payment_interval: '',
  reference_id: 0,
  reward_quantity: 0,
  reward_type: '',
  type: ''
});

const selectProduct = useDebounceFn(async (id: string) => {
  if (!products.value || products.value.length < 1) return;

  await fetchPaymentMethods(); // comment this line if mockProducts are used

  products.value.forEach((bundle) => {
    if (bundle.id === id) {
      bundle.selected = true;
      selectedProduct.value = bundle;
      lastSelectedProduct.value = bundle;
    } else {
      bundle.selected = false;
    }
  });
}, 200);

const selectPayment = useDebounceFn(async (method: PaymentMethod, selectedProduct: Product) => {
  if (disablePayment.value) return;
  disablePayment.value = true;

  const checkoutUrl = await paymentStore.createOrder(method, selectedProduct.id);
  if (checkoutUrl) {
    await navigateTo(checkoutUrl, { external: true });
  } else {
    disablePayment.value = false;
  }
}, 200);

function unselect() {
  if (!products.value || products.value.length < 1) return;
  products.value.forEach((bundle) => {
    bundle.selected = false;
  });
  selectedProduct.value = undefined;
}

async function fetchPaymentMethods() {
  const { data, error } = await apiClient('/client/orders/payment-methods', {
    method: 'GET'
  });

  if (error.value) {
    paymentError.value = error.value.data?.error ?? 'An error occurred';
    return;
  }

  paymentMethods.value = data.value?.methods ?? [];
}
const companyData = ref<LegalVariables>();
const billingSupport = ref<string>();
onMounted(async () => {
  companyData.value = await fetchLegalVariables(
    localeCode.value,
    'companyInformation',
    companyId.value
  );

  billingSupport.value =
    siteConfigStore.siteSettings?.billingSupport ??
    'billing@' + siteConfigStore.siteSettings?.domainName;
});
</script>

<template>
  <div
    class="bg-modal-bg md:!w-modal-lg absolute m-5 flex max-h-[90vh] w-full flex-col overflow-auto rounded-2xl p-4"
  >
    <div
      class="border-header-text/20 absolute right-2 top-2 flex h-10 w-10 cursor-pointer place-content-center place-items-center rounded-md border"
    >
      <Icon
        name="ic:sharp-close"
        size="25"
        class="text-header-text hover:text-primary"
        :data-testid="props.testId + '/CloseButton'"
        @click="modalStore.clearModal()"
      />
    </div>
    <Transition name="selectedbundle">
      <div
        v-show="selectedProduct"
        class="selectedbundleclass mb-4 mt-8 flex h-36 w-full flex-col text-start"
      >
        <h1
          class="text-modal-text text-sm font-bold hover:cursor-pointer"
          :data-testid="props.testId + '/PrevStepButton'"
          @click="unselect()"
        >
          <Icon name="formkit:arrowleft" size="24" />
          Back to Bundle Selection
        </h1>
        <div>
          <h1 class="text-modal-text text-center text-base font-bold">Selected bundle:</h1>
          <div class="bg-modal-backdrop/30 my-6 mb-4 w-full rounded-3xl border">
            <div class="content flex pb-2 font-semibold sm:font-bold">
              <div class="flex w-1/3 flex-col items-center py-2">
                <span
                  :class="{
                    'text-modal-text whitespace-pre': lastSelectedProduct.discount === ''
                  }"
                  class="text-modal-text text-lg font-bold"
                  >&nbsp; {{ lastSelectedProduct.discount }} &nbsp;</span
                >
                <span class="text-modal-text/40 text-center text-xs sm:text-sm">
                  {{
                    '$' +
                    (lastSelectedProduct.price / lastSelectedProduct.reward_quantity).toFixed(2)
                  }}&nbsp;/
                  {{ lastSelectedProduct.reward_type }}
                </span>
              </div>

              <div class="flex w-1/3 flex-col items-center justify-center">
                <span class="text-modal-text text-lg">{{
                  lastSelectedProduct.reward_quantity
                }}</span>
                <span class="text-modal-text/40 text-xs sm:text-sm">
                  {{ lastSelectedProduct.reward_type }}
                </span>
              </div>

              <div class="flex w-1/3 flex-col items-center justify-center">
                <span class="text-modal-text text-lg">{{ '$' + lastSelectedProduct.price }}</span>
                <span class="text-modal-text/40 text-xs sm:text-sm"> Total price </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 w-full border border-[#333741]"></div>
      </div>
    </Transition>
    <div>
      <div
        :class="{
          'translate-y-4 duration-500': selectedProduct
        }"
      >
        <h1 class="text-modal-text mb-1 mt-5 text-center">
          {{ props.data?.title ?? 'No subscriptions.' }}
        </h1>
        <h1 class="text-primary text-center text-lg font-bold">
          {{ 'This is NOT a subscription. Your credit card will NEVER be re-billed. ' }}
        </h1>
      </div>
      <div class="mb-3 mt-8 border border-[#333741]"></div>
      <div class="mb-10">
        <Transition name="products">
          <div v-show="!selectedProduct" class="productsclass hidden-scrollbar">
            <div v-if="products && products.length" class="my-1">
              <h1 class="text-modal-text text-center text-base font-bold">Step 1:</h1>
              <h1 class="text-modal-text/40 text-center text-base">Select a package</h1>
            </div>
            <div v-if="!products || !products.length">
              <p class="mb-3 text-center text-gray-500">No products</p>
            </div>
            <div
              v-for="product of products"
              v-show="!selectedProduct"
              :key="product.id"
              class="hover:bg-modal-backdrop group my-6 mb-4 w-full cursor-pointer rounded-3xl border"
              :class="{
                'border-[#A48AFB]': product.name === 'Discounted Offer!',
                'border-primary': product.name != undefined && product.name != 'Discounted Offer!',
                'border-modal-text/20': product.name === undefined
              }"
              :data-testid="props.testId + '/SelectProductButton'"
              @click="selectProduct(product.id)"
            >
              <div
                class="text-modal-text relative mx-auto w-1/4 -translate-y-4 rounded-full border text-center"
                :class="{
                  'border-[#A48AFB] bg-[#5720B7]': product.name === 'Discounted Offer!',
                  'border-primary bg-[#5a0b21]': product.name != 'Discounted Offer!',
                  invisible: product.name === undefined
                }"
              >
                {{ product.name ?? 'placeholder' }}
              </div>
              <div
                :class="{
                  'font-semibold sm:font-bold': product.selected,
                  'bg-grey-dark white': !product.selected
                }"
                class="content flex pb-2"
              >
                <div class="flex w-1/3 flex-col items-center pb-2">
                  <span
                    :class="{
                      'text-modal-text whitespace-pre': product.discount === ''
                    }"
                    class="text-modal-text text-lg font-bold"
                    >&nbsp; {{ product.discount }} &nbsp;</span
                  >
                  <span class="text-modal-text/40 text-center text-xs sm:text-sm">
                    {{ '$' + (product.price / product.reward_quantity).toFixed(2) }}&nbsp;/
                    {{ product.reward_type }}
                  </span>
                </div>

                <div class="flex w-1/3 flex-col items-center justify-center">
                  <span class="text-modal-text text-lg">{{ product.reward_quantity }}</span>
                  <span class="text-modal-text/40 text-xs sm:text-sm">
                    {{ product.reward_type }}
                  </span>
                </div>

                <div class="flex w-1/3 items-center justify-end">
                  <div
                    class="bg-modal-backdrop/40 border-modal-backdrop text-modal-text bundleButton mr-8 w-36 items-center rounded border px-5 py-2 text-center text-sm font-semibold"
                    :class="{
                      'group-hover:bg-[#A48AFB]': product.name === 'Discounted Offer!',
                      'group-hover:bg-primary': product.name != 'Discounted Offer!'
                    }"
                  >
                    {{ '$' + product.price.toFixed(2)
                    }}<Icon name="ph:arrow-right-bold" size="20" class="mb-1 ml-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div v-if="selectedProduct">
          <div class="mb-3">
            <h1 class="text-modal-text text-center text-base font-bold">Step 2:</h1>
            <h1 class="text-modal-text/40 text-center text-base">Select your payment method.</h1>
          </div>
          <div
            v-for="method of paymentMethods"
            :key="method"
            class="bg-modal-bg text-buttonText shadow-glow hover:bg-modal-backdrop border-modal-text/20 group mb-2 flex w-full cursor-pointer flex-row items-center rounded-xl border px-5 py-3 text-sm font-bold"
            :data-testid="props.testId + '/SelectPaymentButton'"
            @click="selectPayment(method, selectedProduct)"
          >
            <img :src="`images/PaymentMethods/${method}.png`" alt="" class="mr-2" />
            <div class="text-modal-text flex w-full justify-start text-start">{{ method }}</div>
            <div
              class="group-hover:bg-primary bg-modal-backdrop/40 border-modal-backdrop text-modal-text bundleButton flex w-1/2 items-center rounded border px-5 py-2 text-sm font-semibold"
            >
              <p class="text-modal-text group-hover:bg-primary mx-auto flex text-nowrap">
                BUY NOW
                <Icon name="ph:arrow-right-bold" size="20" class="text-modal-text my-auto" />
              </p>
            </div>
          </div>
          <div v-if="!paymentMethods">
            <p class="mb-3 text-center text-gray-500">
              Sorry, there are currently no payment methods available.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="paymentError">
      <p class="text-center text-red-500">{{ paymentError }}</p>
    </div>
    <ul class="relative bottom-1 left-0 hidden w-full flex-row justify-center gap-4 lg:flex">
      <li>
        <button
          :class="{
            'opacity-50': selectedProduct
          }"
          class="h-4 w-4 rounded-full bg-white hover:cursor-default"
        ></button>
      </li>
      <li>
        <button
          :class="{
            'opacity-50': !selectedProduct
          }"
          class="h-4 w-4 rounded-full bg-white hover:cursor-default"
        ></button>
      </li>
    </ul>
    <div v-if="selectedProduct" class="text-footer-text mt-4 text-center">
      <p><strong class="text-modal-text text-lg">Privacy First</strong><br /></p>
      <p class="text-footer-text mt-4">
        We care about your private life and respect your privacy. Any charges made on your credit
        card will appear under:
      </p>
      <br />

      <p class="text-modal-text font-bold">
        <Icon name="fa6-solid:heart-circle-check" size="25" class="text-primary pb-1" />
        &nbsp;"{{ companyData?.company.name + ' ' + companyData?.company.telInfo }}"
      </p>
      <br />
      <p class="text-sm text-inherit">
        By clicking "Buy Now" you agree with our
        <NuxtLink
          to="/agreement"
          class="underline"
          :data-testid="props.testId + '/Terms&ConditionsLink'"
          >Terms & Conditions</NuxtLink
        >
      </p>
    </div>
    <div class="mt-2 flex flex-col pb-4">
      <VTooltip class="mx-auto">
        <button
          aria-label="need help"
          class="text-modal-text bg-primary hover:bg-btn-primary-hover-bg relative top-3 z-10 mx-auto h-12 w-12 rounded-full"
          :data-testid="props.testId + '/HelpButton'"
          @click="clickedHelp = !clickedHelp"
        >
          <Icon name="fa6-regular:circle-question" size="24" />
        </button>
        <template #popper>
          {{ 'Need help?' }}
        </template>
      </VTooltip>
      <Transition name="helpbutton">
        <div v-if="clickedHelp" class="flex flex-col rounded-lg bg-[#1b2641] py-4 text-center">
          <h1 class="text-modal-text text-lg">Need Help?</h1>
          <p class="text-modal-text/50">Reach out to our friendly team:</p>
          <a
            href="mailto:"
            class="text-modal-text/50 underline"
            :data-testid="props.testId + '/MailtoLink'"
            >{{ billingSupport }}</a
          >
        </div>
      </Transition>
    </div>
  </div>
</template>

<style lang="css">
.helpbutton-enter-active,
.helpbutton-leave-active {
  transition: opacity 0.5s ease;
}

.helpbutton-enter-from,
.helpbutton-leave-to {
  opacity: 0;
}

.products-enter-from {
  opacity: 0;
  translate: 0 100%;
}
.products-enter-to {
  opacity: 1;
  translate: 0 0;
}
.products-leave-from {
  opacity: 1;
  translate: 0 0;
}
.products-leave-to {
  opacity: 0;
  translate: 0 100%;
}
.productsclass {
  transition: all 0.5s;
}

.selectedbundle-enter-from {
  opacity: 0;
  translate: 0 -100%;
}
.selectedbundle-enter-to {
  opacity: 1;
  translate: 0 0;
}
.selectedbundle-leave-from {
  opacity: 1;
  translate: 0 0;
}
.selectedbundle-leave-to {
  opacity: 0;
  translate: 0 -100%;
}
.selectedbundleclass {
  transition: all 0.5s;
}
</style>

