import { default as _91_46_46_46all_93qRTrI8DaFoMeta } from "/pangaea/apps/horizon/horizon-ui-site/pages/[...all].vue?macro=true";
import { default as email_45token_45confirmPIfG53betsMeta } from "/pangaea/apps/horizon/horizon-ui-site/pages/email-token-confirm.vue?macro=true";
import { default as pixel_45testVO4cRAhkFzMeta } from "/pangaea/apps/horizon/horizon-ui-site/pages/pixel-test.vue?macro=true";
import { default as reset_45passwordRUA3q2sDJCMeta } from "/pangaea/apps/horizon/horizon-ui-site/pages/reset-password.vue?macro=true";
import { default as sso_45authNc9gsn3I54Meta } from "/pangaea/apps/horizon/horizon-ui-site/pages/sso-auth.vue?macro=true";
export default [
  {
    name: "all___en_US",
    path: "/:all(.*)*",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "all___nl_NL",
    path: "/nl_NL/:all(.*)*",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "email-token-confirm___en_US",
    path: "/email-token-confirm",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/email-token-confirm.vue").then(m => m.default || m)
  },
  {
    name: "email-token-confirm___nl_NL",
    path: "/nl_NL/email-token-confirm",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/email-token-confirm.vue").then(m => m.default || m)
  },
  {
    name: "pixel-test___en_US",
    path: "/pixel-test",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/pixel-test.vue").then(m => m.default || m)
  },
  {
    name: "pixel-test___nl_NL",
    path: "/nl_NL/pixel-test",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/pixel-test.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en_US",
    path: "/reset-password",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___nl_NL",
    path: "/nl_NL/reset-password",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "sso-auth___en_US",
    path: "/sso-auth",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/sso-auth.vue").then(m => m.default || m)
  },
  {
    name: "sso-auth___nl_NL",
    path: "/nl_NL/sso-auth",
    component: () => import("/pangaea/apps/horizon/horizon-ui-site/pages/sso-auth.vue").then(m => m.default || m)
  }
]