<script setup lang="ts">
import { onBeforeRouteLeave, useRoute } from '#vue-router';
import { computed, ref, watch, onMounted } from 'vue';
import { PaymentStatus, paymentStatusValues } from '../../types/fulfillment';
import {
  createCreditGetClient,
  createUseFulfillmentFetchClient
} from '../../composables/apiClients';
import { navigateTo, useCookie } from '#app';
import { useChatStore } from '../../stores';
//TODO: text should be translated instead of hardcoded
const route = useRoute();
const status = route.query.status;
const paymentStatus = ref(paymentStatusValues.find((item) => item === status));
const orderId = useCookie('orderId');
const profileId = useCookie('profileId');
const creditsAdded = ref(false);

if (!orderId.value) {
  await navigateTo('/', { redirectCode: 301 });
}

const chatStore = useChatStore();

const apiClientFulfillment = createUseFulfillmentFetchClient();
const apiClientCredit = createCreditGetClient();

const { data: order } = await apiClientFulfillment('/client/orders/{order_id}/status', {
  path: { order_id: orderId.value! },
  method: 'GET'
});

const { data: creditData } = await apiClientCredit('/users', {
  method: 'GET'
});

let intervalCredit: NodeJS.Timeout;
let intervalFulfillment: NodeJS.Timeout;

onMounted(() => {
  if (paymentStatus.value === PaymentStatus.Pending) {
    intervalFulfillment = setInterval(() => {
      if (!orderId.value) return;
      apiClientFulfillment('/client/orders/{order_id}/status', {
        path: { order_id: orderId.value },
        method: 'GET'
      });
    }, 1000);
  } else if (paymentStatus.value === PaymentStatus.Successful) {
    intervalCredit = setInterval(() => {
      apiClientCredit('/users', {
        method: 'GET'
      });
    }, 1000);
  }
});

onBeforeRouteLeave(() => {
  orderId.value = undefined;
  profileId.value = undefined;
  clearInterval(intervalCredit);
  clearInterval(intervalFulfillment);
});

watch(order, (value) => {
  if (!orderId.value) return;
  if (value?.status === PaymentStatus.Successful) {
    clearInterval(intervalFulfillment);
    paymentStatus.value = PaymentStatus.Successful;

    intervalCredit = setInterval(() => {
      apiClientCredit('/users', {
        method: 'GET'
      });
    }, 1000);
  } else if (value?.status === PaymentStatus.Failed) {
    clearInterval(intervalFulfillment);
    paymentStatus.value = PaymentStatus.Failed;
  }
});

watch(creditData, (value) => {
  if (value && orderId.value && value.orders.includes(orderId.value)) {
    creditsAdded.value = true;
    clearInterval(intervalCredit);
    chatStore.creditCount = value.current_credits;
    const redirectUrl = profileId.value ? `/message/${profileId.value}` : '/chat';

    setTimeout(() => {
      navigateTo(redirectUrl, { redirectCode: 301 });
    }, 5000);
  }
});

const information = computed<{
  title: string;
  description: string;
  success: boolean;
}>(() => {
  switch (paymentStatus.value) {
    case PaymentStatus.Successful:
      return {
        title: 'Thank you for your purchase!',
        description: 'Your credits have been added to your account',
        success: true
      };
    case PaymentStatus.Failed:
      return {
        title: 'Purchase failed',
        description: 'Something went wrong with your purchase.',
        success: false
      };
    case PaymentStatus.Pending:
      return {
        title: 'Purchase pending',
        description: 'Your purchase is pending.',
        success: true
      };
    default:
      return {
        title: 'Status unknown',
        description: 'Something went wrong; status unknown',
        success: false
      };
  }
});
</script>

<template>
  <div v-if="orderId && paymentStatus" class="mt-20 text-center leading-5">
    <h1 class="text-header-text mb-4 text-2xl">{{ information.title }}</h1>
    <p v-if="creditsAdded || !information.success" class="text-text">
      {{ information.description }}
    </p>
    <p v-if="information.success && creditsAdded" class="text-text">You will be redirected back.</p>
    <p v-if="!information.success" class="text-text">
      Please try again or contact customer service.
    </p>

    <template v-if="paymentStatus !== PaymentStatus.Failed && !creditsAdded">
      <Icon name="svg-spinners:ring-resize" class="text-primary mb-4" size="4em" />
      <p v-if="paymentStatus === PaymentStatus.Pending" class="text-text">
        Checking payment status...
      </p>
      <p v-else class="text-text">Adding credits to account...</p>
    </template>
  </div>
</template>

