<script setup lang="ts">
import { useAuthStore } from '../stores';
import { overrideStyles, getTestId } from '../utils';
import type { HorizonComponent, HorizonComponentProps } from '../types';
import { computed, inject, type Component } from 'vue';

interface PropsData {
  authorizedComponent: HorizonComponent;
  unauthorizedComponent: HorizonComponent;
}

const props = defineProps<HorizonComponentProps<PropsData>>();
const authStore = useAuthStore();
const renderedComponent = computed(() =>
  authStore.isAuthorized ? props.data?.authorizedComponent : props.data?.unauthorizedComponent
);
const components = inject<Record<string, Component>>('components');
const testID = props.testId + getTestId(renderedComponent.value);
</script>

<template>
  <component
    :is="components[renderedComponent.name]"
    v-if="components && renderedComponent"
    :data="renderedComponent.data"
    :data-component="renderedComponent.name"
    :children="renderedComponent.children"
    :overrides="renderedComponent.overrides"
    :style="overrideStyles(renderedComponent.overrides)"
    :data-testid="testID"
    :test-id="testID"
  />
</template>

