<script setup lang="ts">
const props = defineProps<{
    url: string;
}>();
</script>

<template>
    <iframe :src="props.url" class="hidden"  frameborder="0"></iframe>
</template>

