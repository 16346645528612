// NOTE: most of these functions are used for the dummy api and are not used in the app
import { EModal, type Mail, type Users } from '../types';

function* random() {
  while (true) {
    yield Math.random();
  }
}

const rand = random();

export function arrayRandom<T>(arr: T[], random: number = Math.random()) {
  return arr[~~(random * arr.length)];
}

export function arrayShuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function randomBool(odds = 0.5) {
  return Math.random() < odds;
}

export function randomValue() {
  return Math.random();
}

export function randomString(length = 10, random: number = Math.random()) {
  return random.toString(36).substring(2, length + 2);
}

export function randomDate(random = Math.random()) {
  const start = new Date(1970, 0, 1);
  const end = new Date(2005, 11, 31);
  const date = new Date(start.getTime() + random * (end.getTime() - start.getTime()));

  return date.toISOString().slice(0, 10);
}

export function randomName(gen: Generator<number> = rand) {
  const firstNames = [
    'John',
    'Jane',
    'Jason',
    'Ryan',
    'Linda',
    'Susan',
    'Karen',
    'Sarah',
    'Emma',
    'Olivia',
    'Ava',
    'Isabella',
    'Sophia',
    'Charlotte',
    'Mia',
    'Amelia',
    'Grace',
    'Ivy'
  ];

  const lastNames = [
    'Doe',
    'Smith',
    'Johnson',
    'Williams',
    'Jones',
    'Brown',
    'Davis',
    'Miller',
    'Wilson',
    'Lee',
    'Green',
    'Turner',
    'Walker'
  ];

  const firstName = arrayRandom(firstNames, gen.next().value);
  const lastName = arrayRandom(lastNames, gen.next().value);

  return {
    fullName: `${firstName} ${lastName}`,
    firstName,
    lastName
  };
}

export function* randomGenerator(seed: number) {
  const a = 1664525;
  const c = 1013904223;
  let x = seed;
  while (true) {
    x = (a * x + c) % 2 ** 32;
    yield x / 2 ** 32;
  }
}
const loremIpsumWords = [
  'Lorem',
  'ipsum',
  'dolor',
  'sit',
  'amet,',
  'consectetur',
  'adipiscing',
  'elit,',
  'sed',
  'do',
  'eiusmod',
  'tempor',
  'incididunt',
  'ut',
  'labore',
  'et',
  'dolore',
  'magna',
  'aliqua.',
  'Ut',
  'enim',
  'ad',
  'minim',
  'veniam,',
  'quis',
  'nostrud',
  'exercitation',
  'ullamco',
  'laboris',
  'nisi',
  'ut',
  'aliquip',
  'ex',
  'ea',
  'commodo',
  'consequat.',
  'Duis',
  'aute',
  'irure',
  'dolor',
  'in',
  'reprehenderit',
  'in',
  'voluptate',
  'velit',
  'esse',
  'cillum',
  'dolore',
  'eu',
  'fugiat',
  'nulla',
  'pariatur.',
  'Excepteur',
  'sint',
  'occaecat',
  'cupidatat',
  'non',
  'proident,',
  'sunt',
  'in',
  'culpa',
  'qui',
  'officia',
  'deserunt',
  'mollit',
  'anim',
  'id',
  'est',
  'laborum.'
];
export function generateLoremIpsum(paragraphs: number) {
  let loremIpsumText = '';

  for (let i = 0; i < paragraphs; i++) {
    const paragraphLength = Math.floor(Math.random() * 5) + 1; // Random length of 1-5 sentences per paragraph

    for (let j = 0; j < paragraphLength; j++) {
      const sentenceLength = Math.floor(Math.random() * 10) + 4; // Random length of 4-13 words per sentence

      for (let k = 0; k < sentenceLength; k++) {
        const wordIndex = Math.floor(Math.random() * loremIpsumWords.length);
        const word = loremIpsumWords[wordIndex];
        loremIpsumText += word + ' ';
      }

      loremIpsumText = loremIpsumText.trim(); // Remove extra whitespace at the end of the sentence
      loremIpsumText += '. '; // Add period and space after the sentence
    }

    loremIpsumText += '\n\n'; // Add new line between paragraphs
  }

  return loremIpsumText.trim(); // Remove extra whitespace at the beginning and end
}

export function generateShortLoremIpsum() {
  const lenghtArray: number[] = [0, 0, 1, 1, 1, 1, 1, 2, 2, 2];
  let loremIpsumText = '';

  const paragraphLength = lenghtArray[Math.floor(Math.random() * 10)]; // Random length of 1 or 2 sentences

  if (paragraphLength === 0) {
    const sentenceLength = Math.floor(Math.random() * 6) + 2; // Random length of 2-7 words per sentence

    for (let k = 0; k < sentenceLength; k++) {
      const wordIndex = Math.floor(Math.random() * loremIpsumWords.length);
      const word = loremIpsumWords[wordIndex];
      loremIpsumText += word + ' ';
    }

    loremIpsumText = loremIpsumText.trim(); // Remove extra whitespace at the end of the sentence
    loremIpsumText += '. '; // Add period and space after the sentence
  } else {
    for (let j = 0; j < paragraphLength; j++) {
      const sentenceLength = Math.floor(Math.random() * 10) + 4; // Random length of 4-13 words per sentence

      for (let k = 0; k < sentenceLength; k++) {
        const wordIndex = Math.floor(Math.random() * loremIpsumWords.length);
        const word = loremIpsumWords[wordIndex];
        loremIpsumText += word + ' ';
      }

      loremIpsumText = loremIpsumText.trim(); // Remove extra whitespace at the end of the sentence
      loremIpsumText += '. '; // Add period and space after the sentence

      if (paragraphLength === 2 && Math.floor(Math.random() * 2) === 1) {
        loremIpsumText += '\n\n'; // Add new line between paragraphs
      }
    }
  }

  return loremIpsumText.trim(); // Remove extra whitespace at the beginning and end
}

export function generateRandomMails(users: Users | null) {
  const mailTypeRatios = ['chat', 'chat', 'chat', 'promotion', 'promotion', 'credit'];
  const amount = Math.floor(Math.random() * 5) + 4;
  let userIDs: string[] = [];
  if (users !== null) {
    userIDs = Object.keys(users);
  }
  const mails: Mail[] = [];
  for (let i = 0; i < amount; i++) {
    if (mails.length === 0) {
      mails.push(randomIntroMail(i));
      continue;
    }
    const mailType = mailTypeRatios[Math.floor(Math.random() * mailTypeRatios.length)];
    if (mailType === 'chat' && userIDs.length > 0) {
      const userID: string | undefined = userIDs.shift();
      if (typeof userID === 'string' && users !== null) {
        const sender: string = users[userID].profile.name;
        mails.push(randomChatMail(i, sender, userID));
      }
    } else if (mailType === 'promotion') {
      mails.push(randomPromoMail(i));
    } else {
      mails.push(randomCreditsMail(i));
      mailTypeRatios.pop();
    }
  }
  return mails;
}
const intromailTitles = [' Welkom op onze website!', ' Wat fijn dat je er bent!'];
function randomIntroMail(ID: number): Mail {
  return {
    id: 'intromail' + String(ID),
    senderID: '-1',
    title: intromailTitles[Math.floor(Math.random() * intromailTitles.length)],
    subtitle: 'We zijn erg blij dat je voor ons hebt gekozen!',
    content: generateLoremIpsum(Math.floor(Math.random() * 3) + 3),
    read: false,
    action: {
      type: 'none'
    }
  };
}
const chatmailTitles = [
  ' vind je leuk!',
  ' heeft je ge-favoriet!',
  ' heeft gematched met jou!',
  ' wilt met je chatten!'
];
const chatmailContent = [
  'Begin vandaag met chatten?',
  'Er wordt op je gewacht! Chat nu!',
  'Laat ze niet hangen! Begin met chatten!',
  'Begin met chatten om ze te leren kennen!',
  'START CHATTING NOW!'
];

function randomChatMail(ID: number, sender: string, userId: string): Mail {
  return {
    id: 'chatmail' + String(ID),
    senderID: userId,
    title: sender + chatmailTitles[Math.floor(Math.random() * chatmailTitles.length)],
    subtitle: chatmailContent[Math.floor(Math.random() * chatmailContent.length)],
    content: generateLoremIpsum(Math.floor(Math.random() * 3)),
    read: Math.random() > 0.7,
    action: {
      type: 'url',
      ref: '/message/' + userId
    }
  };
}

const promomailFilling = [
  ['Er zijn nieuwe profielen!', 'We hebben allemaal nieuwe members! kom kijken en chat er mee!'],
  [
    'We zijn ' + String((Math.floor(Math.random() * 4) + 1) * 5) + ' jaar oud!',
    'Vandaag chat iedereen GRATIS!!!'
  ],
  [
    'Uw account is vandaag ' + String((Math.floor(Math.random() * 4) + 1) * 5) + ' jaar oud!',
    'U krijgt ' + String(Math.floor(Math.random() * 4) + 1) + ' credits van ons cadeau!'
  ]
];
function randomPromoMail(ID: number): Mail {
  if (Math.floor(Math.random() * 7) > 4) {
    const price = Math.floor(Math.random() * 20) / 10;
    return {
      id: 'promomail' + String(ID),
      senderID: '-1',
      title: 'Credits zijn tijdelijk goedkoper!',
      subtitle: 'normaal zijn credits €2.5, maar nu zijn ze €' + String(price),
      content: generateLoremIpsum(Math.floor(Math.random() * 2)),
      read: Math.random() > 0.7,
      action: {
        type: 'modal',
        modal: EModal.insufficientCreditsModalComponent
      }
    };
  } else {
    const filling = promomailFilling[Math.floor(Math.random() * promomailFilling.length)];
    return {
      id: 'promomail' + String(ID),
      senderID: '-1',
      title: filling[0],
      subtitle: filling[1],
      content: generateLoremIpsum(Math.floor(Math.random() * 2)),
      read: Math.random() > 0.7,
      action: {
        type: 'none'
      }
    };
  }
}

function randomCreditsMail(ID: number): Mail {
  return {
    id: 'creditmail' + String(ID),
    senderID: '-1',
    title: 'Je hebt bijna geen credits meer!',
    subtitle: 'Koop snel nieuwe!',
    content: generateLoremIpsum(Math.floor(Math.random() * 1)),
    read: Math.random() > 0.7,
    action: {
      type: 'modal',
      modal: EModal.insufficientCreditsModalComponent
    }
  };
}
