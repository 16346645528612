import { useNuxtApp, useRuntimeConfig } from '#app';
import { createLiteFetchClient, createUseFetchClient } from '@btr/fetch-frog';
import { type Context, type Span, propagation, trace } from '@opentelemetry/api';
import type { FetchOptions } from 'ofetch';
import type { paths as chatPaths } from '../schemas/chat';
import type { paths as collectConsentPaths } from '../schemas/collectconsent';
import type { paths as creditPaths } from '../schemas/credit';
import type { paths as fulFillmentPaths } from '../schemas/fulfillment';
import type { paths as geoPaths } from '../schemas/geo-wizard';
import type { paths as identityPaths } from '../schemas/identity';
import type { paths as profilesPaths } from '../schemas/profiles';
import { useAuthStore } from '../stores/auth.store';
import { getNuxtSpanContext } from '../tracing/tracing';

declare module 'http' {
  interface IncomingMessage {
    spanContexts?: Context[];
    middlewareSpan?: Span;
  }
}

declare module 'ofetch' {
  interface FetchOptions {
    span?: Span;
  }
}

function buildUseFetchClient<T>(baseUrl: string) {
  const authStore = useAuthStore();

  return createUseFetchClient<T>(baseUrl, {
    onRequest: async ({ request, options }) => {
      await createApiCallSpan(request, options);

      const token = await authStore.getUserAccessToken();
      if (token) {
        options.headers = {
          Authorization: 'Bearer ' + token,
          ...options.headers
        };
      }
    }
  });
}

function buildLiteFetchClient<T>(baseUrl: string) {
  const authStore = useAuthStore();

  const client = createLiteFetchClient<T>(baseUrl, {
    onRequest: async ({ request, options }) => {
      await createApiCallSpan(request, options);

      const token = await authStore.getUserAccessToken();
      if (!token) return;

      options.headers = {
        Authorization: 'Bearer ' + token,
        ...options.headers
      };
    }
  });

  return client;
}

async function createApiCallSpan(request: RequestInfo, options: FetchOptions) {
  const $tracer = useNuxtApp().$tracer;
  const spanContext = getNuxtSpanContext();
  if (!$tracer || !spanContext) return;

  const span = $tracer.startSpan(
    `api-client ${new URL(request.toString()).origin}`,
    {
      attributes: {
        'http.url': request.toString(),
        'http.method': options.method ?? 'GET'
      }
    },
    spanContext
  );
  options.span = span;

  const newContext = trace.setSpan(spanContext, span);
  options.headers ??= {};
  propagation.inject(newContext, options.headers);

  options.onRequestError = async ({ options }) => {
    if (options.span) {
      options.span.setAttribute('http.status_code', 408);
      options.span.end();
    }
  };

  options.onResponse = async ({ options, response }) => {
    if (options.span) {
      options.span.setAttribute('http.status_code', response.status);
      options.span.end();
    }
  };
}

export function createUseCCFetchClient() {
  const baseUrl = useRuntimeConfig().public.collectConsentApiBaseUrl;
  return buildUseFetchClient<collectConsentPaths>(baseUrl);
}

export function createCCFetchClient() {
  const baseUrl = useRuntimeConfig().public.collectConsentApiBaseUrl;
  return buildLiteFetchClient<collectConsentPaths>(baseUrl);
}

export function createUseIdentityFetchClient() {
  const baseUrl = useRuntimeConfig().public.identityApiBaseUrl;
  return buildUseFetchClient<identityPaths>(baseUrl);
}

export function createIdentityFetchClient() {
  const baseUrl = useRuntimeConfig().public.identityApiBaseUrl;
  return buildLiteFetchClient<identityPaths>(baseUrl);
}

export function createUseProfilesFetchClient() {
  const baseUrl = useRuntimeConfig().public.profilesApiBaseUrl;
  return buildUseFetchClient<profilesPaths>(baseUrl);
}

export function createProfilesFetchClient() {
  const baseUrl = useRuntimeConfig().public.profilesApiBaseUrl;
  return buildLiteFetchClient<profilesPaths>(baseUrl);
}

export function createUseFulfillmentFetchClient() {
  const baseUrl = useRuntimeConfig().public.fulfillmentApiBaseUrl;
  return buildUseFetchClient<fulFillmentPaths>(baseUrl);
}

export function createCreditPostClient() {
  const baseUrl = useRuntimeConfig().public.creditApiBaseUrl;
  return buildLiteFetchClient<creditPaths>(baseUrl);
}

export function createCreditGetClient() {
  const baseUrl = useRuntimeConfig().public.creditApiBaseUrl;
  return buildUseFetchClient<creditPaths>(baseUrl);
}

export function createGetChatFetchClient() {
  const baseUrl = useRuntimeConfig().public.chatApiBaseUrl;
  return buildLiteFetchClient<chatPaths>(baseUrl);
}

export function createChatFetchClient() {
  const baseUrl = useRuntimeConfig().public.chatApiBaseUrl;
  return buildLiteFetchClient<chatPaths>(baseUrl);
}

export function createGeoFetchClient() {
  const baseUrl = useRuntimeConfig().public.geoApiBaseUrl;
  return buildLiteFetchClient<geoPaths>(baseUrl);
}

