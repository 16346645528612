<script setup lang="ts">
import { useRoute } from 'vue-router';
import GoogleLogo from '../../assets/images/google-logo.svg';
import { hasPartnerParameters, usePartnerTrackingCookie } from '../../utils/partner';
import type { HorizonComponentProps } from '../../types';

interface Props {
  href: string;
  type: 'register' | 'login' | 'custom';
  text?: string;
}

const props = defineProps<HorizonComponentProps<Props>>();
const label = props.data.text + ' Google';

const route = useRoute();

function storePartnerState() {
  if (!hasPartnerParameters(route.query)) {
    return;
  }

  usePartnerTrackingCookie().value = route.query;
}
</script>

<template>
  <a
    :href="props.data.href"
    class="google-button border-header-text/20 rounded-lg"
    :data-testid="props.testId + '/GoogleButton'"
    @click="storePartnerState"
  >
    <div class="inline-grid aspect-square h-full place-items-center">
      <img :src="GoogleLogo" alt="google logo" class="h-5 w-5" />
    </div>
    <strong class="p-3 text-white">
      <template v-if="props.data.type === 'register'">Register with Google</template>
      <template v-else-if="props.data.type === 'login'">Sign in with Google</template>
      <template v-else>{{ label }}</template>
    </strong>
  </a>
</template>

<style scoped lang="postcss">
.google-button {
  @apply flex cursor-pointer items-center;
  height: 42px;
  border-width: 1px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
</style>

