<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { inject, ref, watch, onMounted, computed, type Component } from 'vue';
import { useRoute } from 'vue-router';
import {
  useProfileStore,
  useSiteConfigStore,
  useFiltersStore,
  useGlobalsStore
} from '../../stores';
import type { ProfileCard, HorizonComponentProps } from '../../types';
import {
  overrideStyles,
  emptyCard,
  emptyFilterCard,
  getSkeletonGrid,
  storageKeys,
  storeValue,
  getTestId
} from '../../utils';
import type { ScrollState } from '../ProfileGrid/VirtualInfiniteGrid.vue';
import VirtualInfiniteGrid from '../ProfileGrid/VirtualInfiniteGrid.vue';
import NoResultsNotice from './ModernflingSupport/NoResultsNotice.vue';
import { useWindowSize, watchDebounced, useElementSize } from '@vueuse/core';
import { mapPageNameToRelationalFilter } from '../../utils/filters';

const props = defineProps<HorizonComponentProps>();
const components = inject<Record<string, Component>>('components');

const profileStore = useProfileStore();
const filterStore = useFiltersStore();
await filterStore.initFilterStore();

const siteConfigStore = useSiteConfigStore();
const { profileCardComponent, profileFiltersComponent } = storeToRefs(siteConfigStore);

const globals = useGlobalsStore();

const ITEMS_PER_PAGE = globals.constantGlobals.profileGrid.ITEMS_PER_PAGE;
const gridContainer = ref(null);
const { width } = useElementSize(gridContainer);
const ITEM_WIDTH = ref<number>(globals.constantGlobals.profileGrid.ITEM_WIDTH);

const ITEM_HEIGHT = computed(() => (ITEM_WIDTH.value / 5) * 6);

const MAX_COL_COUNT = globals.constantGlobals.profileGrid.MAX_COL_COUNT;
const pageNumber = ref<number>(0);

const profiles = ref<ProfileCard[]>([]);
const showPopularProfiles = ref<boolean>(false);

const noMoreProfiles = ref<boolean>(false);
const firstFetchEmpty = ref<boolean>(false);
const firstFetch = ref<boolean>(true);

const pageName = String(useRoute().name);
const membersPage = siteConfigStore.pages?.find((page) => page.key === 'members');

const showNotice = ref<boolean>(false);

const { height: pageHeight } = useWindowSize();

const isLoading = ref<boolean>(true);

const skeletonGrid = computed<ProfileCard[]>(() => [
  ...profiles.value,
  ...getSkeletonGrid(ITEMS_PER_PAGE / 2) // full page skeletongrid is a bit large, half page looks nicer
]);

const showProfiles = computed<ProfileCard[]>(() =>
  isLoading.value ? skeletonGrid.value : profiles.value
);

watchDebounced(pageHeight, setForceFooter, { debounce: 100 });

onMounted(() => {
  filterStore.applyFilters(
    filterStore.appliedFilters,
    filterStore.activeFilterKeys,
    mapPageNameToRelationalFilter(pageName)
  );
  if (pageName != membersPage?.name) {
    fetchItems();
  }

  storeValue(storageKeys.profileGrid.prevPage, pageName);
});

async function fetchItems() {
  if (import.meta.server) return;
  if (noMoreProfiles.value) return;
  isLoading.value = true;
  const data = await profileStore.getProfiles(pageNumber.value, ITEMS_PER_PAGE);

  if (data.profiles.length === 0) {
    showPopularProfiles.value = data.noProfiles || false;
  }
  if (!data.noProfiles) {
    showPopularProfiles.value = false;
  }

  if (data.profiles != null)
    profiles.value = [
      ...profiles.value,
      ...data.profiles.filter((x) => !profiles.value.find((y) => y.profile_id === x.profile_id))
    ];

  showNotice.value = profiles.value.length === 0;

  if (data.noProfiles) {
    if (firstFetch.value) {
      firstFetchEmpty.value = true;
      firstFetch.value = false;
    }
  }
  if (data.profiles?.length < ITEMS_PER_PAGE) {
    endOfFetching();
  }

  pageNumber.value++;
  isLoading.value = false;
}

function setForceFooter() {
  if (!document) return;
  setTimeout(() => {
    const footerHeight = document.getElementById('mainFooter')?.scrollHeight ?? 0;
    const filterComponentHeight = document.getElementById('filterComponent')?.scrollHeight ?? 0;
    const pageHeaderHeight = document.getElementById('pageHeader')?.scrollHeight ?? 0;
    const height = pageHeight.value - footerHeight - filterComponentHeight - pageHeaderHeight;
    const minRows = Math.floor(height / ITEM_HEIGHT.value);
    const forceFooterThreshold = minRows * COL_COUNT.value + 1;
    globals.dynamicGlobals.profileGrid.forceFooter = profiles.value.length < forceFooterThreshold;
  });
}

function endOfFetching() {
  if (noMoreProfiles.value) return;
  noMoreProfiles.value = true;
  if (pageName != membersPage?.name) return;
  if (profiles.value.length < ITEMS_PER_PAGE) setForceFooter();

  const endProfile: ProfileCard =
    filterStore.isDefaultFilter() || firstFetchEmpty.value ? emptyCard : emptyFilterCard;

  const lastCardIndex = profiles.value.length - 1;
  const lastCard = profiles.value[lastCardIndex];
  if (!lastCard || lastCard.profile_id === endProfile.profile_id) return;

  if (lastCard.profile_id.startsWith('empty')) {
    profiles.value.pop();
  }
  profiles.value.push(endProfile);
}

function loadState(state: ScrollState<ProfileCard>) {
  if (pageName != membersPage?.name) return;
  isLoading.value = true;
  pageNumber.value = state.page;
  profiles.value = state.items;

  if (profiles.value.length > 0) {
    showNotice.value = false;
  } else {
    fetchItems();
  }
  firstFetchEmpty.value = state.firstFetchEmpty;
  if (profiles.value.length < ITEMS_PER_PAGE) {
    endOfFetching();
  }
  isLoading.value = false;
}

watch(
  () => filterStore.filtersUpdated,
  () => {
    firstFetchEmpty.value = false;
    firstFetch.value = true;
    noMoreProfiles.value = false;
    pageNumber.value = 0;
    profiles.value = [];
  }
);

const COL_COUNT = ref(MAX_COL_COUNT);

watchDebounced(width, resizeGrid, { debounce: 100 });

function resizeGrid() {
  const cols = Math.floor(width.value / globals.constantGlobals.profileGrid.MIN_ITEM_WIDTH);
  COL_COUNT.value = Math.min(cols, MAX_COL_COUNT);
  ITEM_WIDTH.value = width.value / COL_COUNT.value;
  setForceFooter();
}
</script>

<template>
  <div class="relative">
    <div v-if="components && profileCardComponent" class="min-h-modal-2xl">
      <div ref="gridContainer" class="container">
        <component
          :is="components[profileFiltersComponent.name]"
          v-if="profileFiltersComponent"
          :style="overrideStyles(profileFiltersComponent?.overrides)"
          :data="{
            columnCount: COL_COUNT,
            itemWidth: ITEM_WIDTH,
            showNoResults: firstFetchEmpty
          }"
          class="mb-4"
          :data-testid="props.testId + '/' + getTestId(profileFiltersComponent)"
          :test-id="props.testId + '/' + getTestId(profileFiltersComponent)"
        />

        <ClientOnly>
          <div v-show="!showNotice">
            <VirtualInfiniteGrid
              key-field="profile_id"
              :item-width="ITEM_WIDTH"
              :item-height="ITEM_HEIGHT"
              :page="pageNumber"
              :first-fetch-empty="firstFetchEmpty"
              :items="showProfiles"
              :col-count="COL_COUNT"
              @fetch="fetchItems"
              @load-state="loadState"
              @resize="resizeGrid"
              v-slot="slotProps"
              :data-testid="props.testId + '/VirtualGrid'"
              :test-id="props.testId + '/VirtualGrid'"
            >
              <div class="h-full pb-2 pe-2">
                <component
                  :is="components[profileCardComponent.name]"
                  :key="(slotProps.item as ProfileCard).profile_id"
                  :data="{
                    profile: slotProps.item,
                    isDetailPageCard: false,
                    isLoading: isLoading
                  }"
                  :style="overrideStyles(profileCardComponent?.overrides)"
                  :data-testid="
                    props.testId + '/' + getTestId(profileCardComponent) + slotProps.index
                  "
                  :test-id="props.testId + '/' + getTestId(profileCardComponent) + slotProps.index"
                />
              </div>
            </VirtualInfiniteGrid>
          </div>
        </ClientOnly>
      </div>
      <div v-show="showNotice">
        <NoResultsNotice :data="{}" :test-id="props.testId + '/'"></NoResultsNotice>
      </div>
    </div>
  </div>
</template>

