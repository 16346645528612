import type { LabelValuePair } from "../components";

export const Genders = ["m", "f", "t"] as const;

export type EGender = (typeof Genders)[number];

export const GenderOptions: LabelValuePair<EGender | "all">[] = [
  { label: "All", value: "all" },
  { label: "Men", value: "m" },
  { label: "Women", value: "f" },
];
