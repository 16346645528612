import type { z } from 'zod';
import type { PageSchema, SiteSettingsSchema, PageKeySchema } from './schemas';
import type { SiteThemeSchema } from './themeSchema';
import type { HorizonComponentSchema } from './horizonComponentSchema';
import type { TranslationKeySchema } from './translationSchema';

// These types cannot exist in types/*.d.ts since they would resolve to <any> in horizon project
export type HorizonComponent = z.infer<typeof HorizonComponentSchema>;

export type PageKey = z.infer<typeof PageKeySchema>;
export type Page = z.infer<typeof PageSchema>;
export type SiteTheme = z.infer<typeof SiteThemeSchema>;
export type SiteSettings = z.infer<typeof SiteSettingsSchema>;
export type Translations = z.infer<typeof TranslationKeySchema>;

export function isHorizonComponent(item?: object | null): item is HorizonComponent {
  return (
    !!item && //
    'name' in item &&
    'path' in item
  );
}

export type SiteConfig = {
  pages: Page[];
  theme: SiteTheme;
  siteSettings: SiteSettings;
  translations: Translations;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type HorizonComponentProps<TData = {}, TTranslations extends Record<string, string> = {}> = {
  data: TData;
  children?: HorizonComponent[];
  overrides?: SiteTheme;
  translations?: TTranslations;
  testId: string | null;
};

export type SiteThemes = {
  [override: string]: SiteTheme;
};

