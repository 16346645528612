<script setup lang="ts">
import type { ProfileRelationalFilter, HorizonComponentProps } from '../../../types';
import {
  useModalStore,
  useFiltersStore,
  type FilterKey,
  type AppliedFilters
} from '../../../stores';
import { ref } from 'vue';
import { useGeoStore } from '../../../stores/geo.store';
import { storeToRefs } from 'pinia';

const props = defineProps<HorizonComponentProps>();

const modalStore = useModalStore();

const filterStore = useFiltersStore();

const geoStore = useGeoStore();
geoStore.fetchGeoRegions();
const { geoRegionFilters } = storeToRefs(geoStore);

const currentFilters = ref<Partial<AppliedFilters>>({
  gender: filterStore.appliedFilters.gender,
  location: filterStore.appliedFilters.location,
  minAge: filterStore.appliedFilters.minAge,
  maxAge: filterStore.appliedFilters.maxAge,
  appearance: filterStore.appliedFilters.appearance,
  build: filterStore.appliedFilters.build,
  hair_color: filterStore.appliedFilters.hair_color
});

const activeFilters: FilterKey[] = Object.keys(currentFilters.value);
const relationalFilter = ref<ProfileRelationalFilter>(filterStore.relationalFilter);

function applyFilters() {
  filterStore.applyFilters(currentFilters.value, activeFilters, relationalFilter.value);
  modalStore.clearModal();
}
const genderLabelMapping: Record<string, string> = {
  m: 'Man',
  f: 'Woman',
  t: 'Trans',
  All: 'All'
};

const possibleFilters = filterStore.possibleFilters;
</script>

<template>
  <div class="bg-modal-bg w-modal absolute max-h-full max-w-full overflow-auto rounded-2xl p-6">
    <div
      class="border-header-text/20 absolute right-2 top-2 flex h-10 w-10 cursor-pointer place-content-center place-items-center rounded-md border"
    >
      <Icon
        name="ic:sharp-close"
        size="25"
        class="text-header-text hover:text-primary"
        :data-testid="props.testId + '/CloseButton'"
        @click="modalStore.clearModal()"
      />
    </div>
    <div class="flex flex-col">
      <strong class="text-modal-text bg-modal-bg text-lg font-bold">
        What are you looking for?
      </strong>
      <p class="bg-modal-bg pb-4 text-gray-400">Set your preferences for profiles here.</p>
    </div>

    <form>
      <div class="mb-8 grid gap-4 md:grid-cols-2 md:gap-8">
        <div>
          <label for="gender" class="text-modal-text bg-modal-bg tracking-wider">Gender</label>
          <select
            id="gender"
            v-model="currentFilters.gender"
            name="gender"
            class="control"
            :data-testid="props.testId + '/GenderSelect'"
          >
            <option
              v-for="(option, id) of possibleFilters.gender"
              :key="id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'GenderOption'"
            >
              {{ genderLabelMapping[option.label] }}
            </option>
          </select>
        </div>

        <div>
          <label for="location" class="text-modal-text bg-modal-bg tracking-wider">Location</label>
          <select
            id="location"
            v-model="currentFilters.location"
            name="location"
            class="control"
            :data-testid="props.testId + '/LocationSelect'"
          >
            <option
              v-for="(option, id) of geoRegionFilters"
              :key="id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'LocationOption'"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <div>
          <label for="minage" class="text-modal-text bg-modal-bg tracking-wider">Min. Age</label>
          <select
            id="minage"
            v-model="currentFilters.minAge"
            name="minage"
            class="control mr-1"
            :data-testid="props.testId + '/MinAgeSelect'"
          >
            <option
              v-for="option in Number(possibleFilters.maxAge[0].value) -
              Number(possibleFilters.minAge[0].value) +
              1"
              :key="option"
              :value="String(option + 17)"
              :disabled="option + 17 > Number(currentFilters.maxAge)"
            >
              {{ String(option + 17) }}
            </option>
          </select>
        </div>

        <div>
          <label for="maxage" class="text-modal-text bg-modal-bg tracking-wider">Max. Age</label>
          <select
            id="maxage"
            v-model="currentFilters.maxAge"
            name="maxage"
            class="control ml-1"
            :data-testid="props.testId + '/MaxAgeSelect'"
          >
            <option
              v-for="option in Number(possibleFilters.maxAge[0].value) -
              Number(possibleFilters.minAge[0].value) +
              1"
              :key="option"
              :value="String(option + 17)"
              :disabled="option + 17 < Number(currentFilters.minAge)"
            >
              {{ String(option + 17) }}
            </option>
          </select>
        </div>

        <div class="md:col-span-2">
          <label for="appearance" class="text-modal-text bg-modal-bg tracking-wider"
            >Ethnicity</label
          >
          <select
            id="appearance"
            v-model="currentFilters.appearance"
            name="appearance"
            class="control"
            :data-testid="props.testId + '/EthnicitySelect'"
          >
            <option
              v-for="(option, id) of possibleFilters.appearance"
              :key="id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'EthnicityOption'"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <div>
          <label for="build" class="text-modal-text bg-modal-bg tracking-wider">Body type</label>
          <select
            id="build"
            v-model="currentFilters.build"
            name="build"
            class="control"
            :data-testid="props.testId + '/BuildSelect'"
          >
            <option
              v-for="(option, id) of possibleFilters.build"
              :key="id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'BuildOption'"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <div>
          <label for="haircolor" class="text-modal-text bg-modal-bg tracking-wider"
            >Hair color</label
          >
          <select
            id="haircolor"
            v-model="currentFilters.hair_color"
            name="haircolor"
            class="control"
            :data-testid="props.testId + '/HairColorSelect'"
          >
            <option
              v-for="(option, id) of possibleFilters.hair_color"
              :key="id"
              :value="option.value"
              :data-testid="props.testId + '/' + option.label + 'HairColorOption'"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>

      <ButtonSimple
        class="w-full"
        :data="{ label: 'Save Preferences', isLarge: true }"
        :test-id="props.testId"
        @click="applyFilters()"
      />
    </form>
  </div>
</template>

<style scoped lang="postcss">
.control {
  @apply text-modal-text bg-modal-bg border-modal-backdrop h-10 w-full rounded-md border px-2 py-1 text-sm;
}
</style>

