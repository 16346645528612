<script lang="ts" setup>
import type { Link, HorizonComponentProps } from '../../types';
import { useRoute } from 'vue-router';

interface PropsData {
  buttons: Link[];
}

const props = defineProps<HorizonComponentProps<PropsData>>();

const route = useRoute();
</script>

<template>
  <nav v-if="props.data" class="my-8 md:mt-12">
    <ul class="mx-auto flex w-max flex-row justify-center rounded-md bg-[#111316]">
      <NuxtLink
        v-for="link in props.data.buttons"
        :key="link.href"
        :to="link.href"
        :data-testid="props.testId + '/' + link.text + 'Button'"
      >
        <li
          :class="{
            'bg-primary': route.path.startsWith(link.href)
          }"
          class="text-text hover:bg-primary/70 rounded-md px-6 py-2 font-semibold"
        >
          {{ link.text }}
        </li>
      </NuxtLink>
    </ul>
  </nav>
</template>

