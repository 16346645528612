import { useCookie } from 'nuxt/app';
import type { LocationQuery, LocationQueryRaw } from 'vue-router';

type CookieValue = {
  p?: number;
  pi?: string;
  pe?: string; 
  pt1?: string;
  pt2?: string;
}

export function usePartnerTrackingCookie() {
  return useCookie<CookieValue | undefined>('partner-tracking', {
    encode: (v) => JSON.stringify(v),
    decode: (v) => JSON.parse(v),
    secure: true,
    httpOnly: false,
  });
} 

const partnerParamRegex = new RegExp(/^(p|pi|pe|pt\d+|age)$/);

export function extractPartnerParameters(query: LocationQuery): LocationQueryRaw {
  const result: LocationQueryRaw = {};

  Object.entries(query).forEach(([k,v]) => {
    if (k.match(partnerParamRegex)) {
      result[k] = v;
    }
  })
  
  return result;
}

export function hasPartnerParameters(query: LocationQuery): boolean {
  if (!query) return false;

  for (const k of Object.keys(query)) {
    if (k.match(partnerParamRegex)) {
      return true;
    }
  }

  return false;
}
