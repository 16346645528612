<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { createCCFetchClient } from '../../composables/apiClients';
import { useUserConsentStore } from '../../stores';
import { useAuthStore } from '../../stores/auth.store';
import TextRenderer from './TextRenderer.vue';
import { fetchUserConsent } from '../../utils/consentChecker';
import type { HorizonComponentProps } from '../../types';

const props = defineProps<HorizonComponentProps>();
const authStore = useAuthStore();
const userConsentStore = useUserConsentStore();
const collectConsentApi = createCCFetchClient();

// Refetch user consent state in case the user changed consent in the first browser session
if (authStore.isAuthorized) {
  await fetchUserConsent();
}

const nonRequiredSegments =
  userConsentStore.userConsentState?.consentSegments?.filter((x) => !x.consentSegment.isRequired) ??
  [];

const segmentState = reactive(
  nonRequiredSegments.reduce(
    (state, segment) => {
      state[segment.consentSegment.versionID] = !!segment.hasAccepted;
      return state;
    },
    {} as Record<string, boolean>
  )
);

const hasUserMadeChanges = computed<boolean>(() => {
  for (const segment of nonRequiredSegments) {
    const state = segmentState[segment.consentSegment.versionID];
    if (state !== segment.hasAccepted) {
      return true;
    }
  }

  return false;
});

const updateStatus = ref<'idle' | 'saving' | 'success' | 'error'>('idle');

async function saveChanges() {
  updateStatus.value = 'saving';

  const versionIdsToUpdate = nonRequiredSegments
    .filter((x) => x.hasAccepted !== segmentState[x.consentSegment.versionID])
    .map((x) => x.consentSegment.versionID);

  const fetchPromises = versionIdsToUpdate.map((id) =>
    collectConsentApi('/user/consent', {
      method: 'POST',
      body: {
        consentSegmentVersionID: id,
        hasAccepted: segmentState[id],
        type: 'sponsor'
      }
    }).then((result) => {
      // Update the segment to have the new 'hasAccepted' state, to prevent doing a double call when updating a different segment.
      if (!result.error) {
        const segment = nonRequiredSegments.find((x) => x.consentSegment.versionID === id);
        if (segment) {
          segment.hasAccepted = segmentState[id];
        }
      }

      return result;
    })
  );

  const fetchResults = await Promise.allSettled(fetchPromises);

  updateStatus.value = fetchResults.every((x) => x.status === 'fulfilled' && !x.value.error)
    ? 'success'
    : 'error';
}
</script>

<template>
  <template v-if="authStore.isAuthorized">
    <div
      v-if="updateStatus === 'error'"
      class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm"
    >
      <strong class="text-white">Wijzigingen opslaan mislukt...</strong>
    </div>
    <div
      v-if="updateStatus === 'success'"
      class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm"
    >
      <strong class="text-white">Wijzigingen opgeslagen!</strong>
    </div>

    <ul class="mt-2">
      <li v-for="segment of nonRequiredSegments" :key="segment.consentSegment.versionID">
        <label class="flex items-start text-black">
          <input
            v-model="segmentState[segment.consentSegment.versionID]"
            class="me-2 mt-2"
            type="checkbox"
          />
          <TextRenderer :data="{ text: segment.consentSegment.content }" :test-id="null" />
        </label>
      </li>
    </ul>

    <ButtonSimple
      class="mt-2 p-2 font-bold"
      :data="{
        label: 'Opslaan',
        isDisabled: !hasUserMadeChanges || updateStatus === 'saving',
        isSaving: updateStatus === 'saving'
      }"
      :test-id="props.testId"
      @click="saveChanges()"
    />
  </template>
</template>

