import { useRoute } from '#app';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  PixelParamKeys,
  type LoadedPixel,
  type PixelParamKey,
  type PixelSchema
} from '../types/config/partnerPixelsSchema';
import { useSiteConfigStore } from './siteConfig.store';
import { usePartnerTrackingCookie } from '../utils/partner';

type PixelParams = {
  [P in PixelParamKey]?: string;
};

export const usePartnerTrackingStore = defineStore('partner-tracking', () => {
  const siteConfigStore = useSiteConfigStore();
  const requiredParams = computed<Set<string>>(
    () => new Set(siteConfigStore.partnerPixelConfig?.requiredParams ?? [])
  );

  const activePixels = ref<LoadedPixel[]>([]);
  const id = ref(0);

  function loadSoiPixels(profileID?: string, age?: number) {
    const soiPixels = siteConfigStore.partnerPixelConfig?.soiPixels ?? [];

    soiPixels.forEach(soiPixel => {
        loadPixel(
            soiPixel,
            profileID,
            age
        )
    })
  }

  function loadDoiPixels(profileID?: string, age?: number) {
    const doiPixels = siteConfigStore.partnerPixelConfig?.doiPixels ?? [];

    doiPixels.forEach(doiPixel => {
        loadPixel(
            doiPixel,
            profileID,
            age
        )
    })
  }

  /**
   * Loads a pixel into the store, replacing the variables in the specified url.
   * If a pixel parameter is not available, and the site config has marked that parameter as 'required', the pixel will NOT be loaded.
   */
  function loadPixel(pixel: PixelSchema, profileID?: string, age?: number) {
    const route = useRoute();
    const cookie = usePartnerTrackingCookie();

    const pt1 = route.query['pt1'] || cookie.value?.pt1;
    const pt2 = route.query['pt2'] || cookie.value?.pt2;

    const { replacedUrl, success } = replaceUrlParams(pixel.url, {
      transaction_id: (Array.isArray(pt1) ? pt1[0] : pt1) as string | undefined,
      sub_id: (Array.isArray(pt2) ? pt2[0] : pt2) as string | undefined,
      enduser_id: profileID,
      age: age?.toString()
    });

    if (!success) {
      return;
    }

    activePixels.value.push({
      id: id.value++,
      strategy: pixel.strategy,
      url: replacedUrl
    });
  }

  function removePixel(id: number) {
    activePixels.value = activePixels.value.filter((x) => x.id !== id);
  }

  /**
   * Example:
   *
   * `/api/pixeltest/iframe?transaction_id=:transaction_id&enduser_id=:enduser_id&sub_id=:sub_id&age=:age`
   * 
   * `/api/pixeltest/iframe?transaction_id=87231&enduser_id=1234&sub_id=123&age=31`
   */
  function replaceUrlParams(
    url: string,
    params: PixelParams
  ): { replacedUrl: string; success: boolean } {
    let replacedUrl = url.slice();

    for (const key of Object.keys(PixelParamKeys.Enum)) {
      if (!url.includes(key)) continue;

      const value = params[key as keyof PixelParams];

      if (!value && requiredParams.value.has(key)) {
        console.error('Could not replace url params for', { url, key });
        return { replacedUrl: '', success: false };
      }

      replacedUrl = replacedUrl.replace(':' + key, value ?? '');
    }

    return { replacedUrl, success: true };
  }

  function buildPartnerParams(): Record<string, unknown> {
    if (!siteConfigStore.partnerPixelConfig) return {};
    
    const route = useRoute();
    const cookie = usePartnerTrackingCookie();
    
    const userP = Number(route.query['p']) || cookie.value?.p;
    const userPI = route.query['pi']?.toString() || cookie.value?.pi;
    const userPE = route.query['pe']?.toString() || cookie.value?.pe;
    const userPT1 = route.query['pt1']?.toString() || cookie.value?.pt1;
    const userPT2 = route.query['pt2']?.toString() || cookie.value?.pt2;
    
    const configP = siteConfigStore.partnerPixelConfig.p;
    const configPI = siteConfigStore.partnerPixelConfig.pi;
    const configPE = siteConfigStore.partnerPixelConfig.pe;
    
    const config = {
      p: configP.value,
      pi: configPI.value,
      pe: configPE.value,
      pt: {
        "1": userPT1,
        "2": userPT2
      }
    }

    if (userP && !configP.isFixed) {
      config.p = userP;
    }
    if (userPI && !configPI.isFixed) {
      config.pi = userPI;
    }
    if (userPE && !configPE.isFixed) {
      config.pe = userPE;
    }

    return config;
  }

  return {
    activePixels,

    loadSoiPixels,
    loadDoiPixels,
    loadPixel,
    removePixel,
    buildPartnerParams
  };
});

