export function hexToRgb(hex: string) {
  if (!hex) {
    return "";
  }
  if (hex.length === 4) {
    hex = "#" + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return "";
  }
  return `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(
    result[3],
    16
  )}`;
}
