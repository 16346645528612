import { useCookie, createError, useRuntimeConfig } from '#app';
import { createCCFetchClient } from '../composables/apiClients';
import { useSiteConfigStore } from '../stores';
import { useModalStore } from '../stores/modal.store';
import { useUserConsentStore } from '../stores/userconsent.store';
import { EModal, type HorizonNuxtErrorData, type TranslationVars } from '../types';
import { storeToRefs, useNuxtApp } from '#imports';

export async function fetchUserConsent() {
  const runtimeConfig = useRuntimeConfig();
  const { $translate } = useNuxtApp();
  const siteConfigStore = useSiteConfigStore();
  const { translations } = storeToRefs(siteConfigStore);
  const localeCode = siteConfigStore.localeCode;
  const companyID = siteConfigStore.companyId;
  if (!localeCode || !companyID) {
    const vars: TranslationVars = {
      whatVar: 'user consent'
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.loadError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: siteConfigStore.theme,
        devTitle: 'consentChecker fetchUserConsent: invalid config',
        devMessage: 'no localeCode or companyID specified, unable to fetch consent status'
      },
      fatal: true
    });
  }

  const ccFetchClient = createCCFetchClient();
  const fetchResponse = await ccFetchClient('/locale/{localeCode}/user/consent', {
    method: 'GET',
    path: { localeCode },
    query: { companyID }
  }).catch(() => {});

  if (!fetchResponse || fetchResponse.error) {
    // Disable consent error message in development or chao since you wouldn't be able to visit the site without setting up collect consent otherwise
    if (import.meta.dev || runtimeConfig.public.isRunningInChao) {
      return;
    }
    const vars: TranslationVars = {
      whatVar: 'user consent'
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.loadError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: siteConfigStore.theme,
        devTitle: 'consentChecker fetchUserConsent: error fetching consent status',
        devMessage: fetchResponse?.error?.data?.error
      },
      fatal: true
    });
  }

  useUserConsentStore().loadUserConsentState(fetchResponse.data);
}

export async function checkUserConsent() {
  const { userConsentState, hasAccess } = useUserConsentStore();
  if (!userConsentState) {
    return;
  }

  if (!hasAccess) {
    useModalStore().showModalComponent(EModal.onboardingNoticeModalComponent, false);
  }
}

export async function fingerPrintUser(userId: string | undefined) {
  const cookieBannerState = useCookieBannerState().value;
  if (!userId || !cookieBannerState) return;

  const collectConsentApi = createCCFetchClient();
  const { error } = await collectConsentApi('/user/fingerprint/{fingerprint}', {
    method: 'POST',
    path: {
      fingerprint: cookieBannerState.fingerprint
    }
  });

  if (error) {
    console.error(
      'Could not fingerprint guest user cookie consent state to registered user. Re-approval required...'
    );
    return;
  }

  useCookieBannerState().value = null;
}

type CookieBannerState = {
  fingerprint: string;
  consentSegmentVersionIDs: string[];
};

export function useCookieBannerState() {
  return useCookie<CookieBannerState | null>('cookieBannerState');
}

