<script setup lang="ts">
import { navigateTo } from '#app';
import { useModalStore } from '../../../stores';
import { sendFlirt, flirtButtons } from '../../../utils/flirt';
import { ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import type { HorizonComponentProps } from '../../../types';
import type { FlirtModalProps } from './flirtmodal.schema';

const modalStore = useModalStore();
const props = defineProps<HorizonComponentProps<FlirtModalProps>>();
const success = ref<boolean>();
const reason = ref<string>();
const sending = ref<boolean>(false);
const timer = ref<number>(0);

function countDown(id: string, sec: number = 3) {
  timer.value = sec - 1; // it counts the 0 as well
  const interval = setInterval(() => {
    if (timer.value === 0) {
      clearInterval(interval);
      navigateTo(`/message/${id}`);
      modalStore.clearModal();
    } else {
      timer.value--;
    }
  }, 1000);
}

const pressButton = useDebounceFn(async (content: string) => {
  if (!props || sending.value) return;
  sending.value = true;
  const response = await sendFlirt(props.data.profileId, content);
  success.value = response.success;
  reason.value = response.reason;
  if (success.value === true) {
    countDown(props.data.profileId);
  }
}, 250);
</script>

<template>
  <div
    class="bg-header-bg top-1/10 md:!w-modal-lg absolute m-5 max-h-full w-full overflow-y-auto rounded-2xl p-6 md:h-fit"
  >
    <div
      class="border-header-text/20 absolute right-2 top-2 flex h-10 w-10 cursor-pointer place-content-center place-items-center rounded-md border"
    >
      <Icon
        name="ic:sharp-close"
        size="25"
        class="text-header-text hover:text-primary"
        :data-testid="props.testId + '/CloseButton'"
        @click="modalStore.clearModal()"
      />
    </div>
    <div class="mb-3">
      <h1 class="text-modal-text text-start text-xl font-bold">Send a Flirt!</h1>
      <h1 class="text-modal-text/40 text-start text-base">Tap the Flirt you want to sent!</h1>
    </div>
    <Transition name="banner">
      <div
        v-show="success != undefined"
        class="bannerclass mb-3 w-full rounded-full py-1 text-center text-sm text-white"
        :class="{
          'bg-danger': !success,
          'bg-success': success
        }"
      >
        {{ reason }}<strong v-if="success" class="text-primary text-lg">{{ timer }}</strong>
        {{ success == true ? (timer === 1 ? 'second' : 'seconds') : '' }}
      </div>
    </Transition>

    <div class="grid grid-cols-2 grid-rows-3 gap-3">
      <button
        v-for="button of flirtButtons"
        :key="button.text"
        class="bg-btn-secondary-bg hover:bg-btn-secondary-hover-bg flex h-20 flex-row rounded-md"
        :data-testid="props.testId + '/' + button.text + 'Button'"
        @click="pressButton(button.text)"
      >
        <div class="my-auto ml-4 h-14 w-14">
          <img :src="button.image" alt="" />
        </div>
        <p class="text-header-text my-auto ml-2">{{ button.text }}</p>
        <Icon name="circum:paperplane" class="text-primary my-auto ml-auto mr-2" size="25" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.banner-enter-from {
  opacity: 0;
  translate: -100% 0;
}
.banner-enter-to {
  opacity: 1;
  translate: 0 0;
}
.banner-leave-from {
  opacity: 1;
  translate: 0 0;
}
.banner-leave-to {
  opacity: 0;
  translate: -100% 0;
}
.bannerclass {
  transition: all 0.5s;
}
</style>

