<script setup lang="ts">
import { useElementSize, watchDebounced } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, inject, ref, type Component } from 'vue';
import { useGlobalsStore, useProfileStore, useSiteConfigStore } from '../../stores';
import type { ProfileCard, HorizonComponentProps } from '../../types';
import { arrayShuffle, overrideStyles, getTestId, getSkeletonGrid } from '../../utils';
import BlurField from '../Generic/BlurField.vue';
import type { ScrollState } from '../ProfileGrid/VirtualInfiniteGrid.vue';
import VirtualInfiniteGrid from '../ProfileGrid/VirtualInfiniteGrid.vue';

const props = defineProps<HorizonComponentProps>();

const components = inject<Record<string, Component>>('components');

const profileStore = useProfileStore();

const siteConfigStore = useSiteConfigStore();
const { profileCardComponent, profileFiltersComponent } = storeToRefs(siteConfigStore);

const globals = useGlobalsStore();
const landingGridContainer = ref(null);
const MAX_COL_COUNT = globals.constantGlobals.profileGrid.MAX_COL_COUNT;
const COL_COUNT = ref(MAX_COL_COUNT);

const { width } = useElementSize(landingGridContainer);
const ITEM_WIDTH = ref<number>(globals.constantGlobals.profileGrid.ITEM_WIDTH);

const ITEM_HEIGHT = computed(() => (ITEM_WIDTH.value / 5) * 6);

const pageNumber = ref<number>(0);
const maxRows = 4;
const profiles = ref<ProfileCard[]>([]);
const profileCards = ref<ProfileCard[]>([]);
const noMoreProfiles = ref<boolean>(false);
const firstFetchEmpty = ref<boolean>(false);
const firstFetch = ref<boolean>(true);
const specialBlurOffsetCardsizeRange = {
  min: 169,
  max: 179
};
const maxCards = computed(() => COL_COUNT.value * maxRows);
const isLoading = ref<boolean>(true);
const skeletonGrid: ProfileCard[] = getSkeletonGrid(maxCards.value);
const showProfiles = computed<ProfileCard[]>(() =>
  isLoading.value ? skeletonGrid : profileCards.value
);

async function fetchItems() {
  if (profiles.value.length > 0) return;
  if (import.meta.server) return;
  if (noMoreProfiles.value) return;

  const data = await profileStore.getSplashProfiles();
  if (data.profiles.length > 0) isLoading.value = false;
  profiles.value = arrayShuffle(data.profiles);
  if (data.noProfiles) {
    if (firstFetch.value) {
      firstFetchEmpty.value = true;
      firstFetch.value = false;
    }
  }

  profileCards.value = profiles.value.slice(0, maxCards.value);
  endOfFetching();
  pageNumber.value++;
}

function endOfFetching() {
  if (noMoreProfiles.value) return;
  noMoreProfiles.value = true;
}

function loadState(_state: ScrollState<ProfileCard>) {
  fetchItems();
}

watchDebounced(width, resizeGrid, { debounce: 10 });

function resizeGrid() {
  const cols = Math.floor(width.value / globals.constantGlobals.profileGrid.MIN_ITEM_WIDTH);
  COL_COUNT.value = Math.min(cols, MAX_COL_COUNT);
  ITEM_WIDTH.value = Math.min(
    width.value / COL_COUNT.value,
    globals.constantGlobals.profileGrid.MAX_ITEM_WIDTH
  );
  const maxCards = COL_COUNT.value * maxRows;
  profileCards.value = profiles.value.slice(0, maxCards);
}
</script>

<template>
  <div class="relative translate-y-9">
    <div v-if="components && profileCardComponent" class="min-h-modal-2xl">
      <div ref="landingGridContainer" class="container">
        <component
          :is="components[profileFiltersComponent.name]"
          v-if="profileFiltersComponent"
          :style="overrideStyles(profileFiltersComponent?.overrides)"
          :data="{
            columnCount: COL_COUNT,
            itemWidth: ITEM_WIDTH,
            homePage: true
          }"
          class="mb-4"
          :data-testid="props.testId + '/' + getTestId(profileFiltersComponent)"
          :test-id="props.testId + '/' + getTestId(profileFiltersComponent)"
        />

        <ClientOnly>
          <VirtualInfiniteGrid
            key-field="profile_id"
            :item-width="ITEM_WIDTH"
            :item-height="ITEM_HEIGHT"
            :page="pageNumber"
            :first-fetch-empty="firstFetchEmpty"
            :items="profileCards"
            :col-count="COL_COUNT"
            :data-testid="props.testId + '/VirtualGrid'"
            :test-id="props.testId + '/VirtualGrid'"
            v-slot="slotProps"
            @fetch="fetchItems"
            @load-state="loadState"
            @resize="resizeGrid"
          >
            <div class="h-full pb-2 pe-2">
              <component
                :is="components[profileCardComponent.name]"
                :key="(slotProps.item as ProfileCard).profile_id"
                :data="{
                  profile: slotProps.item,
                  isDetailPageCard: false,
                  isLoading: isLoading
                }"
                :style="overrideStyles(profileCardComponent?.overrides)"
                :data-testid="
                  props.testId + '/' + getTestId(profileCardComponent) + slotProps.index
                "
                :test-id="props.testId + '/' + getTestId(profileCardComponent) + slotProps.index"
              />
            </div>
          </VirtualInfiniteGrid>
        </ClientOnly>
      </div>
    </div>

    <BlurField
      :data="{ isLoading: isLoading }"
      class="absolute md:top-[28%]"
      :class="{
        'top-[27%]':
          specialBlurOffsetCardsizeRange.min <= ITEM_WIDTH &&
          ITEM_WIDTH <= specialBlurOffsetCardsizeRange.max,
        'top-[25.5%]':
          specialBlurOffsetCardsizeRange.min > ITEM_WIDTH ||
          ITEM_WIDTH > specialBlurOffsetCardsizeRange.max
      }"
      :style="`height: ${3 * ITEM_HEIGHT}px;`"
      :test-id="props.testId + '/BlurField'"
    ></BlurField>
  </div>
</template>

