<script setup lang="ts">
import type { HorizonComponent } from '@horizon/library/types';
import { getTestId, overrideStyles } from '@horizon/library/utils';
import * as components from '~~/.nuxt/uiLibFacade'; // generated file containing all used components

type ComponentName = keyof typeof components;

interface Props {
  componentData: HorizonComponent;
}

const isDev = import.meta.dev;
const props = defineProps<Props>();
const spacingClasses = computed(() => ({
  'mx-mx': !!props.componentData.overrides?.margin?.x,
  'my-my': !!props.componentData.overrides?.margin?.y,
  'px-px': !!props.componentData.overrides?.padding?.x,
  'py-py': !!props.componentData.overrides?.padding?.y
}));
const testID = getTestId(props.componentData);
</script>

<template>
  <component
    :is="components[props.componentData.name as ComponentName]"
    v-if="props.componentData"
    class="flex-[var(--flex)]"
    :class="spacingClasses"
    :style="overrideStyles(props.componentData.overrides)"
    :children="props.componentData.children"
    :overrides="props.componentData.overrides"
    :data="props.componentData.data"
    :data-component="isDev ? props.componentData.name : null"
    :translations="props.componentData.translations"
    :data-testid="testID"
    :test-id="testID"
  ></component>
</template>

