import type { SiteTheme } from '../types/config/types';
import { hexToRgb } from './color';
import { flattenObjectKeys, getNestedObjectValue } from './objects';

/**
 * Format a camel case string to kebab case
 */
export function camelToKebab(value: string) {
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function overrideStyles(overrides?: SiteTheme, isPage: boolean = false): string | undefined {
    if (!overrides) return undefined;

    const styles: string[] = [];
    // colors
    if (overrides?.colors) {
        const colorEntries = Object.entries(overrides.colors);

        for (const [key, value] of colorEntries) {
            const replacedKey = key.startsWith('button') ? key.replace('button', 'btn') : key;

            const newKey = camelToKebab(replacedKey);

            const rgb = hexToRgb(value ?? '');
            if (isPage) {
                styles.push(`${newKey}-color:${value}`);
                styles.push(`${newKey}-rgb:${rgb}`);
            } else {
                styles.push(`--${newKey}-color:${value}`);
                styles.push(`--${newKey}-rgb:${rgb}`);
            }
        }
    }

    const skipKeys = new Set(['colors']);

    const keys = flattenObjectKeys(overrides);

    for (const key of keys) {
        if (skipKeys.has(key.split('.')[0])) continue;

        const k = camelToKebab(key.replace('.', '-'));
        const value = getNestedObjectValue(overrides, key);

        if (!value) continue;
        if (isPage) {
            styles.push(`${k}:${value}`);
        } else {
            styles.push(`--${k}:${value}`);
        }
    }

    return styles.join(';') + ';';
}
