<script setup lang="ts">
import type { ZodFormattedError } from "zod";
import { computed, toRefs } from "vue";

interface Props {
  zodErrors?: ZodFormattedError<unknown>;
  property?: string;
}

const props = defineProps<Props>();
const { zodErrors, property } = toRefs(props);

const errors = computed(() => {
  const allErrors = zodErrors.value;
  if (!allErrors) return null;

  if (!property.value) {
    return allErrors._errors;
  }

  const typedKey = property.value as keyof typeof zodErrors.value;
  const propertyErrors = allErrors[typedKey] as
    | ZodFormattedError<unknown>
    | undefined;
  if (!propertyErrors) return null;

  return propertyErrors._errors;
});
</script>

<template>
  <ul v-if="errors" class="mt-2">
    <li v-for="error of errors" :key="error">
      <div
        class="w-full rounded-full bg-yellow-600 p-1 text-center text-xs text-white"
      >
        {{ error }}
      </div>
    </li>
  </ul>
</template>
