import { createError, useCookie } from '#app';
import { useRoute } from '#vue-router';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { createUseFulfillmentFetchClient } from '../composables/apiClients';
import type { HorizonNuxtErrorData } from '../types';
import { PaymentStatus, type PaymentProducts } from '../types/fulfillment';
import { getDeviceType } from '../utils/deviceType';
import { useSiteConfigStore } from './siteConfig.store';
import { useCachedFn } from '../utils/useCachedFn';

export const usePaymentStore = defineStore('payment-store', () => {
  const apiClient = createUseFulfillmentFetchClient();
  const siteConfigStore = useSiteConfigStore();
  const route = useRoute();

  const paymentPage = siteConfigStore.pages?.find((page) => page.key === 'payment');
  const paymentPagePath = paymentPage?.path;
  const paymentError = ref<string | null>(null);

  const paymentProducts = ref<PaymentProducts>();
  const minCreditPrice = ref<number>(0);
  const maxCreditPrice = ref<number>(0);

  async function fetchProducts() {
    const data = await useCachedFn('products', async () => {
      const tenant_id = siteConfigStore.siteSettings?.tenantId ?? '';
  
      const { data, error } = await apiClient('/client/tenants/{tenant_id}/products', {
        method: 'GET',
        path: {
          tenant_id
        }
      });
  
      if (!data.value || error.value) {
        throw createError<HorizonNuxtErrorData>({
          data: {
            title: 'Unable to load products',
            theme: siteConfigStore.theme,
            devTitle: 'payment store, fetchProducts() failed',
            devMessage: error.value?.data?.error
          },
          fatal: true
        });
      }

      return data.value;
    })

    if (data) {
      paymentProducts.value = data.products;
      _calculateMinMaxCreditPrices();
    }
  }

  async function createOrder(paymentMethod: string, productId: string): Promise<string | null> {
    if (!siteConfigStore.localeCode) return null;

    paymentError.value = null;

    if (!paymentPagePath) {
      paymentError.value = 'Payment page not found';
      return null;
    }

    const origin = new URL(window.location.href).origin;
    const redirectBaseUrl = origin + paymentPagePath;
    const locale = siteConfigStore.localeCode ?? '';
    const { data, error } = await apiClient('/client/orders', {
      method: 'POST',
      body: {
        device: getDeviceType(),
        order_lines: [{ product_id: productId, quantity: 1 }],
        payment_method: paymentMethod,
        locale: locale,
        success_url: `${redirectBaseUrl}?status=${PaymentStatus.Successful}`,
        fail_url: `${redirectBaseUrl}?status=${PaymentStatus.Failed}`,
        pending_url: `${redirectBaseUrl}?status=${PaymentStatus.Pending}`
      }
    });

    if (error.value || !data.value) {
      paymentError.value = 'Failed to create order';
      return null;
    }

    const orderCookie = useCookie('orderId', { maxAge: 60 * 60 }); // 1 hour
    orderCookie.value = data.value.id;

    const profileCookie = useCookie('profileId', { maxAge: 60 * 60 }); // 1 hour
    const profileId: string | undefined = route.params?.id?.toString();
    profileCookie.value = profileId;

    return data.value.checkout_url;
  }

  function _calculateMinMaxCreditPrices() {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;

    paymentProducts.value?.forEach((x) => {
      const pricePerCredit = Math.round((x.price / x.reward_quantity) * 100) / 100;
      min = Math.min(min, pricePerCredit);
      max = Math.max(max, pricePerCredit);
    });

    minCreditPrice.value = min === Number.MAX_SAFE_INTEGER ? 0 : min;
    maxCreditPrice.value = max;
  }

  return {
    paymentError,
    paymentProducts,
    minCreditPrice,
    maxCreditPrice,

    createOrder,
    fetchProducts
  };
});

