import { useSiteConfigStore } from '../stores';
import type { PageKey } from '../types/config/types';
import type { ProfileRelationalFilter } from '../types';
import { createProfilesFetchClient } from '../composables/apiClients';

export const pageKeyToFilterMap: Partial<Record<PageKey, ProfileRelationalFilter>> = {
  members: 'All',
  visitors: 'LikedMe',
  'i-like': 'Liked',
  'liked-me': 'LikedMe',
  favourites: 'Favorites',
  matched: 'Match'
};

export function mapPageNameToRelationalFilter(pageName: string): ProfileRelationalFilter {
  const page = useSiteConfigStore()?.pages?.find((x) => x.name === pageName);
  if (!page?.key) return 'All';

  const filter = pageKeyToFilterMap[page.key];
  if (filter) return filter;

  return 'All';
}

export async function getFilterOptions() {
  const profileFetchClient = createProfilesFetchClient();
  const { data, error } = await profileFetchClient('/profile/filters', {
    method: 'GET'
  });
  return { data: data, error: error };
}

