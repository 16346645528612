// no check is because created at var is a mess right now, see: https://harlemnext.atlassian.net/browse/PHCA-64
import type { MessageDocument } from '@horizon/chat-protocol-ts';
import type { chatProfileData, ProfileCard } from '../types';
import { timestampNow } from '@bufbuild/protobuf/wkt';

function buildMessage(content: string, locale: string = 'en_US'): MessageDocument {
  return {
    attachment: '',
    content,
    createdAt: timestampNow(),
    id: '',
    isRead: true,
    pokeMessageType: 'greeting',
    isFree: true,
    messageLength: content.length,
    senderId: '',
    senderType: 'agent',
    type: 'normal',
    domain: '',
    imported: false,
    locale: locale,
    typeId: '',
    tenant: '',
    senderName: 'agent',
    operatorResponseTime: 21,
    creditsSpent: 1,
    metadata: {},
    senderUuid: '',
    recipientUuid: ''
  };
}

const NLmessage0 = buildMessage('Hi! Welkom bij de chat pagina!', 'nl_NL');
const NLnoProfilesMessage1 = buildMessage(
  'Het ziet er naar uit dat je nog geen gesprekken hebt!',
  'nl_NL'
);
const NLnoProfilesMessage2 = buildMessage(
  'Ga nieuwe gesprekken aan via de Leden of Bezoekers pagina!',
  'nl_NL'
);
const NLyesProfilesMessage1 = buildMessage('Je hebt nog geen gesprek aangeklikt!', 'nl_NL');
const NLyesProfilesMessage2 = buildMessage(
  'Klik op een gesprek links of ga nieuwe gesprekken aan via de Leden of Bezoekers pagina',
  'nl_NL'
);
const ENmessage0 = buildMessage('Hi! Welcome to the chat page!');
const ENnoProfilesMessage1 = buildMessage("It looks like you don't have any chats yet!");
const ENnoProfilesMessage2 = buildMessage('Start new chats through the Members or Visitors page!');
const ENyesProfilesMessage1 = buildMessage("You haven't clicked on a chat yet!");
const ENyesProfilesMessage2 = buildMessage(
  'Click on a chat to the left or start a new chat through the Members or Visitors page'
);

export function getEmptyProfile(name: string, image: string) {
  const emptyProfile: chatProfileData = {
    createdAt: '',
    conversation: false,
    flirted: false,
    card: {
      name: name,
      date_of_birth: '',
      image: {
        id: crypto.randomUUID(),
        url: image,
        approved: true
      },
      profile_id: 'none',
      city: ''
    },
    chatID: 'none',
    messages: []
  };
  return emptyProfile;
}
export function getEmptyProfileMessages(noProfiles: boolean = true, language: string = 'en') {
  const messages = [];
  if (language === 'en') {
    messages.push(ENmessage0);
    if (noProfiles) {
      messages.push(ENnoProfilesMessage1);
      messages.push(ENnoProfilesMessage2);
    } else {
      messages.push(ENyesProfilesMessage1);
      messages.push(ENyesProfilesMessage2);
    }
  }
  if (language === 'nl') {
    messages.push(NLmessage0);
    if (noProfiles) {
      messages.push(NLnoProfilesMessage1);
      messages.push(NLnoProfilesMessage2);
    } else {
      messages.push(NLyesProfilesMessage1);
      messages.push(NLyesProfilesMessage2);
    }
  }

  return messages;
}

export const emptyFilterCard: ProfileCard = {
  profile_id: 'empty.There are no more results within your filters',
  city: 'Breda',
  name: 'empty filter card',
  date_of_birth: '',
  image: {
    id: '',
    url: '',
    approved: false
  }
};
export const emptyCard: ProfileCard = {
  profile_id: 'empty.Sorry, there are no more profiles',
  city: 'Breda',
  name: 'empty card',
  date_of_birth: '',
  image: {
    id: '',
    url: '',
    approved: false
  }
};

export const emptyWrongCard: ProfileCard = {
  profile_id: 'empty.Something went wrong, please try again later',
  city: 'Breda',
  name: 'empty wrong card',
  date_of_birth: '',
  image: {
    id: '',
    url: '',
    approved: false
  }
};

function getSkeletonCard(id: number) {
  const skeletonCard: ProfileCard = {
    city: '',
    date_of_birth: '',
    image: {
      approved: false,
      id: '',
      url: ''
    },
    name: '',
    profile_id: 'skeletonCard#' + id
  };
  return skeletonCard;
}
export function getSkeletonGrid(size: number = 30) {
  const skeletonGrid: ProfileCard[] = [...Array(size)].map((_, x) => getSkeletonCard(x));
  return skeletonGrid;
}

