import { defineStore } from 'pinia';
import type {
  SiteConfig,
  Page,
  SiteTheme,
  SiteSettings,
  HorizonComponent,
  Translations,
  TranslationConsts
} from '../types';
import { useAuthStore } from './auth.store';
import { ref, computed } from 'vue';
import type { PartnerPixelConfig } from '../types/config/partnerPixelsSchema';

export const useSiteConfigStore = defineStore('siteConfig', () => {
  const authStore = useAuthStore();
  const siteConfig = ref<SiteConfig | undefined>(undefined);

  const pages = computed<Page[] | undefined>(() => siteConfig.value?.pages);
  const theme = computed<SiteTheme | undefined>(() => siteConfig.value?.theme);
  const siteSettings = computed<SiteSettings | undefined>(() => siteConfig.value?.siteSettings);
  const translations = computed<Translations | undefined>(() => siteConfig.value?.translations);

  const localeCode = computed(() => siteConfig.value?.siteSettings.localeCode);
  const companyId = computed(() => siteConfig.value?.siteSettings.companyId);
  const profileCardComponent = computed<HorizonComponent | undefined>(
    () => siteConfig.value?.siteSettings.components.profileCard
  );
  const profileFiltersComponent = computed<HorizonComponent | undefined>(
    () => siteConfig.value?.siteSettings.components.profileFilters
  );
  const profilePaginationComponent = computed<HorizonComponent | undefined>(
    () => siteConfig.value?.siteSettings.components.profilePagination
  );
  const notificationComponent = computed<HorizonComponent | undefined>(
    () => siteConfig.value?.siteSettings.components.notification
  );

  const partnerPixelConfig = computed<PartnerPixelConfig | undefined>(
    () => siteConfig.value?.siteSettings.partnerPixels
  );

  const translationConsts = computed<TranslationConsts | undefined>(() => {
    if (!siteConfig.value) return;

    return {
      domain: siteConfig.value.siteSettings.name
    };
  });

  function setConfig(config: SiteConfig) {
    siteConfig.value = structuredClone(config);

    const tenantId = config.siteSettings.tenantId;
    authStore.loadTenant(tenantId);
  }

  return {
    siteConfig,
    pages,
    theme,
    siteSettings,
    localeCode,
    companyId,
    profileCardComponent,
    profileFiltersComponent,
    profilePaginationComponent,
    notificationComponent,
    partnerPixelConfig,
    translations,
    translationConsts,

    setConfig
  };
});

