<script setup lang="ts">
import { useSiteConfigStore, useGlobalsStore } from '@horizon/library/stores';
import type {
  SiteTheme,
  HorizonNuxtErrorData,
  HorizonComponentProps,
  HorizonComponent,
  Page
} from '@horizon/library/types';
import { ref, computed } from 'vue';

const props = defineProps<HorizonComponentProps>();
const error = useError();

if (error.value) console.error(error.value);

const data = computed<HorizonNuxtErrorData | undefined>(() => {
  if (!error.value?.data) return undefined;

  if (typeof error.value.data === 'string') {
    try {
      return JSON.parse(error.value.data);
    } catch {
      //
    }
  } else if (typeof error.value.data === 'object') {
    return error.value.data;
  }

  return undefined;
});

const route = useRoute();
const siteConfigStore = useSiteConfigStore();
const globals = useGlobalsStore();
const { siteConfig, pages, translations } = storeToRefs(siteConfigStore);
const { $translate } = useNuxtApp();
// Do not use currentPage from the site config store, otherwise this will cause problems during page navigation.
// The old page will start rendering components of the new page, causing double calls.
const currentPage = ref<Page | undefined>();
currentPage.value = getPageFromRoutePath(pages.value, route.path);
const headerComponent = computed<HorizonComponent | undefined>(() =>
  currentPage.value?.components.find((x) => x.path.startsWith('Header'))
);
const currForceShowFooterVal = globals.dynamicGlobals.profileGrid.forceFooter;
const footerComponent = computed<HorizonComponent | undefined>(() => getFooter());

const theme = loadTheme();
applyTheme(theme);

function getFooter() {
  const footer = currentPage.value?.components.find(
    (x) => x.path.startsWith('Footer') || x.path.startsWith('ScrollFooter')
  );
  if (footer) {
    globals.dynamicGlobals.profileGrid.forceFooter = true;
  }
  return footer;
}

function loadTheme(): SiteTheme | undefined {
  if (data.value?.theme) return data.value?.theme;

  // siteConfigStore theme is only filled in the browser, SSR does not send this as payload to the client
  const siteConfig = useSiteConfigStore();
  if (siteConfig && siteConfig.theme) {
    return siteConfig.theme;
  }

  return undefined;
}

function applyTheme(theme: SiteTheme | undefined) {
  const siteSettings = siteConfig.value?.siteSettings;

  if (!theme || !siteSettings) return;

  const headContent = buildHeadContent(siteSettings, theme, undefined);
  useHead(headContent);
}

function handleError() {
  globals.dynamicGlobals.profileGrid.forceFooter = currForceShowFooterVal;
  clearError({ redirect: route.path });
}
</script>

<template>
  <main
    class="flex min-h-screen flex-col"
    :class="{ 'flex size-full flex-col items-center': false }"
  >
    <CustomComponent v-if="headerComponent" :component-data="headerComponent"></CustomComponent>
    <div class="container mt-10 flex size-full flex-col items-center">
      <div class="mb-4 flex flex-col">
        <Icon name="nonicons:error-16" size="50" class="text-primary mx-auto mb-3" />
        <p class="text-text mx-2 my-auto text-5xl">{{ $translate(translations?.errorHeading) }}</p>
      </div>
      <template v-if="data">
        <h1 v-if="data.title" class="text-text text-balance text-2xl">
          {{ data.title }}
        </h1>
        <h4 v-if="data.subtitle" class="text-text mt-4 text-balance text-lg">
          {{ data.subtitle }}
        </h4>
      </template>

      <ButtonSimple
        :data="{ label: 'Reload page' }"
        class="mt-4"
        :test-id="props.testId"
        @click="handleError()"
      />

      
    </div>
    <CustomComponent
      v-if="footerComponent"
      :component-data="footerComponent"
      class="mt-10"
    ></CustomComponent>
  </main>
</template>

<style>
.stack-wrapper .stack:not(.internal) {
  background-color: rgb(36 85 112);
}
</style>

