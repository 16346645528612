import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import type { Timestamp } from 'firebase-admin/firestore';

 
dayjs.extend(relativeTimePlugin);

export function dateToAge(date?: dayjs.ConfigType): number {
  return dayjs().diff(dayjs(date), 'years');
}

// https://day.js.org/docs/en/display/format
export function dateFormat(date?: dayjs.ConfigType): string {
  return dayjs(date).format('DD MMM YYYY');
}

export function inputDateFormat(date?: dayjs.ConfigType): string {
  return dayjs(date).format('YYYY-MM-DD')
}

export function dateTimeFormat(date?: dayjs.ConfigType, american:boolean = false): string {
  return dayjs(date).format(american ? 'MMM DD YYYY, HH:mm' :'DD MMM YYYY, HH:mm');
}

export function unixToDateTimeFormat(unixDate: string | Timestamp, american:boolean = false) {
  return dateTimeFormat(dayjs(Number(typeof unixDate === 'string' ? unixDate : unixDate.seconds) * 1000), american);
}

export function dateDistanceToNow(date?: dayjs.ConfigType): string {
  return dayjs(date).fromNow();
}

export function messageTimeFormat(date?: dayjs.ConfigType): string {
  return dayjs(date).format('HH:mm');
}

export function unixToChatTime(unixDate: string | Timestamp) {
  return dayjs(Number(typeof unixDate === 'string' ? unixDate : unixDate.seconds) * 1000).format(
    'HH:mm'
  ); //.format('YYYY-MM-DDTHH:mm:ssZ');
}

export function unixToFromNowDate(unixDate: string | Timestamp) {
  return dateDistanceToNow(dayjs(Number(typeof unixDate === 'string' ? unixDate : unixDate.seconds) * 1000));
}

export function unixToChatDate(unixDate: string | Timestamp, american: boolean = false) {
  return dayjs(Number(typeof unixDate === 'string' ? unixDate : unixDate.seconds) * 1000).format(
    american ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
  );
}

export function createChatTime() {
  return dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
}

export function logTimestamp() {
  return String(dayjs(new Date()).format('HH:mm:ss:SSS - '));
}

export function getNowInUnix() {
  return dayjs(new Date()).unix();
}

