import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { AttributeTypesResponse } from '../types/attribute';
import { createProfilesFetchClient } from '../composables/apiClients';

export const useAttributesStore = defineStore('attributes', () => {
  const profileFetchClient = createProfilesFetchClient();

  // Mapping attribute name to its' ID -> { "aboutMe": "UUID" }
  const attributes = ref<AttributeTypesResponse>();

  async function fetchAttributes() {
    const result = await profileFetchClient('/attributes', { method: 'GET' }).catch(() => {});

    if (!result || !result.data) {
      return;
    }

    attributes.value = result.data.reduce(
      (attrs, current) => ({ ...attrs, [current.name]: current.id }),
      {} as AttributeTypesResponse
    );
  }

  return { attributes, fetchAttributes };
});
