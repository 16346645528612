import { defineStore } from 'pinia';
import type { Notification, chatProfileData } from '../types';
import { getSingleRouteParam, storageKeys } from '../utils';

import { push } from 'notivue';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

import { useSiteConfigStore } from '../stores';
import { getApps } from 'firebase/app';
import { collection, getFirestore, doc, setDoc } from 'firebase/firestore';
import type { MessageDocument } from '@horizon/chat-protocol-ts';

interface CustomProps {
  notification: Notification;
}

type Setting = 'all' | 'mail' | 'none'; // this is a P.O.C, it kan be better

type storageType = {
  setting: Setting;
};
export const useNotificationStore = defineStore('notification', () => {
  const apps = getApps();
  const app = apps[0]; // for some reason the getApp() function of firebase is less reliable
  const db = getFirestore(app);
  const locale = useSiteConfigStore().siteSettings?.localeCode as string;
  const storageKey = storageKeys.notifications.notified;
  const storedVal =
    typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(storageKey) : null;
  const storage: storageType = storedVal != null ? JSON.parse(storedVal) : { setting: 'all' };

  const notifySetting = ref<Setting>(storage.setting);

  const route = useRoute();
  const IDs = ref<string[]>([]);

  function getSetting() {
    return notifySetting.value;
  }

  function applySetting(setting: Setting) {
    storage.setting = setting;
    sessionStorage.setItem(storageKey, JSON.stringify(storage));
    notifySetting.value = setting;
  }

  function countAsUnread(message: MessageDocument) {
    if (!message.id) return false
    return !IDs.value.includes(message.id);
  }

  function shouldAdd(message: MessageDocument, profileID: string) {
    const id = getSingleRouteParam(route.params, 'id');
    if (
      !profileID ||
      message.isRead ||
      message.metadata.isNotified ||
      notifySetting.value === 'none' ||
      (notifySetting.value === 'mail' && message.pokeMessageType === 'reply') ||
      (id === profileID && route.name === 'ProfileChat')
    ) {
      if (message.id) {
        IDs.value.push(message.id);
      }
      return false;
    }

    return true;
  }

  function setNotified(message: MessageDocument, chatID: string) {
    const messageRef = doc(
      collection(doc(collection(doc(collection(db, 'geos'), locale), 'chats'), chatID), 'messages'),
      message.id
    );
    setDoc(messageRef, { Metadata: { IsNotified: true } }, { merge: true });
    
    if (message.id) {
      IDs.value.push(message.id);
    }
  }

  function addNotification(notification: Notification, message: MessageDocument, profile: chatProfileData) {
    if (!shouldAdd(message, profile.card.profile_id)) return;
    push.success<CustomProps>({
      duration: notification.duration,
      title: 'Successfully sent!',
      message: message.id,
      props: {
        notification: notification
      },
      onAutoClear(_item) {
        setNotified(message, profile.chatID);
      },
      onManualClear(_item) {
        setNotified(message, profile.chatID);
      }
    });
  }

  function clearAllNotifications() {
    push.clearAll();
    IDs.value = [];
  }

  return {
    getSetting,
    applySetting,
    addNotification,
    clearAllNotifications,
    countAsUnread
  };
});

