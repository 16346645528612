import { z } from 'zod';
import { SiteThemeSchema } from './themeSchema';

const horizonComponentSchemaBase = z.object({
  name: z.string(),
  path: z.string(),
  data: z.object({}).catchall(z.any()).optional(),
  overrides: SiteThemeSchema.optional(),
  translations: z.object({}).optional(),
  testId: z.string().optional(),
  components: z
    .never({
      invalid_type_error: "Deprecated -> Use 'children' for nested components instead."
    })
    .optional()
});

// Create 4 layers of components with children since it is not possible to create recursive types in zod
// It is only possible to create a lazy children property, but this will strip all optional properties such as 'data' from said children
const horizonComponentSchemaOneChild = horizonComponentSchemaBase.extend({
  children: z.lazy(() => horizonComponentSchemaBase.array().optional())
});
const horizonComponentSchemaTwoChild = horizonComponentSchemaOneChild.extend({
  children: z.lazy(() => horizonComponentSchemaOneChild.array().optional())
});
const horizonComponentSchemaThreeChild = horizonComponentSchemaTwoChild.extend({
  children: z.lazy(() => horizonComponentSchemaTwoChild.array().optional())
});

export const HorizonComponentSchema = horizonComponentSchemaThreeChild.extend({
  children: z.lazy(() => horizonComponentSchemaThreeChild.array().optional())
});

export const HorizonModalSchema = HorizonComponentSchema.merge(
  z.object({
    data: z.object({
      backgroundURL: z.string().optional(),
      blur: z.boolean().optional()
    })
  })
);

